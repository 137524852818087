import { render, staticRenderFns } from "./allocated.vue?vue&type=template&id=749c9a32&scoped=true&"
import script from "./allocated.vue?vue&type=script&lang=js&"
export * from "./allocated.vue?vue&type=script&lang=js&"
import style0 from "./allocated.vue?vue&type=style&index=0&id=749c9a32&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749c9a32",
  null
  
)

export default component.exports