<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.keyword"
            placeholder="学生姓名/联系方式"
            style="min-width: 120px"
            size="small"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">释放人：</span>
          <el-select
            v-model="listParams.saleId"
            placeholder="请选择"
            size="small"
            @change="saleChangeSearch"
          >
            <el-option
              v-for="item in saleListTitle"
              :key="item.userId"
              :label="`${item.username}（${item.name}）`"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">标签：</span>
          <el-select
            v-model="listParams.ifEffective"
            placeholder="请选择"
            size="small"
          >
            <el-option key="" label="全部" value=""></el-option>
            <el-option key="Y" label="有效" value="Y"></el-option>
            <el-option key="N" label="无效" value="N"></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">释放时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="() => ((listParams.pageNumber = 1), getStudentList())"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="daalDelete('')">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>批量真实删除</span>
          </el-button>
          <el-button type="default" size="small" @click="daalFlyBack('')">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>批量回归客户池</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="userList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        ref="studentListTable"
        height="100%"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        @row-click="studentItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop="batch"
          label="批号"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="number"
          label="编号"
          align="center"
        ></el-table-column> -->
        <el-table-column prop="name" label="学生姓名" align="center">
          <template slot-scope="scope">
            <div
              style="text-decoration: underline; cursor: pointer"
              @click="daalDetail(scope.row)"
            >
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="account"
          label="联系方式"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="followUpCreateTime"
          label="跟进时间"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="releaseTime"
          label="释放时间"
          align="center"
        ></el-table-column>
        <el-table-column prop="followupTagsName" label="标签" align="center">
          <template slot-scope="scope">
            <span>{{ followupTagsFn(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否下过单" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.isFormal == 1 ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="saleName"
          label="释放人"
          align="center"
        >
          <template slot-scope="scope" >
            <span>{{scope.row.saleName}}（{{scope.row.saleRealName}}）</span>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.stop="daalDelete(scope.row)"
              >真实删除</el-button
            >
            <el-button
              class="mL10"
              type="text"
              size="small"
              @click.stop="daalFlyBack(scope.row)"
              >回归客户池</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { listNoPage } from "@/api/sales/saleManage";
import {
  studentReleaseList,
  trueDel,
  batchFlyBack,
} from "@/api/sales/myCustomer.js";
export default {
  data() {
    return {
      userList: [],
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        keyword: "",
        isRelease: 1,
        searchBeginDate: null,
        searchEndDate: null,
        ifEffective: "",
        saleId: "",
        dataAuthority: "dept",
      },
      total: 0,
      roleList: [],
      selectUserList: [],
      saleListTitle: [],
      saleParams: {
        roleSign: "sales",
        status: 1,
      },
      timePickerValue: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(90, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  inject: ["reload"],
  methods: {
    saleChangeSearch() {
      for (let i = 0, len = this.saleListTitle.length; i < len; i++) {
        if (this.saleListTitle[i].userId == this.listParams.saleId) {
          this.listParams.saleName = this.saleListTitle[i].username;
        }
      }
    },
    followupTagsFn(row) {
      if (row.contract != 6 && row.contract != null && row.contract != "") {
        return "有效";
      } else if (row.contract == 6) {
        return "无效";
      } else {
        return "";
      }
    },
    daalDetail(row) {
      this.$router.push({
        name: "sectionManageStudentInfo",
        params: {
          id: row.id,
          contract: -1,
          show: false,
          type: 3,
        },
      });
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.searchBeginDate = null;
        this.listParams.searchEndDate = null;
        return;
      }
      this.listParams.searchBeginDate = this.timePickerValue[0];
      this.listParams.searchEndDate = this.timePickerValue[1];
    },
    handleSelectionChange(val) {
      this.selectUserList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          // this.selectUserList.push(val[i].userId);
          this.selectUserList.push(val[i].id);
        }
      }
    },
    getStudentList() {
      studentReleaseList(this.listParams).then((res) => {
        console.log(res);
        this.userList = res.rows;
        this.total = res.total;
      });
    },

    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getStudentList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getStudentList();
    },
    refreshClick() {
      this.reload();
    },
    daalDelete(item) {
      let arr = [];
      if (item) {
        if (item.isFormal == 1) {
          this.$message.warning("下过单的客户不能进行真实删除！");
          return;
        }
        arr = [item.id];
      } else {
        for (let i = 0, lenI = this.selectUserList.length; i < lenI; i++) {
          for (let j = 0, lenJ = this.userList.length; j < lenJ; j++) {
            if (
              this.selectUserList[i] == this.userList[j].id &&
              this.userList[j].isFormal == 1
            ) {
              this.$message.warning("删除列表中包含下过单的客户,不能进行真实删除！请重新选择");
              return;
            }
          }
        }
        arr = this.selectUserList;
      }
      if (arr.length == 0) {
        this.msgWarn("请至少选择一个客户！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          trueDel(arr).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getStudentList();
            }
          });
        })
        .catch(() => {});
    },
    daalFlyBack(item) {
      let arr = [];
      if (item) {
        arr = [item.id];
      } else {
        arr = this.selectUserList;
      }
      if (arr.length == 0) {
        this.msgWarn("请至少选择一个客户！");
        return;
      }
      this.$confirm("确定要回归到客户池吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          batchFlyBack(arr).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getStudentList();
            }
          });
        })
        .catch(() => {});
    },

    studentItemClick(row) {
      this.$refs.studentListTable.toggleRowSelection(row);
    },
    getSaleList() {
      listNoPage(this.saleParams).then((res) => {
        this.saleListTitle = res;
        this.saleListTitle.unshift({
          userId: "",
          name: "全部",
          username: "全部",
        });
      });
    },
  },
  created() {},
  mounted() {
    this.getStudentList();
    this.getSaleList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 2;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
