<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="handleAddFee()">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="handleEditFee()">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="handleDelete">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
          <el-button type="default" size="small" @click="handleEnable">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-bofang" />
            </svg>
            <span>启用</span>
          </el-button>
          <el-button type="default" size="small" @click="handleDisable">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-bofang-copy" />
            </svg>
            <span>禁用</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="flowFeeList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        ref="flowFeeListTable"
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column width="66" align="center" label="单选" type>
          <template slot-scope="scope">
            <el-radio v-model="selectFeeId" :label="scope.row.id"
              >&nbsp;</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column label="上课模式" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.classMode == 1">一对一</span>
            <span v-else-if="scope.row.classMode == 2">一对多</span>
          </template>
        </el-table-column>
        <el-table-column label="视频模式" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.videoMode == 1">普通模式</span>
            <span v-else-if="scope.row.videoMode == 2">高级模式</span>
          </template>
        </el-table-column>
        <el-table-column label="费用" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.classMode == 1">{{ scope.row.flowFee }}</span>
            <span
              v-else-if="scope.row.classMode == 2"
              class="text_btn"
              @click="handleSeeOneToMoreFee(scope.row)"
              >查看</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
        ></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">已禁用</span>
            <span v-else-if="scope.row.status == 1">已启用</span>
          </template></el-table-column
        >
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title="流量费设置"
      :visible.sync="saveFeeDialogVisible"
      width="560px"
      center
    >
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="上课模式" prop="classMode">
            <el-radio-group v-model="saveRuleForm.classMode">
              <el-radio :label="1">一对一</el-radio>
              <el-radio :label="2">一对多</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="视频模式" prop="videoMode">
            <el-radio-group v-model="saveRuleForm.videoMode">
              <el-radio :label="1" v-show="saveRuleForm.classMode == 1">普通模式</el-radio>
              <el-radio :label="2">高级模式</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="流量费用"
            v-show="saveRuleForm.classMode == 1"
            prop="flowFee"
          >
            <div class="one_flow_fee">
              <el-input
                class="input_fee"
                v-model.trim="saveRuleForm.flowFee"
                @input="handleLimitNum($event)"
              ></el-input>
              <span>元/课时</span>
            </div>
          </el-form-item>
          <el-form-item
            label="流量费用"
            v-show="saveRuleForm.classMode == 2"
            prop="sysFlowFeeMoreList"
          >
            <div class="more_flow_fee">
              <div
                class="more_flowfee_item"
                v-for="(item, index) in saveRuleForm.sysFlowFeeMoreList"
                :key="index"
              >
                <el-input
                  class="more_input_people"
                  v-model.number="item.startPeople"
                ></el-input>
                <span>人</span>
                <span class="wave">~</span>
                <el-input
                  class="more_input_people"
                  v-model.number="item.endPeople"
                ></el-input>
                <span>人</span>
                <el-input
                  class="more_input_fee"
                  v-model.trim="item.flowFeeMore"
                  @input="handleSectionLimitNum($event, index)"
                ></el-input>
                <span>元/课时</span>
                <el-button
                  size="small"
                  class="del_fee"
                  type="danger"
                  @click="handleDelFeeSection(index)"
                  >删除</el-button
                >
              </div>
              <div class="add_fee">
                <el-button
                  size="small"
                  type="primary"
                  @click="handleAddFeeSection"
                  >添加</el-button
                >
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveFeeDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          class="mL20"
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="一对多费用"
      :visible.sync="saveMoreFeeDetailVisible"
      width="500px"
      center
    >
      <div class="dialog_feedetail_content">
        <div class="fee_detail_item" v-for="item in oneToMoreFeeList" :key="item.id">{{item.startPeople}}人~{{item.endPeople}}人{{item.flowFeeMore}}元/课时</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="mL20"
          type="primary"
          @click="saveMoreFeeDetailVisible = false"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  flowFeeUpdate,
  flowFeeEnable,
  flowFeeSave,
  flowFeeList,
  getFlowFeeById,
  flowFeeDel,
} from "@/api/system/systemManage";
export default {
  data() {
    return {
      oneToMoreFeeList: [],
      dialogType: "add",
      selectFeeId: null,
      flowFeeList: [],
      total: 0,
      listParams: {
        pageSize: 10,
        pageNumber: 1,
      },
      saveFeeDialogVisible: false,
      saveMoreFeeDetailVisible: false,
      saveRuleForm: {
        classMode: 1,
        flowFee: "",
        videoMode: 1,
        sysFlowFeeMoreList: [
          {
            startPeople: "",
            endPeople: "",
            flowFeeMore: "",
          },
        ],
      },
      saveRules: {
        classMode: [
          { required: true, message: "上课模式不能为空", trigger: "change" },
        ],
        videoMode: [
          { required: true, message: "视频模式不能为空", trigger: "change" },
        ],
        flowFee: [
          {
            required: true,
            message: "流量费用不能为空或0！",
            trigger: "change",
          },
        ],
        sysFlowFeeMoreList: [
          {
            trigger: "change",
            validator: (rule, value, callback) => {
              console.log("value: ", value);
              if (this.saveRuleForm.classMode == 1) {
                return callback();
              }
              if (value.length == 0) {
                return callback(new Error("阶段流量费用不能为空!"));
              }
              let startPeople = 1;
              for (let i = 0, len = value.length; i < len; i++) {
                if (
                  !value[i].startPeople ||
                  !value[i].endPeople ||
                  !value[i].flowFeeMore
                ) {
                  return callback(
                    new Error("输入框内容都为必填！且不能为空！")
                  );
                }
                if (value[i].startPeople >= value[i].endPeople) {
                  return callback(new Error("起始人数必须小于结束人数!"));
                }
                if (value[i].startPeople != startPeople) {
                  if (i == 0) {
                    return callback(new Error("起始人数必须从1人开始!"));
                  }
                  return callback(
                    new Error("下个阶梯起始人数必须连接上个阶梯截止人数!")
                  );
                }
                startPeople = value[i].endPeople + 1;
              }
              return callback();
            },
          },
        ],
      },
    };
  },
  watch: {
    "saveRuleForm.classMode"() {
      if(this.saveRuleForm.classMode == 2){
        this.saveRuleForm.videoMode = 2
      }
      this.handleFeeRequired();
    },
  },
  components: {},
  computed: {},
  mounted() {
    this.getFlowFeeList();
  },
  methods: {
    /* 处理添加费用区间 */
    handleAddFeeSection() {
      this.saveRuleForm.sysFlowFeeMoreList.push({
        startPeople: "",
        endPeople: "",
        flowFeeMore: "",
      });
    },
    /* 处理删除费用区间 */
    handleDelFeeSection(index) {
      this.saveRuleForm.sysFlowFeeMoreList.splice(index, 1);
    },
    /* 查看一对多费用详情 */
    handleSeeOneToMoreFee(item) {
      this.oneToMoreFeeList = item.sysFlowFeeMoreList
      this.saveMoreFeeDetailVisible = true
    },
    /* 一对多费用限制 */
    handleSectionLimitNum(value, index) {
      value =
        value
          .replace(/[^\d^\.]+/g, "")
          .replace(/^0+(\d)/, "$1")
          .replace(/^\./, "0.")
          .match(/^\d*(\.?\d{0,2})/g)[0] || "";
      this.saveRuleForm.sysFlowFeeMoreList[index].flowFeeMore = value;
    },
    /* 限制只能输入数字(小数)问题 */
    handleLimitNum(value) {
      value =
        value
          .replace(/[^\d^\.]+/g, "")
          .replace(/^0+(\d)/, "$1")
          .replace(/^\./, "0.")
          .match(/^\d*(\.?\d{0,2})/g)[0] || "";
      this.saveRuleForm.flowFee = value;
    },
    /* 当前页码改变 */
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getFlowFeeList();
    },
    /* 单页数量改变 */
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getFlowFeeList();
    },
    /* 获取平台充值列表 */
    getFlowFeeList() {
      this.selectFeeId = null;
      flowFeeList({ ...this.listParams }).then((res) => {
        console.log(res);
        const { total, rows } = res.rows;
        this.total = total;
        this.flowFeeList = rows;
      });
    },
    /* 处理编辑 */
    handleEditFee() {
      this.dialogType = "edit";
      if (!this.selectFeeId) {
        return this.$message.warning("请先选择要编辑的项！");
      }
      let flag;
      this.flowFeeList.forEach((item) => {
        if (item.id == this.selectFeeId && item.status == 1) {
          flag = true;
        }
      });
      if (flag) {
        return this.$message.warning("请先将状态设置为禁用再进行编辑！");
      }
      this.saveFeeDialogVisible = true;
      if (this.$refs["saveRuleForm"]) {
        this.$refs["saveRuleForm"].resetFields();
      }
      getFlowFeeById({
        id: this.selectFeeId,
      }).then((res) => {
        console.log(res);
        if (res.flag == "error") {
          return this.$message.error(res.msg);
        }
        this.saveRuleForm = res.rows;
        if (!this.saveRuleForm.sysFlowFeeMoreList) {
          this.saveRuleForm.sysFlowFeeMoreList = [];
        }
        this.handleFeeRequired();
      });
    },
    /* 处理新增 */
    handleAddFee() {
      this.dialogType = "add";
      this.saveFeeDialogVisible = true;
      if (this.$refs["saveRuleForm"]) {
        this.$refs["saveRuleForm"].resetFields();
      }
      this.handleFeeRequired();
    },
    /* 处理费用是否为必须输入 */
    handleFeeRequired() {
      if (this.saveRuleForm.classMode == 1) {
        this.saveRules.flowFee[0].required = true;
      } else if (this.saveRuleForm.classMode == 2) {
        this.saveRules.flowFee[0].required = false;
      }
    },
    /* 处理删除 */
    handleDelete() {
      if (!this.selectFeeId) {
        return this.$message.warning("请先选择要删除的项！");
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        flowFeeDel({ id: this.selectFeeId }).then((res) => {
          if (res.flag == "error") {
            this.$message.error(res.msg);
          } else {
            this.saveFeeDialogVisible = false;
            this.getFlowFeeList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
        console.log("res: ", res);
      });
    },
    /* 处理启用 */
    handleEnable() {
      if (!this.selectFeeId) {
        return this.$message.warning("请先选择要启用的项！");
      }
      this.$confirm("确定要启用吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        flowFeeEnable({ id: this.selectFeeId, status: 1 }).then((res) => {
          if (res.flag == "error") {
            this.$message.error(res.msg);
          } else {
            this.saveFeeDialogVisible = false;
            this.getFlowFeeList();
            this.$message({
              type: "success",
              message: "启用成功!",
            });
          }
        });
        console.log("res: ", res);
      });
    },
    /* 处理禁用 */
    handleDisable() {
      if (!this.selectFeeId) {
        return this.$message.warning("请先选择要禁用的项！");
      }
      this.$confirm("确定要禁用吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        flowFeeEnable({ id: this.selectFeeId, status: 0 }).then((res) => {
          if (res.flag == "error") {
            this.$message.error(res.msg);
          } else {
            this.saveFeeDialogVisible = false;
            this.getFlowFeeList();
            this.$message({
              type: "success",
              message: "禁用成功!",
            });
          }
        });
        console.log("res: ", res);
      });
    },
    /* 确定保存 */
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { classMode, id, flowFee, videoMode, sysFlowFeeMoreList } = this.saveRuleForm
          if (this.dialogType == "add") {
            flowFeeSave({
              classMode,
              flowFee: classMode == 1 ? flowFee : null,
              videoMode,
              sysFlowFeeMoreList: classMode == 2 ? sysFlowFeeMoreList : [],
            }).then((res) => {
            console.log(res);
            if (res.flag == "error") {
              this.$message.warning(res.msg);
            } else {
              this.$message.success("操作成功！");
              this.saveFeeDialogVisible = false;
              this.getFlowFeeList();
            }
          });
          } else if (this.dialogType == "edit") {
            flowFeeUpdate({
              id,
              classMode,
              flowFee: classMode == 1 ? flowFee : null,
              videoMode,
              sysFlowFeeMoreList: classMode == 2 ? sysFlowFeeMoreList : [],
            }).then((res) => {
              console.log(res);
              if (res.flag == "error") {
                this.$message.warning(res.msg);
              } else {
                this.$message.success("操作成功！");
                this.saveFeeDialogVisible = false;
                this.getFlowFeeList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
    /deep/{
      .el-radio__label{
        padding-left: 0;
      }
    }
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .save-dialog-content {
    height: 500px;
    overflow-y: auto;
    padding-right: 20px;
  }

  .charge_tip {
    text-indent: 26px;
  }

  .one_flow_fee {
    display: flex;
    .input_fee {
      width: 160px;
      margin-right: 20px;
    }
  }

  .text_btn {
    cursor: pointer;
    color: #1890ff;
  }

  .more_flow_fee {
    max-height: 40vh;
    overflow-y: auto;
    .more_flowfee_item {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .more_input_people {
        width: 60px;
        margin: 0 6px;
      }
      .more_input_fee {
        width: 80px;
        margin: 0 6px;
      }
      .del_fee {
        margin-left: 10px;
      }
      /deep/.el-input__suffix {
        display: none !important;
      }
    }
  }

  .fee_detail_item{
    padding: 8px 0;
    line-height: 20px;
  }

  .dialog_feedetail_content{
    max-height: 70vh;
    overflow-y: auto;
  }
}
</style>