<template>
  <div class="teacher-cash">
    <div class>
      <el-form
        :model="saveRuleForm"
        status-icon
        :rules="saveRules"
        ref="saveRuleForm"
        label-width="100px"
        size="small"
      >
        <el-row :gutter="20" >
          <el-col :span="12">
            <el-form-item label="手续费类型" prop="poundageType">
              <el-select v-model="saveRuleForm.poundageType" placeholder="请选择" @change="typeChange" >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="typeName" prop="poundageNum">
              <el-input v-model="saveRuleForm.poundageNum"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="提现提示" prop="describe">
          <editor
          :content_height="content_height"
            @edit_content_change="edit_content_change"
            :set_editor_content="saveRuleForm.describe"
          ></editor>
        </el-form-item>
        <el-form-item>
          <div class="func-btn">
            <el-button type="primary" @click="submitForm('saveRuleForm')">提交</el-button>
            <el-button @click="resetForm('saveRuleForm')">重置</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { systemAppTeacherCash } from '@/api/system/appManage'
export default {
  data() {
    let checkPoundageNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`${this.typeName}不能为空`));
      } else {
        if (/^\d{1,}$/g.test(value.trim())) {
          callback();
        } else {
          return callback(new Error(`${this.typeName}必须为数字`));
        }
      }
    };
    return {
      saveRuleForm: {
        describe: "",
        poundageNum: "",
        poundageType: "1",
      },
      content_height: 150,
      saveRules: {
        poundageNum: [
          { validator: checkPoundageNum, trigger: "blur" },
          {
            required: true,
            message: `${this.typeName}不能为空 `,
            trigger: "change",
          },
        ],
      },
      typeList: [
        {
          value: "1",
          label: "固定金额",
        },
        {
          value: "2",
          label: "百分比",
        },
      ],
      typeName: "金额",
    };
  },
  methods: {
    edit_content_change(html) {
      console.log(html)
      this.saveRuleForm.describe = html;
    },
    typeChange() {
      switch (this.saveRuleForm.poundageType) {
        case "1":
          this.typeName = "金额";
          break;
        case "2":
          this.typeName = "百分比";
          break;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          systemAppTeacherCash(this.saveRuleForm).then(res => {
            console.log(res)
            this.msgSuccess('提交成功！')
          })
        } else {
          this.msgWarn("必填字段不能为空！");
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="scss">

.teacher-cash {
  width: 800px;
  height: 600px;
  margin: 60px auto;
  .func-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>