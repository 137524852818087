<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">类别：</span>
          <el-select
            v-model="listParams.infoGenre"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">是否置顶：</span>
          <el-select
            v-model="listParams.isTop"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in topList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">创建时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm"
          ></el-date-picker>
        </div>
        <div class="select-condition-item">
          <el-input
            v-model="listParams.infoTitle"
            placeholder="请输入标题"
            size="small"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="getSystemAppNewsList"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
          <el-button type="default" size="small" @click="upTopClick(true)">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-stick" />
            </svg>
            <span>一键置顶</span>
          </el-button>
          <el-button type="default" size="small" @click="upTopClick(false)">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-quxiaozhiding" />
            </svg>
            <span>一键取消置顶</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="newsList"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="newsListTable"
        @selection-change="handleSelectionChange"
        @row-click="newsItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="infoTitle"
          label="资讯标题"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="infoGenre"
          :formatter="dealInfoGenre"
          label="所属类别"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="author"
          label="作者"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="browseNum"
          label="浏览数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="isTop"
          :formatter="dealIsTop"
          label="是否置顶"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          :formatter="dealStatus"
          label="状态"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      width="860px"
      center
      :close-on-click-modal="false"
    >
      <el-dialog
        title="选择标签"
        :visible.sync="tagsDialogVisible"
        append-to-body
        width="860px"
        center
        :close-on-click-modal="false"
        custom-class="tags-dialog"
      >
        <div class="dialog-content">
          <div>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-input
                  v-model="tagsListParams.polymerizationKeywords"
                  placeholder="请输入查询关键字"
                  size="small"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  v-model="tagsListParams.tagsAddress"
                  placeholder="请输入查询链接地址"
                  size="small"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-search"
                  @click="getSystemAppNewsList"
                  >搜索</el-button
                >
              </el-col>
            </el-row>
          </div>
          <div class="table-area mT10">
            <el-table
              size="small"
              :data="tagsList"
              style="width: 100%;margin-bottom: 20px;"
              border
              height="400px"
              tooltip-effect="dark"
              :row-key="getRowKeys"
              ref="tagsListTable"
              @selection-change="handleTagsSelectionChange"
              @row-click="tagsItemClick"
            >
              <el-table-column
                type="selection"
                width="55"
                :reserve-selection="true"
                align="center"
                :selectable="checkSelectable"
              ></el-table-column>
              <el-table-column
                prop="polymerizationKeywords"
                label="关键字"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="tagsAddress"
                label="链接地址"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="author"
                label="作者"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="createTime"
                label="添加时间"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
          <div class="page-area">
            <el-pagination
              @size-change="handleTagsSizeChange"
              @current-change="handleTagsCurrentChange"
              :current-page="tagsListParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="tagsListParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="tagsTotal"
              background
            ></el-pagination>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="tagsDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" @click="confirmTagsSelect()" size="small"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <div class="dialog-content">
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="资讯标题" prop="infoTitle">
                <el-input v-model="saveRuleForm.infoTitle"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="资讯关键字" prop="courseKeywords">
                <el-input v-model="saveRuleForm.courseKeywords"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="资讯类别" prop="infoGenre">
                <el-select
                  v-model="saveRuleForm.infoGenre"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in tagsTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否置顶" prop="isTop">
                <el-select
                  v-model="saveRuleForm.isTop"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in tagsTopList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="资讯来源" prop="origin">
                <el-input v-model="saveRuleForm.origin"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="作者" prop="author">
                <el-input v-model="saveRuleForm.author"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="页面描述" prop="pageDiscribe">
            <el-input
              type="textarea"
              v-model="saveRuleForm.pageDiscribe"
            ></el-input>
          </el-form-item>
          <el-form-item label="tags标签" prop="tagsId">
            <el-row>
              <el-col :span="20">
                <div class="tags-area">
                  <el-tag
                    v-for="tag in selectTagsList"
                    :key="tag.id"
                    closable
                    @close="removeTag(tag)"
                    :disable-transitions="false"
                    >{{ tag.polymerizationKeywords }}</el-tag
                  >
                </div>
              </el-col>
              <el-col :span="4" class="select">
                <el-button type="primary" size="small" @click="selectTagsClick"
                  >选择标签</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="文章内容" prop="articleContent">
            <editor
              @edit_content_change="edit_content_change"
              :set_editor_content="set_editor_content"
            ></editor>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="saveRuleForm.status">
              <el-radio label="1">发布</el-radio>
              <el-radio label="2">草稿</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { inferfaceFileUpload } from "@/api/public";
import {
  systemAppNewsList,
  systemAppNewsSave,
  systemAppNewsInfo,
  systemAppNewsUpdate,
  systemAppNewsRemove,
  systemAppNewsUpTop,
  systemAppNewsNoTop,
  systemAppTagsList
} from "@/api/system/appManage";
class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }
  upload() {
    //重置上传路径
    return new Promise(async (resolve, reject) => {
      let formdata = new FormData();
      let file = await this.loader.file;
      formdata.append("file", file);
      inferfaceFileUpload(formdata).then(res => {
        resolve({
          default: res[0].url
        });
      });
    });
  }
  abort() {}
}
function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = loader => {
    // Configure the URL to the upload script in your back-end here!
    return new UploadAdapter(loader);
  };
}
export default {
  inject: ["reload"],
  watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
  data() {
    return {
      editorData: "",
      editorConfig: {
        extraPlugins: [MyCustomUploadAdapterPlugin],
        image: {
          resizeUnit: "%",
          resizeOptions: [
            {
              name: "imageResize:original",
              value: null,
              label: "Original size" // Note: add the "icon" property if you're configuring a standalone button.
            },
            {
              name: "imageResize:50",
              value: "50",
              label: "Medium size" // Note: add the "icon" property if you're configuring a standalone button.
            },
            {
              name: "imageResize:75",
              value: "75",
              label: "Large size" // Note: add the "icon" property if you're configuring a standalone button.
            }
          ],
          toolbar: [
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "imageTextAlternative",
            "imageResize:50",
            "imageResize:75",
            "imageResize:original"
          ]
        }
        // The configuration of the editor.
      },
      typeList: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "1",
          label: "教育动态"
        },
        {
          value: "2",
          label: "好文分享"
        },
        {
          value: "3",
          label: "段子分享"
        }
      ],
      tagsTypeList: [
        {
          value: "1",
          label: "教育动态"
        },
        {
          value: "2",
          label: "好文分享"
        },
        {
          value: "3",
          label: "段子分享"
        }
      ],
      topList: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "1",
          label: "是"
        },
        {
          value: "2",
          label: "否"
        }
      ],
      tagsTopList: [
        {
          value: "1",
          label: "是"
        },
        {
          value: "2",
          label: "否"
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      timePickerValue: "",
      newsList: [],
      listParams: {
        infoGenre: "0",
        isTop: "0",
        searchBeginDate: null,
        searchEndDate: null,
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        infoTitle: ""
      },
      tagsListParams: {
        polymerizationKeywords: "",
        tagsAddress: "",
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc"
      },
      tagsTotal: 0,
      total: 0,
      saveDialogVisible: false,
      saveRuleForm: {
        articleContent: "",
        author: "",
        courseKeywords: "",
        infoGenre: "",
        infoTitle: "",
        isTop: "",
        origin: "",
        pageDiscribe: "",
        status: "1",
        tagsId: ""
      },
      saveRules: {
        articleContent: [
          { required: true, message: "文章内容不能为空", trigger: "blur" }
        ],
        author: [{ required: true, message: "作者不能为空", trigger: "blur" }],
        courseKeywords: [
          { required: true, message: "课程关键字不能为空", trigger: "blur" }
        ],
        infoGenre: [
          { required: true, message: "资讯类别不能为空", trigger: "blur" }
        ],
        infoTitle: [
          { required: true, message: "资讯标题不能为空", trigger: "blur" }
        ],
        isTop: [
          { required: true, message: "是否置顶不能为空", trigger: "blur" }
        ],
        origin: [{ required: true, message: "来源不能为空", trigger: "blur" }],
        pageDiscribe: [
          { required: true, message: "页面来源不能为空", trigger: "blur" }
        ],
        status: [{ required: true, message: "状态不能为空", trigger: "blur" }],
        tagsId: [
          { required: true, message: "tags标签不能为空", trigger: "blur" }
        ]
      },
      tagsList: [],
      selectTagsList: [],
      tagsSelection: [],
      selectNewsList: [],
      saveDialog: {
        title: "新增资讯",
        type: "add"
      },
      tagsDialogVisible: false,
      set_editor_content: ""
    };
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    checkSelectable(row) {
      let flag = false;
      for (let i = 0, len = this.selectTagsList.length; i < len; i++) {
        if (this.selectTagsList[i].id == row.id) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        return true;
      }
      return false;
    },
    confirmTagsSelect() {
      for (let i = 0, len = this.tagsSelection.length; i < len; i++) {
        this.selectTagsList.push({
          id: this.tagsSelection[i].id,
          polymerizationKeywords: this.tagsSelection[i].polymerizationKeywords
        });
      }
      this.tagsDialogVisible = false;
    },
    getSystemAppTagsList() {
      systemAppTagsList(this.tagsListParams).then(res => {
        this.tagsList = res.rows;
        this.tagsTotal = res.total;
      });
    },
    selectTagsClick() {
      this.tagsDialogVisible = true;
      this.tagsListParams = {
        polymerizationKeywords: "",
        tagsAddress: "",
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc"
      };
      this.getSystemAppTagsList();
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.searchBeginDate = null;
        this.listParams.searchEndDate = null;
        return;
      }
      this.listParams.searchBeginDate = this.timePickerValue[0];
      this.listParams.searchEndDate = this.timePickerValue[1];
    },
    handleSelectionChange(val) {
      this.selectNewsList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectNewsList.push(val[i].id);
        }
      }
    },
    //单次选中 不选中
    handleTagsSelectionChange(val) {
      this.tagsSelection = val;
    },
    getSystemAppNewsList() {
      let data = {
        infoGenre:
          this.listParams.infoGenre != "0" ? this.listParams.infoGenre : null,
        isTop: this.listParams.isTop != "0" ? this.listParams.isTop : null,
        searchBeginDate: this.listParams.searchBeginDate,
        searchEndDate: this.listParams.searchEndDate,
        pageNumber: this.listParams.pageNumber,
        pageSize: this.listParams.pageSize,
        sortOrder: "asc",
        infoTitle: this.listParams.infoTitle ? this.listParams.infoTitle : null
      };
      systemAppNewsList(data).then(res => {
        this.newsList = res.rows;
        this.total = res.total;
      });
    },
    edit_content_change(html) {
      this.saveRuleForm.articleContent = html;
    },
    ckedit_content_change(html) {
      this.saveRuleForm.articleContent = html;
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getSystemAppNewsList();
    },
    handleTagsSizeChange(pageSize) {
      this.tagsListParams.pageSize = pageSize;
      this.getSystemAppTagsList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getSystemAppNewsList();
    },
    handleTagsCurrentChange(currentPage) {
      this.tagsListParams.pageNumber = currentPage;
      this.getSystemAppTagsList();
    },
    confirmSave(formName) {
      let arr = [];
      for (let i = 0, len = this.selectTagsList.length; i < len; i++) {
        arr.push(this.selectTagsList[i].id);
      }
      this.saveRuleForm.tagsId = arr.join(",");
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            articleContent: this.saveRuleForm.articleContent,
            author: this.saveRuleForm.author,
            courseKeywords: this.saveRuleForm.courseKeywords,
            infoGenre: this.saveRuleForm.infoGenre,
            infoTitle: this.saveRuleForm.infoTitle,
            isTop: this.saveRuleForm.isTop,
            origin: this.saveRuleForm.origin,
            pageDiscribe: this.saveRuleForm.pageDiscribe,
            status: this.saveRuleForm.status,
            tagsId: this.saveRuleForm.tagsId
          };
          if (this.saveDialog.type == "add") {
            systemAppNewsSave(data).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemAppNewsList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.id = this.selectNewsList[0];
            systemAppNewsUpdate(data).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemAppNewsList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },

    editClick() {
      if (this.selectNewsList.length != 1) {
        this.msgWarn("请选择单个资讯进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑资讯";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        articleContent: "",
        author: "",
        courseKeywords: "",
        infoGenre: "",
        infoTitle: "",
        isTop: "",
        origin: "",
        pageDiscribe: "",
        status: "1",
        tagsId: ""
      };
      this.saveDialogVisible = true;
      systemAppNewsInfo(this.selectNewsList[0]).then(res => {
        if (res.code == 0) {
          let tagIdList = res.rows.tagsId ? res.rows.tagsId.split(",") : [],
            tagNameList = res.rows.names ? res.rows.names.split(",") : [];
          this.selectTagsList = [];
          for (let i = 0, len = tagIdList.length; i < len; i++) {
            this.selectTagsList.push({
              id: tagIdList[i],
              polymerizationKeywords: tagNameList[i]
            });
          }
          this.saveRuleForm = {
            articleContent: res.rows.articleContent,
            author: res.rows.author,
            courseKeywords: res.rows.courseKeywords,
            infoGenre: String(res.rows.infoGenre),
            infoTitle: res.rows.infoTitle,
            isTop: String(res.rows.isTop),
            origin: res.rows.origin,
            pageDiscribe: res.rows.pageDiscribe,
            status: String(res.rows.status),
            tagsId: res.rows.tagsId
          };
          this.set_editor_content= res.rows.articleContent;
          this.editorData = res.rows.articleContent;
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增资讯";
      this.saveDialog.type = "add";
      this.selectTagsList = [];
      this.saveRuleForm = {
        articleContent: "",
        author: "",
        courseKeywords: "",
        infoGenre: "",
        infoTitle: "",
        isTop: "",
        origin: "",
        pageDiscribe: "",
        status: "1",
        tagsId: ""
      };
      this.set_editor_content = "";
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload();
    },
    delClick() {
      if (this.selectNewsList.length == 0) {
        this.msgWarn("请至少选择一个资讯！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          systemAppNewsRemove(this.selectNewsList).then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSystemAppNewsList();
            }
          });
        })
        .catch(() => {});
    },
    upTopClick(upTop) {
      if (upTop) {
        systemAppNewsUpTop(this.selectNewsList).then(res => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.getSystemAppNewsList();
          }
        });
      } else {
        systemAppNewsNoTop(this.selectNewsList).then(res => {
          if (res.code == 0) {
            this.msgSuccess("操作成功！");
            this.getSystemAppNewsList();
          }
        });
      }
    },
    newsItemClick(row) {
      this.$refs.newsListTable.toggleRowSelection(row);
    },
    tagsItemClick(row) {
      let flag = false;
      for (let i = 0, len = this.selectTagsList.length; i < len; i++) {
        if (this.selectTagsList[i].id == row.id) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        this.$refs.tagsListTable.toggleRowSelection(row);
      }
    },
    removeTag(tag) {
      for (let i = 0, len = this.selectTagsList.length; i < len; i++) {
        if (this.selectTagsList[i].id == tag.id) {
          this.selectTagsList.splice(i, 1);
        }
      }
    },
    dealInfoGenre(row) {
      if (row.infoGenre == 1) {
        return "教育动态";
      } else if (row.infoGenre == 2) {
        return "好文分享";
      } else if (row.infoGenre == 3) {
        return "段子分享";
      }
    },
    dealIsTop(row) {
      if (row.isTop == 1) {
        return "是";
      } else {
        return "否";
      }
    },
    dealStatus(row) {
      if (row.status == 1) {
        return "发布";
      } else if (row.status == 2) {
        return "草稿";
      }
    }
  },
  created() {},
  mounted() {
    this.getSystemAppNewsList();
  }
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    height: 560px;
    overflow: auto;
    padding-right: 10px;
    .tags-area {
      min-height: 100px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      padding: 10px;
    }
    .select {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
 
}
</style>
