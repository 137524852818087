<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="请输入搜索关键字"
            size="small"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">学段/年级：</span>
          <el-select
            v-model="listParams.sectionName"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in sectionNameList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">类型：</span>
          <el-select
            v-model="listParams.type"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in questionType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">学科：</span>
          <el-select
            v-model="listParams.subjectName"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in subjectNameList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">选择时间：</span>
          <el-date-picker
            v-model="year"
            type="year"
            @change="salaryMonthFn"
            placeholder="选择年"
          >
          </el-date-picker>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="() => ((listParams.pageNumber = 1), getPublicSourceList())"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="sourceList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="sourceListTable"
        @selection-change="handleSelectionChange"
        @row-click="sourceItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="dealType"
          label="类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sectionName"
          label="学段/年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="subjectName"
          label="学科"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="year"
          label="年份"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="browseNum"
          label="浏览数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="downloadNum"
          label="下载数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createUserName"
          label="创建人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      width="600px"
      center
      :close-on-click-modal="false"
      custom-class="article-dialog"
    >
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="文件选择">
            <el-upload
              accept="image/jpeg, image/png/, image/gif"
              :show-file-list="uploadConfig.showFileList"
              :action="fileUpload"
              :on-success="uploadSuccess"
              :on-error="uploadError"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="资源名称" prop="name">
            <el-input v-model="saveRuleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="资源地址" prop="url">
            <el-input v-model="saveRuleForm.url" disabled></el-input>
          </el-form-item>
          <el-form-item label="资源类型" prop="type">
            <el-select
              v-model="saveRuleForm.type"
              placeholder="请选择"
              @change="typeChange"
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年  级" prop="sectionName">
            <el-select
              v-model="saveRuleForm.sectionName"
              placeholder="请选择"
              @change="sectionChange"
            >
              <el-option
                v-for="item in sectionList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="科  目" prop="subjectName">
            <el-select
              v-model="saveRuleForm.subjectName"
              placeholder="请选择"
              @change="subjectChange"
            >
              <el-option
                v-for="item in subjectList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="年  份"
            prop="year"
            v-if="saveRuleForm.type == 1"
          >
            <el-select v-model="saveRuleForm.year" placeholder="请选择">
              <el-option
                v-for="item in yearList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="地区"
            prop="selectedAreaOptions"
            v-if="saveRuleForm.type == 1"
          >
            <el-cascader
              :options="areaList"
              @expand-change="handleItemChange"
              :props="areaProps"
              v-model="saveRuleForm.selectedAreaOptions"
              :key="areaParams.areaKey"
              style="width: 100%"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            label="题 型"
            prop="questionType"
            v-if="saveRuleForm.type == 1"
          >
            <el-select v-model="saveRuleForm.questionType" placeholder="请选择">
              <el-option
                v-for="item in questionTypeList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="知识点"
            prop="knowledgesId"
            v-if="saveRuleForm.type == 2"
          >
            <el-select v-model="saveRuleForm.knowledgesId" placeholder="请选择">
              <el-option
                v-for="item in knowledgeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
let id = 0;
import {
  publicSourceList,
  publicSourceSave,
  publicSourceInfo,
  publicSourceUpdate,
  publicSourceRemove,
  knowledgeTree,
} from "@/api/system/publicSource";
import { getSelectList, fileUpload } from "@/api/public";
import { systemAreaSelect } from "@/api/system/systemManage";
const getYearList = () => {
  let year = new Date().getFullYear(),
    list = [];
  for (let i = year; i >= 2010; i--) {
    list.push(year--);
  }
  list.push("更早");
  return list;
};
const sectionList_1 = [
  "高考真题",
  "中考真题",
  "高三",
  "高二",
  "高一",
  "初三",
  "初二",
  "初一",
  "六年级",
  "五年级",
  "四年级",
  "三年级",
];
const sectionList_2 = ["高中", "初中"];
const subjectList_1 = [
  "语文",
  "数学",
  "英语",
  "物理",
  "化学",
  "生物",
  "历史",
  "地理",
  "政治",
  "文综",
  "理综",
];
const subjectList_2 = [
  "语文",
  "数学",
  "英语",
  "物理",
  "化学",
  "生物",
  "历史",
  "地理",
  "政治",
];
const subjectList_3 = ["语文", "数学", "英语", "其他"];
const questionTypeList_1 = ["真题"];
const questionTypeList_2 = ["全部", "模考", "期中", "期末", "月考","其他"];
const questionTypeList_3 = ["全部", "期中", "期末", "月考", "其他"];
export default {
  inject: ["reload"],
  data() {
    return {
      subjectNameList: [
        { label: "全部", value: "" },
        { label: "语文", value: "语文" },
        { label: "数学", value: "数学" },
        { label: "英语", value: "英语" },
        { label: "物理", value: "物理" },
        { label: "化学", value: "化学" },
        { label: "生物", value: "生物" },
        { label: "历史", value: "历史" },
        { label: "地理", value: "地理" },
        { label: "政治", value: "政治" },
        { label: "文综", value: "文综" },
        { label: "理综", value: "理综" },
      ],
      questionType: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "试卷",
        },
        {
          value: 2,
          label: "课件",
        },
      ],
      typeList: [
        {
          value: 1,
          label: "试卷",
        },
        {
          value: 2,
          label: "课件",
        },
      ],
      sectionList: [],
      subjectList: [],
      questionTypeList: questionTypeList_1,
      yearList: getYearList(),
      areaList: [],
      areaProps: {
        value: "index",
        label: "name",
        children: "children",
      },
      areaParams: {
        parentCode: "0",
        areaKey: new Date().getTime(),
      },
      sourceList: [],
      fileUpload,
      uploadConfig: {
        showFileList: false,
      },
      year: "",
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        name: "",
        sectionName: "",
        type: "",
        subjectName: "",
        year: "",
      },
      total: 0,
      saveDialogVisible: false,
      knowledgeList: [],
      saveRuleForm: {
        name: "",
        sectionName: "",
        subjectName: "",
        questionType: "",
        type: "",
        url: "",
        year: "",
        knowledgesId: "",
        selectedAreaOptions: [],
      },
      sectionNameList: [
        { label: "全部", value: "" },
        { label: "高考真题", value: "高考真题" },
        { label: "中考真题", value: "中考真题" },
        { label: "高三", value: "高三" },
        { label: "高二", value: "高二" },
        { label: "高一", value: "高一" },
        { label: "初三", value: "初三" },
        { label: "初二", value: "初二" },
        { label: "初一", value: "初一" },
        { label: "六年级", value: "六年级" },
        { label: "五年级", value: "五年级" },
        { label: "四年级", value: "四年级" },
        { label: "三年级", value: "三年级" },
        { label: "高中", value: "高中" },
        { label: "初中", value: "初中" },
      ],
      saveRules: {
        name: [
          { required: true, message: "资源名称不能为空", trigger: "blur" },
        ],
        sectionName: [
          { required: true, message: "年级不能为空", trigger: "blur" },
        ],
        subjectName: [
          { required: true, message: "学科不能为空", trigger: "blur" },
        ],
        type: [{ required: true, message: "类型不能为空", trigger: "blur" }],
        url: [
          { required: true, message: "资源地址不能为空", trigger: "change" },
        ],
        year: [{ required: true, message: "年份不能为空", trigger: "blur" }],
        questionType: [
          { required: true, message: "题型不能为空", trigger: "blur" },
        ],
        knowledgesId: [
          { required: true, message: "题型不能为空", trigger: "blur" },
        ],
        selectedAreaOptions: [
          { required: true, message: "地区不能为空", trigger: "blur" },
        ],
      },
      selectSourceList: [],
      saveDialog: {
        title: "新增资源",
        type: "add",
      },
    };
  },
  methods: {
    timeFn(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var second = date.getSeconds();
      second = second < 10 ? "0" + second : second;
      return y;
    },
    salaryMonthFn() {
      this.listParams.year = new Date(this.year).getFullYear();
    },
    handleItemChange(val) {
      if (val.length == 1) {
        let index = val[0].split("_")[0],
          areaCode = val[0].split("_")[2];
        if (this.areaList[index].children.length > 0) {
          return;
        }
        systemAreaSelect({
          areaCode,
        }).then((res) => {
          res.forEach((item, index) => {
            item.index = `${index}_${item.areaId}_${item.areaCode}`;
            if (item.isParent) {
              item.children = [];
            }
          });
          this.areaList[index].children = res;
        });
      } else if (val.length == 2) {
        let index_1 = val[0].split("_")[0],
          index_2 = val[1].split("_")[0],
          areaCode = val[1].split("_")[2];
        if (this.areaList[index_1].children[index_2].children.length > 0) {
          return;
        }
        systemAreaSelect({
          areaCode,
        }).then((res) => {
          res.forEach((item, index) => {
            item.index = `${index}_${item.areaId}_${item.areaCode}`;
            if (item.isParent) {
              item.children = [];
            }
          });
          this.areaList[index_1].children[index_2].children = res;
        });
      }
    },
    typeChange() {
      this.saveRuleForm.sectionName = "";
      this.saveRuleForm.subjectName = "";
      if (this.saveRuleForm.type == 1) {
        this.sectionList = sectionList_1;
        this.subjectList = subjectList_1;
      } else if (this.saveRuleForm.type == 2) {
        this.$set(this.saveRuleForm, "knowledgesId", "");
        this.sectionList = sectionList_2;
        this.subjectList = subjectList_2;
      }
    },
    sectionChange() {
      this.areaParams.areaKey = new Date().getTime();
      if (this.saveRuleForm.type == 1) {
        if (["高考真题", "中考真题"].includes(this.saveRuleForm.sectionName)) {
          this.subjectList = subjectList_1;
          this.questionTypeList = questionTypeList_1;
        } else if (["高三", "初三"].includes(this.saveRuleForm.sectionName)) {
          this.subjectList = subjectList_1;
          this.questionTypeList = questionTypeList_2;
        } else if (
          ["高一", "高二", "初一", "初二"].includes(
            this.saveRuleForm.sectionName
          )
        ) {
          this.subjectList = subjectList_2;
          this.questionTypeList = questionTypeList_3;
        } else if (
          ["六年级", "五年级", "四年级", "三年级"].includes(
            this.saveRuleForm.sectionName
          )
        ) {
          this.subjectList = subjectList_3;
          this.questionTypeList = questionTypeList_3;
        }
        if (["高考真题", "中考真题"].includes(this.saveRuleForm.sectionName)) {
          this.areaList.forEach((item) => {
            if (item.name == "北京市") {
              item.children = null;
            }
          });
        } else {
          this.areaList.forEach((item) => {
            if (item.name == "北京市") {
              item.children = [];
            }
          });
        }
        this.$set(this.saveRuleForm, "selectedAreaOptions", []);
        this.$set(this.saveRuleForm, "questionType", "");
        this.$set(this.saveRuleForm, "subjectName", "");
      } else if (this.saveRuleForm.type == 2) {
        this.subjectList = subjectList_2;
        this.$set(this.saveRuleForm, "subjectName", "");
      }
      this.dealKnowledge();
    },
    subjectChange() {
      this.saveRuleForm.knowledgesId = "";
      this.dealKnowledge();
    },
    async dealKnowledge() {
      if (
        this.saveRuleForm.type == 2 &&
        this.saveRuleForm.subjectName &&
        this.saveRuleForm.sectionName
      ) {
        let sectionList,
          subjectList,
          parentId = 0;
        sectionList = (
          await knowledgeTree({
            parentId,
          })
        ).rows;
        for (let i = 0, len = sectionList.length; i < len; i++) {
          if (sectionList[i].name == this.saveRuleForm.sectionName) {
            parentId = sectionList[i].id;
            break;
          }
        }
        subjectList = (
          await knowledgeTree({
            parentId,
          })
        ).rows;
        for (let i = 0, len = subjectList.length; i < len; i++) {
          if (subjectList[i].name == this.saveRuleForm.subjectName) {
            parentId = subjectList[i].id;
            break;
          }
        }
        this.knowledgeList = (
          await knowledgeTree({
            parentId,
          })
        ).rows;
      }
    },
    getAreaList() {
      systemAreaSelect({
        areaCode: this.areaParams.parentCode,
      }).then((res) => {
        res.forEach((item, index) => {
          item.disabled = true;
          item.index = `${index}_${item.areaId}_${item.areaCode}`;
          if (item.isParent) {
            item.children = [];
          }
          if (item.name == "北京市") {
            item.disabled = false;
          }
        });
        this.areaList = res;
      });
    },
    handleSelectionChange(val) {
      this.selectSourceList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectSourceList.push(val[i].id);
        }
      }
    },
    uploadSuccess(res, file, fileList) {
      this.saveRuleForm.name = res[0].originalName;
      this.saveRuleForm.url = res[0].url;
    },
    uploadError(err, file, fileList) {
      this.msgError("文件传发异常！");
      console.log(err);
    },
    getPublicSourceList() {
      publicSourceList(this.listParams).then((res) => {
        this.sourceList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getPublicSourceList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getPublicSourceList();
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let {
            name,
            sectionName,
            subjectName,
            questionType,
            type,
            url,
            year,
            knowledgesId,
            selectedAreaOptions,
          } = this.saveRuleForm;
          let provinceId = "",
            cityId = "",
            countyId = "",
            data = {};
          type = Number(type);
          console.log(selectedAreaOptions);

          if (type == 1) {
            if (selectedAreaOptions.length == 1) {
              provinceId = Number(selectedAreaOptions[0].split("_")[1]);
            } else if (selectedAreaOptions.length == 2) {
              provinceId = Number(selectedAreaOptions[0].split("_")[1]);
              cityId = Number(selectedAreaOptions[1].split("_")[1]);
            } else if (selectedAreaOptions.length == 3) {
              provinceId = Number(selectedAreaOptions[0].split("_")[1]);
              cityId = Number(selectedAreaOptions[1].split("_")[1]);
              countyId = Number(selectedAreaOptions[2].split("_")[1]);
            }
            data = {
              name,
              sectionName,
              subjectName,
              questionType,
              type,
              url,
              year,
              provinceId,
              cityId,
              countyId,
            };
          } else {
            data = {
              name,
              sectionName,
              subjectName,
              type,
              url,
              knowledgesId,
            };
          }
          if (this.saveDialog.type == "add") {
            publicSourceSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getPublicSourceList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.id = this.selectSourceList[0];
            publicSourceUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getPublicSourceList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if (this.selectSourceList.length != 1) {
        this.msgWarn("请选择单个资源进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑资源";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        name: "",
        sectionName: "",
        subjectName: "",
        questionType: "",
        type: "",
        url: "",
        year: "",
        knowledgesId: "",
        selectedAreaOptions: [],
      };
      let selectedAreaOptions = [];
      this.saveDialogVisible = true;
      publicSourceInfo(this.selectSourceList[0]).then(async (res) => {
        if (res.code == 0) {
          let {
            name,
            sectionName,
            subjectName,
            type,
            url,
            year,
            questionType,
            knowledgesId,
            provinceId,
            cityId,
            countyId,
          } = res.rows;
          this.saveRuleForm = {
            name,
            sectionName,
            subjectName,
            type,
            url,
            year,
            knowledgesId,
            questionType,
            selectedAreaOptions: [],
          };
          if (this.saveRuleForm.type == 1) {
            this.sectionList = sectionList_1;
            this.subjectList = subjectList_1;
          } else if (this.saveRuleForm.type == 2) {
            this.sectionList = sectionList_2;
            this.subjectList = subjectList_2;
            await this.dealKnowledge();
          }
          let provinceCode,
            cityCode,
            provinceList = [],
            cityList = [],
            countyList = [],
            provinceIndex,
            cityIndex;
          provinceList = await systemAreaSelect({
            areaCode: this.areaParams.parentCode,
          });
          provinceList.forEach((item, index) => {
            item.disabled = true;
            item.index = `${index}_${item.areaId}_${item.areaCode}`;
            if (item.areaId == provinceId) {
              provinceIndex = index;
              selectedAreaOptions.push(item.index);
              provinceCode = item.areaCode;
            }
            if (item.isParent) {
              item.children = [];
            }
            if (item.name == "北京市") {
              item.disabled = false;
            }
            if (
              item.name == "北京市" && (this.saveRuleForm.sectionName == "高考真题" || this.saveRuleForm.sectionName == "中考真题")
            ) {
              item.children = null;
            }
          });
          this.areaList = provinceList;
          if (cityId) {
            cityList = await systemAreaSelect({
              areaCode: provinceCode,
            });
            cityList.forEach((item, index) => {
              item.index = `${index}_${item.areaId}_${item.areaCode}`;
              if (item.areaId == cityId) {
                selectedAreaOptions.push(item.index);
                cityCode = item.areaCode;
                cityIndex = index;
              }
              if (item.isParent) {
                item.children = [];
              }
            });
            this.areaList[provinceIndex].children = cityList;
          }
          if (countyId) {
            countyList = await systemAreaSelect({
              areaCode: cityCode,
            });
            countyList.forEach((item, index) => {
              item.index = `${index}_${item.areaId}_${item.areaCode}`;
              if (item.areaId == countyId) {
                selectedAreaOptions.push(item.index);
              }
              if (item.isParent) {
                item.children = [];
              }
            });
            this.areaList[provinceIndex].children[cityIndex].children =
              countyList;
          }
          this.saveRuleForm.selectedAreaOptions = selectedAreaOptions;
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增资源";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        name: "",
        sectionName: "",
        subjectName: "",
        type: "",
        url: "",
        year: "",
        suggest: 0,
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload();
    },
    delClick() {
      if (this.selectSourceList.length == 0) {
        this.msgWarn("请至少选择一个资源！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          publicSourceRemove(this.selectSourceList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getPublicSourceList();
            }
          });
        })
        .catch(() => {});
    },
    sourceItemClick(row) {
      this.$refs.sourceListTable.toggleRowSelection(row);
    },
    dealType(row) {
      let type;
      switch (row.type) {
        case 1:
          type = "试卷";
          break;
        case 2:
          type = "课件";
          break;
      }
      return type;
    },
  },
  created() {},
  watch: {
    saveDialogVisible: function (val, oldVla) {
      if (this.$refs["saveRuleForm"] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    },
  },
  mounted() {
    this.getPublicSourceList();
    this.getAreaList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .class_title {
    height: 30px;
    line-height: 30px;
    text-indent: 12px;
  }
  .table_area {
    margin-left: 20px;
    padding: 10px 4px;
    .class_tip {
      height: 40px;
      line-height: 40px;
      text-indent: 4px;
      span {
        margin-right: 20px;
      }
    }
  }
  .self_table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-collapse: collapse;
    border-spacing: 0px;
    tr {
      border: 1px solid #ebeef5;
      td {
        border: 1px solid #ebeef5;
        text-align: center;
        vertical-align: middle;
        height: 40px;
      }
    }
  }
  .required_subject {
    height: auto;
    margin-bottom: 10px;
  }
  .select_subject {
    .select_subject_item {
      .item_subject_title {
        font-weight: 900;
        padding-left: 32px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      .item_score {
        display: flex;
        /deep/ .el-input {
          width: 40%;
        }
        > span {
          width: 20px;
          text-align: center;
        }
      }
    }
  }
  .open_tip {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .item_title span:nth-child(1) {
    font-weight: 900;
    padding-left: 32px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }
  .item_title span:nth-child(2) {
    font-weight: 500;
    font-size: 16px;
    margin-left: 20px;
    color: #000;
  }
}
</style>