<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">交易类型：</span>
          <el-select
            v-model="listParams.type"
            placeholder="请选择交易类型名称"
            size="small"
            @change="handleTypeChange"
          >
            <el-option
              v-for="item in typeList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            ></el-option>
          </el-select>
        </div>
        
        <div class="select-condition-item">
          <span class="item-lable">交易时间：</span>
          <el-date-picker
            size="small"
            v-model="selectDate"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            popper-class="popper_date"
            @change="handleDatePickerChange"
          ></el-date-picker>
        </div>
      </div>
      <div class="right">
        <el-button type="primary" size="small" @click="$router.back()"
          >返回</el-button
        >
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="organChargeList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        ref="organListTable"
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column prop="orgName" label="机构名称" align="center">
        </el-table-column>
        <el-table-column
          prop="transationTime"
          label="交易时间"
          align="center"
        ></el-table-column>

        <el-table-column label="类型" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">充值</span>
            <span v-else-if="scope.row.type == 2">平台赠送</span>
            <span v-else-if="scope.row.type == 3">流量费消耗</span>
            <span v-else-if="scope.row.type == 4">流量费取消</span>
            <span v-else-if="scope.row.type == 5">余额清零</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="transationBalance"
          label="金额(元)"
          align="center"
        ></el-table-column>

        <el-table-column label="收支" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.intOutType == 1">收</span>
            <span v-else-if="scope.row.intOutType == 2">支</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="accountBalance"
          label="余额(元)"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <div class="list_tip">
      <span>总计</span>
      <span class="mL20">收入金额：{{ incomeBalance }}元</span>
      <span class="mL20">支出金额：{{ expenditureBalance }}元</span>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { organExchargeDetailList } from "@/api/system/systemManage";
export default {
  data() {
    return {
      incomeBalance: "", // 收入金额
      expenditureBalance: "", // 支出金额
      typeList: [
        {
          name: "全部",
          type: 0,
        },
        {
          name: "流量费消耗",
          type: 3,
        },
        {
          name: "充值",
          type: 1,
        },
        {
          name: "平台赠送",
          type: 2,
        },
        {
          name: "流量费取消",
          type: 4,
        },
        {
          name: "余额清零",
          type: 5,
        },
      ],
      organChargeList: [],
      total: 0,
      selectDate: [],
      listParams: {
        pageSize: 10,
        pageNumber: 1,
        orgId: "",
        searchBeginDate: null,
        searchEndDate: null,
        type: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const start = new Date();
              picker.$emit("pick", [start, start]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, start]);
            },
          },
          {
            text: "本周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 89);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  components: {},
  computed: {},
  mounted() {
    const { orgId } = this.$route.query;
    this.listParams.orgId = orgId;
    this.getOrganRechargeDetailList();
  },
  methods: {
    /* 日期选择改变 */
    handleDatePickerChange() {
      if (!this.selectDate) {
        this.listParams.searchBeginDate = null
        this.listParams.searchEndDate = null
        return;
      }
      this.listParams.searchBeginDate = this.selectDate[0]
      this.listParams.searchEndDate = this.selectDate[1]
      this.getOrganRechargeDetailList();
    },
    /* 当前页码改变 */
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getOrganRechargeDetailList();
    },
    /* 单页数量改变 */
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getOrganRechargeDetailList();
    },
    /* 获取平台充值列表 */
    getOrganRechargeDetailList() {
      let type = this.listParams.type;
      if (type == 0) {
        type = null;
      }
      organExchargeDetailList({ ...this.listParams, type }).then((res) => {
        console.log(res);
        const {
          total,
          rows,
          countMap: { expenditureBalance, incomeBalance },
        } = res.rows;
        this.expenditureBalance = expenditureBalance;
        this.incomeBalance = incomeBalance;
        this.total = total;
        this.organChargeList = rows;
      });
    },
    /* 处理类型选择 */
    handleTypeChange() {
      this.listParams.pageNumber = 1;
      this.getOrganRechargeDetailList();
    },
  },
};
</script>
<style lang='scss' scoped>
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .save-dialog-content {
    height: 500px;
    overflow-y: auto;
    padding-right: 20px;
  }

  .charge_tip {
    text-indent: 26px;
  }

  .list_tip {
    padding-right: 10px;
    height: 40px;
    line-height: 40px;
    text-align: right;
  }
}
</style>