<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
      <div class="left">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input v-model="listParams.username" placeholder="请输入用户名或手机号" size="small" @blur="searchFn()"></el-input>
          </el-col>
          <el-col :span="8">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="()=>(listParams.pageNumber=1,getSystemUserList())"
            >搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
          <el-button type="default" size="small" @click="enableClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-bofang" />
            </svg>
            <span>启用</span>
          </el-button>
          <el-button type="default" size="small" @click="disableClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-bofang-copy" />
            </svg>
            <span>禁用</span>
          </el-button>
          <el-button type="default" size="small" @click="resetPwClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-mima2" />
            </svg>
            <span>重置密码</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="userList"
        style="width: 100%;margin-bottom: 20px;"
        row-key="userId"
        border
        ref="userListTable"
        height="100%"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        @row-click="userItemClick"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="userId" label="编号" width="80" align="center"></el-table-column>
        <el-table-column prop="username" label="用户名" width="160" align="center"></el-table-column>
        <el-table-column prop="email" label="邮箱" align="center"></el-table-column>
        <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-show="scope.row.status == 1">正常</el-button>
            <el-button type="danger" size="mini" v-show="scope.row.status == 0">禁用</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="gmtCreate" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="inviteCode" label="邀请码" align="center"></el-table-column>
        <el-table-column prop="saleQrcode" label="二维码" align="center"></el-table-column>
        <el-table-column prop="remark" label="备注" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog :title="saveDialog.title" :visible.sync="saveDialogVisible" width="700px" center>
      <div class="save-dialog-content">
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="用户名" prop="username">
                <el-input
                  v-model="saveRuleForm.username"
                  autocomplete="off"
                  :disabled="saveDialog.type == 'edit'"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="密码" prop="password">
                <el-input
                  type="password"
                  v-model="saveRuleForm.password"
                  autocomplete="off"
                  :disabled="saveDialog.type == 'edit'"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="真实姓名" prop="name">
                <el-input v-model="saveRuleForm.name" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="手机号" prop="mobile" >
                <el-input v-model="saveRuleForm.mobile" autocomplete="off" :maxlength="11"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="saveRuleForm.email" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注" prop="remark">
            <el-input v-model="saveRuleForm.remark" autocomplete="off"></el-input>
          </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="最大新用户量" prop="dailyReceiveUsers">
                <el-input v-model="saveRuleForm.dailyReceiveUsers" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态">
                <el-radio-group v-model="saveRuleForm.status">
                  <el-radio label="0">禁用</el-radio>
                  <el-radio label="1">正常</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col >
              <el-form-item label="领取用户类型" prop="collectionUserType">
                <el-checkbox-group v-model="collectionUserTypeList" @change="changes()">
                    <el-checkbox :label="1">新用户</el-checkbox>
                    <el-checkbox :label="2">被释放的客户</el-checkbox>
                    <el-checkbox :label="3">有效客户</el-checkbox>
                    <el-checkbox :label="4">无效客户</el-checkbox>
                  </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSave('saveRuleForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemUserList,
  systemUserRoleList,
  systemUserSave,
  systemUserInfo,
  systemUserInfoUpdate,
  systemUserDelete,
  systemUserDisable,
  systemUserEnable,
  systemUserResetPw,
} from "@/api/system/systemManage";
export default {
  data() {
    let checkPhone = (rule, value, callback) => {
      // 当数据为空时，不进行校验
      if (!value) {
        return callback()
      }
     const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/
       setTimeout(() => {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号码'))
        }
      }, 100)
    }
    return {
      collectionUserTypeList:[],
      userList: [],
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        username: "",
        //notSelf: 'true',
        roleSign: 'sales',
        roleSignAdmin: 'salesAdmin',
      },
      total: 0,
      saveDialogVisible: false,
      saveRuleForm: {
        username: "",
        inviteCodeType: "xs",
        mobile: "",
        orgId:0,
        password: "",
        name: "",
        status: 1,
        remark: "",
        email: "",
        roleIdList: [],
        dailyReceiveUsers: '',

        dailyActualReceiveUsers:'',
        roleSign: 'sales',
      },
      saveRules: {
         mobile : [
         {
             validator: checkPhone, message: '请输入正确的手机号码',trigger: "blur" 
          }
        ],
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "真实姓名不能为空", trigger: "blur" },
        ],

      },
      roleList: [],
      selectUserList: [],
      saveDialog: {
        title: "新增销售",
        type: "add",
      },
    };
  },
  inject: ['reload'],
   watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
  methods: {
    changes(){
      this.saveRuleForm.collectionUserType=this.collectionUserTypeList.toString();
      // console.log( this.collectionUserTypeList.toString(),'123123...')
    },
    handleSelectionChange(val) {
      this.selectUserList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectUserList.push(val[i].userId);
        }
      }
    },
    searchFn(){
      this.getSystemUserList()
    },
    getSystemUserList() {
      systemUserList(this.listParams).then((res) => {
        console.log(res);
        this.userList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getSystemUserList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getSystemUserList();
    },
    getSystemUserRoleList() {
      systemUserRoleList().then((res) => {
        if (res.code == 0) {
          this.roleList = res.rows;
          for(let i = 0, len = this.roleList.length; i < len; i++){
            if(this.roleList[i].roleSign == this.saveRuleForm.roleSign){
                this.saveRuleForm.roleIdList = [this.roleList[i].roleId]
            }
          }
        }
      });
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            username: this.saveRuleForm.username,
            inviteCodeType: this.saveRuleForm.inviteCodeType,
            mobile: this.saveRuleForm.mobile,
            password: this.saveRuleForm.password,
            name: this.saveRuleForm.name,
            status: this.saveRuleForm.status,
            remark: this.saveRuleForm.remark,
            email: this.saveRuleForm.email,
            roleIdList: this.saveRuleForm.roleIdList,
            dailyReceiveUsers: this.saveRuleForm.dailyReceiveUsers,
            roleSign: 'sales',
            dailyActualReceiveUsers:this.saveRuleForm.dailyReceiveUsers,
            collectionUserType:this.collectionUserTypeList.toString()
          };
          if (this.saveDialog.type == "add") {
            systemUserSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemUserList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.userId = this.selectUserList[0];
            systemUserInfoUpdate(data).then((res) => {
              if (res.code == 0) {
                this.saveDialogVisible = false;
                this.getSystemUserList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    resetPwClick(){
      if (this.selectUserList.length > 1) {
        this.msgWarn("请选择单个用户进行密码重置操作！");
        return;
      }
      if (this.selectUserList.length == 0) {
        this.msgWarn("请选择一个用户进行密码重置操作！");
        return;
      }
      this.$prompt("请输入新的密码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        inputPattern: /\S+/,
        inputErrorMessage: "密码不能为空",
      })
        .then(({ value }) => {
          systemUserResetPw({
            password: value,
            userId: this.selectUserList[0]
          }).then(res => {
            if(res.code == 0){
              this.msgSuccess('密码修改成功！')
            }
          })
        })
        .catch(() => {
          
        })
    },
    editClick() {
      if (this.selectUserList.length > 1) {
        this.msgWarn("请选择单个用户进行编辑操作！");
        return;
      }
      if (this.selectUserList.length == 0) {
        this.msgWarn("请选择一个用户进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑销售";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        username: "",
        inviteCodeType: "xs",
        mobile: "",
        password: "",
        name: "",
        status: "1",
        remark: "",
        email: "",
        roleIdList: this.saveRuleForm.roleIdList,
        dailyReceiveUsers: '',
        dailyActualReceiveUsers:'',
        roleSign: 'sales'
      };
      this.saveDialogVisible = true;
      systemUserInfo(this.selectUserList[0]).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            username: res.rows.username,
            inviteCodeType: res.rows.inviteCodeType,
            mobile: res.rows.mobile,
            password: res.rows.password,
            name: res.rows.name,
            status: String(res.rows.status),
            remark: res.rows.remark,
            email: res.rows.email,
            roleIdList: res.rows.roleIdList,
            dailyReceiveUsers: res.rows.dailyReceiveUsers,
            roleSign: 'sales',
          };
           this.collectionUserTypeList = res.rows.collectionUserType
            .split(",")
            .map(function(item, index, array) {
              return item - 0;
            });
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增销售";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        orgId:0,
        username: "",
        inviteCodeType: "xs",
        mobile: "",
        password: "",
        name: "",
        status: "1",
        remark: "",
        email: "",
        roleIdList: this.saveRuleForm.roleIdList,
        dailyReceiveUsers:'',
        dailyActualReceiveUsers:'',
        roleSign: 'sales'
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload()
    },
    delClick() {
      if (this.selectUserList.length == 0) {
        this.msgWarn("请至少选择一个用户！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          systemUserDelete(this.selectUserList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSystemUserList();
            }
          });
        })
        .catch(() => {});
    },
    enableClick() {
      if (this.selectUserList.length == 0) {
        this.msgWarn("请至少选择一个用户！");
        return;
      }
      systemUserEnable(this.selectUserList).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.getSystemUserList();
        }
      });
    },
    disableClick() {
      if (this.selectUserList.length == 0) {
        this.msgWarn("请至少选择一个用户！");
        return;
      }
      systemUserDisable(this.selectUserList).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.getSystemUserList();
        }
      });
    },
    userItemClick(row){
       this.$refs.userListTable.toggleRowSelection(row);
    },
  },
  created() {},
  mounted() {
    this.getSystemUserList();
    this.getSystemUserRoleList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 300px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .save-dialog-content {
    padding-right: 20px;
  }
}
</style>