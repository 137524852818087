<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area select-condition">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">导入方式：</span>
          <el-select
            v-model="listParams.importType"
            placeholder="请选择"
            size="small"
            @change="()=>(listParams.pageNumber=1,getStudentList())"
          >
            <el-option
              v-for="item in sourceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">添加时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.keyword"
            placeholder="请输入学生姓名/学生电话"
            size="small"
             @blur="()=>(listParams.pageNumber=1,getStudentList())"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="()=>(listParams.pageNumber=1,getStudentList())"
            >搜索</el-button
          >
        </div>
      </div>
    </div>
    <div class="btn-area">
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="batchImport">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>批量导入</span>
          </el-button>
          <el-button type="default" size="small" @click="batchApportion">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>批量分配</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="studentList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="studentListTable"
        @selection-change="handleSelectionChange"
        @row-click="studentItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="batch"
          label="批次"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="number"
          label="编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="学生姓名"
          align="center"
        >
          <template slot-scope="scope" >
            <div  style="text-decoration:underline;cursor:pointer;" @click="daalDetail(scope.row)">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="phoneNo"
          label="联系方式"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sex"
          :formatter="dealSex"
          label="性别"
          align="center"
        ></el-table-column> -->
        <el-table-column
          :formatter="dealSourceType"
          label="导入方式"
          align="center"
        ></el-table-column>
         <!-- <el-table-column
          :formatter="dealSourceListType"
          label="来源渠道"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="createTime"
          label="添加时间"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-show="scope.row.saleId"
              @click.stop="dealApportion(scope.row)"
              >重新分配</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="!scope.row.saleId"
              @click.stop="dealApportion(scope.row)"
              >分配</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              @click.stop="dealDisable(scope.row)"
              v-show="scope.row.status == 1"
              >禁用</el-button
            >
            <el-button
              type="text"
              size="small"
              @click.stop="dealAble(scope.row)"
              v-show="scope.row.status == 0"
            >
              启用
            </el-button> -->
            <el-button
              type="text"
              size="small"
              v-show="isShow"
              @click.stop="daalDelete(scope.row)"
              >删除</el-button
            >
            <el-button
              type="text"
              size="small"
              @click.stop="daalEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <div class="info-title">学生信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学生姓名" prop="name">
                <el-input v-model="saveRuleForm.name" :maxlength="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="phoneNo">
                <el-input
                :disabled="saveDialog.type == 'edit'"
                  v-model="saveRuleForm.phoneNo"
                  oninput="if(value.length>11)value=value.slice(0,11)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="saveRuleForm.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item label="年级" prop="sectionId">
                <el-select
                  v-model="saveRuleForm.sectionId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sectionList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            
          </el-row>
          
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所在学校" prop="schoolName">
                <el-input v-model="saveRuleForm.schoolName"></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item label="学校所在区域" prop="schoolReach">
                <el-input v-model="saveRuleForm.schoolReach"></el-input>
              </el-form-item>
            </el-col>
            
          </el-row>
           <el-row :gutter="20">
             <el-col :span="12">
               <el-form-item label="来源渠道" prop="sourceType">
                <el-select
                  v-model="saveRuleForm.sourceType"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in addSourceTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
             </el-col>
             <el-col :span="12">
              <el-form-item label="孩子性格" prop="childNature">
                <el-input v-model="saveRuleForm.childNature"></el-input>
              </el-form-item>
            </el-col>
           </el-row>
           <!-- <div class="info-title" v-show="saveRuleForm.sourceType=='5'">转介绍信息</div> -->
          <el-row :gutter="20" v-show="saveRuleForm.sourceType=='5'">
            <el-col :span="12">
              <el-form-item label="转介绍人姓名" prop="referralstudent">
                <el-input v-model="saveRuleForm.referralstudent" :maxlength="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="转介绍人电话" prop="referralphone">
                <el-input v-model="saveRuleForm.referralphone" :maxlength="11"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
             <el-col :span="12">
              <el-form-item label="出生日期" prop="birthday">
                <el-date-picker
                  v-model="saveRuleForm.birthday"
                  type="date"
                  @change="birthdayChange"
                  :default-value="new Date('2003-01-01')"
                   :picker-options="expireTimeOption"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="星座" prop="constellationId">
                <el-select
                  filterable 
                  :disabled="true"
                  v-model="saveRuleForm.constellationId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in constellationList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
           
          </el-row>
          <el-form-item label="家庭住址">
            <el-row type="flex">
              <el-col :span="8">
                <el-select
                  v-model="saveRuleForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="provinceChange"
                >
                  <el-option
                    v-for="item in provinceList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveRuleForm.cityId"
                  placeholder="请选择"
                  size="small"
                  @change="cityChange"
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="mL20">
                <el-select
                  v-model="saveRuleForm.countyId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in countyList"
                    :key="item.areaId"
                    :label="item.name"
                    :value="item.areaId"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="学生其他需求">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              maxlength="500"
              v-model="saveRuleForm.studentsOtherNeeds">
            </el-input>
          </el-form-item>
          <div class="info-title">家长信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="母亲姓名" prop="childMotherName">
                <el-input v-model="saveRuleForm.childMotherName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="母亲电话" prop="childMotherPhone">
                <el-input v-model="saveRuleForm.childMotherPhone" :maxlength="11"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="父亲姓名" prop="childFatherName">
                <el-input v-model="saveRuleForm.childFatherName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="父亲电话" prop="childFatherPhone">
                <el-input v-model="saveRuleForm.childFatherPhone" :maxlength="11"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="微信/QQ" prop="parentWechat">
            <el-input v-model="saveRuleForm.parentWechat"></el-input>
          </el-form-item>
          <div class="info-title">空闲时间</div>
          <div class="class_title">首次上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in firstClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <el-form-item label="上课时间备注" prop="firstClassTimeRemark">
              <el-input v-model="saveRuleForm.firstClassTimeRemark"></el-input>
            </el-form-item>
          </div>
          <div class="class_title">正式上课时间</div>
          <div class="table_area">
            <table class="self_table">
              <thead>
                <tr>
                  <td>周一</td>
                  <td>周二</td>
                  <td>周三</td>
                  <td>周四</td>
                  <td>周五</td>
                  <td>周六</td>
                  <td>周日</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in formalClassTimeList" :key="index">
                  <td v-for="(subItem, subIndex) in item" :key="subIndex">
                    <el-checkbox v-model="subItem.status">{{
                      subItem.name
                    }}</el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="class_tip">
              <span>上午：8:00-12:00</span>
              <span>下午：12:00-18:00</span>
              <span>晚上：18:00-22:00</span>
            </div>
            <el-form-item label="上课时间备注" prop="formalClassTimeRemark">
              <el-input v-model="saveRuleForm.formalClassTimeRemark"></el-input>
            </el-form-item>
          </div>
          <!-- <div class="open_tip" v-show="saveDialog.type == 'save'">
            <el-button type="text" @click="isOpen = !isOpen">{{
              isOpen ? "收缩" : "展开"
            }}</el-button>
          </div> -->
          <div v-show="isOpen || saveDialog.type == 'edit'">
            <div class="info-title">课程信息</div>
            <el-tabs stretch>
              <el-tab-pane label="文化课">
                <div class="required_subject left">
                  <span>选择必考科目：</span>
                  <template v-for="item in subjectList">
                    <el-checkbox
                      v-model="item.select"
                      v-show="item.value == 'sys.teach.subject.base'"
                      class="mR10 mT10"
                      :key="item.macroId"
                      >{{ item.name }}</el-checkbox
                    >
                  </template>
                </div>
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.base'"
                      class="mR10 mT10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
              <el-tab-pane label="小语种">
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.language'"
                      class="mR10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
              <el-tab-pane label="艺术类">
                <div class="left">
                  <template v-for="item in subjectList">
                    <el-button
                      type="primary"
                      v-model="item.status"
                      v-show="item.value == 'sys.teach.subject.art'"
                      class="mR10"
                      :key="item.macroId"
                      size="small"
                      :disabled="item.status"
                      @click="item.status = !item.status"
                      >{{ item.name }}</el-button
                    >
                  </template>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div class="select_subject">
              <div
                class="select_subject_item"
                v-for="(item,index) in subjectList" :key="index"
                v-show="item.status"
              >
                <div class="item_subject_title left item_title" style="margin-top:30px;">
                  <span>{{ item.name }}</span>
                </div>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="讲课速度">
                      <el-select
                        v-model="item.speedNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachSpeedList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="性别偏好" prop="sexNeedId">
                      <el-select
                        v-model="item.sexNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachSexList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="年龄偏好" prop="ageNeedId">
                      <el-select
                        v-model="item.ageNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachYearList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="风格偏好" prop="styleNeedId">
                      <el-select
                        v-model="item.styleNeedId"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in teachStyleList"
                          :key="item.macroId"
                          :label="item.name"
                          :value="item.macroId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <p style="font-weight: 600;font-size:16px;margin-left:30px;" >学生成绩<el-button size="mini" type="primary" @click="addStudentResult(item,index)" style="margin-left:10px;">添加</el-button></p>

                <ul class="paperUl">
                  <li v-for="(item,indexs) in item.xmtStudentAcademicRecordList" :key="indexs">
                    <span>{{examinationTimeFn(item.examinationTime)}}</span>
                    <span>{{examinationTypeFn(item.examinationType)}}</span>
                    <span>{{item.subjectScore}}/{{item.subjectAllScore}}</span>
                    <span>{{item.remarks}}</span>
                    <span>
                    <span style="color:#1890ff;cursor:pointer;" @click="paperPreview(item)">预览</span>
                    <span style="color:#1890ff;cursor:pointer;padding:0 5px;" @click="editStudent(item)">编辑</span>
                    <span style="color:#1890ff;cursor:pointer;" @click="paperRemove(item,index,indexs)">删除</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          :disabled="isSaveDisable"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="apportionDialog.title"
      :visible.sync="apportionVisible"
      :close-on-click-modal="false"
      width="500px"
      center
    >
      <div class="dialog-content">
        <div class="item_select">
          <span>请选择销售：</span>
          <el-select
            v-model="saleData.saleId"
            placeholder="请选择"
            size="small"
            @change="saleChange"
          >
            <el-option
              v-for="item in saleList"
              :key="item.userId"
               :label="`${item.username}（${item.name}）`"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="record">
          <el-checkbox v-model="saleData.record">可查看跟踪记录</el-checkbox>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="apportionVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmApportion()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="批量导入"
      :visible.sync="batchImportVisible"
      width="700px"
      :close-on-click-modal="false"
      center
    >
      <div class="dialog-content">
        <div style="text-align: left">
          <el-button type="success" size="small" @click="selectFileClick"
            >选择文件</el-button
          >
          <el-button type="primary" size="small" @click="uploadSelectFileClick"   :disabled="isDisable">
            开始上传
          </el-button>
          <el-button type="primary" size="small" @click="downloadTemplate"   >
            下载模板
          </el-button>
          <input
            type="file"
            multiple
            style="display: none"
            ref="batchImport"
            @change="selectFileChange"
          />
        </div>
        <el-table
          :data="selectFile"
          border
          height="400"
          style="width: 100%; margin-top: 20px"
          size="small"
          row-key="id"
        >
          <el-table-column prop="file.name" label="文件名" align="center">
          </el-table-column>
          <el-table-column prop="file.size" label="大小" align="center">
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span v-show="scope.row.status == 0">待上传</span>
              <span v-show="scope.row.status == 1">上传成功</span>
              <span v-show="scope.row.status == 2"
                >上传失败(原因：{{ scope.row.importMsg }})</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="amount2" label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="danger"
                v-show="scope.row.status == 0 || scope.row.status == 2"
                size="small"
                @click="selectFileDel(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-table
          :data="errorFile"
          border
          height="400"
          style="width: 100%; margin-top: 20px"
          size="small"
          row-key="id"
        >
          <el-table-column prop="name" label="学生姓名" align="center">
          </el-table-column>
          <el-table-column prop="account" label="学生电话" align="center">
          </el-table-column>
           <el-table-column prop="importMsg" label="状态" align="center">
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="batchImportVisible = false"
          size="small"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="studentResultDialog.title"
      :visible.sync="studentResultSync"
      :close-on-click-modal="false"
      width="800px"
      center
    >
      <div class="dialog-content">
         <el-form  
          :model="studentRuleForm"
          status-icon
          :rules="studentResultRules"
          ref="studentRuleForm"
          label-width="100px"
          size="small"
        >
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="考试时间" prop="examinationTime">
                 <el-date-picker
                  class="time_selector"
                  v-model="studentRuleForm.examinationTime"
                  type="date"
                  :picker-options="expireTimeOption"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="考试类型" prop="examinationType">
                 <el-select
                      v-model="studentRuleForm.examinationType"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in examinationTypeList"
                        :key="item.macroId"
                        :label="item.name"
                        :value="item.macroId"
                      ></el-option>
                    </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="12">
              <el-form-item label="成绩" prop="subjectScore">
                <el-input
                  v-model="studentRuleForm.subjectScore"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总分" prop="subjectAllScore" >
                <el-input
                  v-model="studentRuleForm.subjectAllScore"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="备注说明" prop="remarks">
                <el-input
                  v-model="studentRuleForm.remarks"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="试卷上传" prop="testPapers">
                <el-upload  
                accept="image/jpeg, image/png/, image/gif"
                :action="fileUpload" 
                :file-list="testPapers" 
                list-type="picture-card" 
                :on-preview="handlePictureCardPreview" 
                multiple
                :on-remove="handleRemove" 
                :limit="8" 
                :on-success="handleAvatarSuccess"
                ><i class="el-icon-plus"></i>
              </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
       <span slot="footer" class="dialog-footer">
        <el-button @click="studentResultSync = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmStudent('studentRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="试卷预览"
      :visible.sync="previewDialogVisible"
      :close-on-click-modal="false"
      width="650px"
      center
    >
      <div class="dialog-content" style="margin-top:30px;">
         <el-form  
          :model="previewRuleForm"
          status-icon
          :rules="previewResultRules"
          ref="previewRuleForm"
          label-width="100px"
          size="small"
        >
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="考试时间：" prop="examinationTime">
                {{previewRuleForm.examinationTime}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="考试类型：" prop="examinationType">
                {{examinationTypeFn(previewRuleForm.examinationType)}}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="12">
              <el-form-item label="成绩：" prop="subjectScore">
                {{previewRuleForm.subjectScore}}分
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总分：" prop="subjectAllScore">
                {{previewRuleForm.subjectAllScore}}分
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="备注说明：" prop="remarks">
                {{previewRuleForm.remarks}}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
           <el-col :span="20">
              <el-form-item label="试卷上传：" prop="testPapers">
                <el-image v-for="(ele,index) in previewImage" :key="index"
                      style="width: 100px; height: 100px;margin-right:10px;"
                      :src="ele"
                      :preview-src-list="getPreviewImgList(index)">
                </el-image>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  studentList,
  getNewCustomerPool,
  studentUpdate,
  deleteStudentToken,
  studentDelete,
  listNoPage,
  studentImport,
  getSaleId,
  studentSave,
  studentInfo,
  infoByParentCodeAndValue,
  reapportionList,
  saleBack,
  academicrecordSave,//学生添加试卷信息接口
  getObjectById,//学生添加试卷信息接口
  academicrecordRemove,//学生删除试卷接口
  academicrecordUpdate,//学生编辑试卷接口
  
} from "@/api/sales/saleManage";
import { systemAreaList ,downloadExcel} from "@/api/system/systemManage";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  fileUpload,

} from "@/api/public";
import dayjs from "dayjs";
let id = 0;
export default {
  inject: ["reload"],
  data() {
    let checkPhone = (rule, value, callback) => {
      // 当数据为空时，不进行校验
      if (!value) {
        return callback()
      }
     const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/
       setTimeout(() => {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号码'))
        }
      }, 100)
    }
    return {
      fileUpload,
      isShow: false,
      saleData: {
        saleId: "",
        saleName: "",
        record: true
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs()
                  .subtract(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(7, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(30, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs()
                  .add(1, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs()
                  .subtract(90, "day")
                  .format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      getMacroId:'',
      getSubjectId:'',
      studentResultSync:false,
      studentRuleForm:{
        examinationType:"",//考试类型,
        subjectScore:"",//成绩
        subjectAllScore:"",//总分
        examinationTime:"",//考试时间
        remarks:"",//备注说明
      },
      testPapers:[],
      studentResultRules:{
        examinationTime: [
          { required: true, message: "考试时间不能为空", trigger: "blur" },
        ],
        subjectScore: [
           { required: true,
            validator: (rule, value, callback) => {
              let regNum = /^[0-9]+$/
              if (value === '') {
                callback(new Error('成绩不能为空不能为空'))
              } else if (!regNum.test(value)) {
                callback(new Error('仅限输入数字格式'))
              } else {
                callback()
              }
            },
            trigger: 'blur' }
        ],
        subjectAllScore: [
          { required: true,
            validator: (rule, value, callback) => {
              let regNum = /^[0-9]+$/
              if (value === '') {
                callback(new Error('总分不能为空不能为空'))
              } else if (!regNum.test(value)) {
                callback(new Error('仅限输入数字格式'))
              } else {
                callback()
              }
            },
            trigger: 'blur' }
        ],
       
      },
      examinationTypeList:[
        {
          macroId:"1",
          name:"模拟考试"
        },
        {
          macroId:"2",
          name:"期中"
        },
        {
          macroId:"3",
          name:"期末"
        },
        {
          macroId:"4",
          name:"月考"
        },
        {
          macroId:"5",
          name:"随堂练"
        },
      ],
       studentResultDialog:{
        title:"添加试卷信息",
        type:"add"
      },
      timePickerValue: "",
      errorFile:[],
      studentList: [],
      listParams: {
        searchBeginDate: null,
        searchEndDate: null,
        pageNumber: 1,
        pageSize: 10,
        sectionId: "",
        dataAuthority: "dept",
        saleName: "",
        sourceType: "",
        importType:""
      },
       addSourceTypeList:[
        {
          value: "1",
          label: "地推活动"
        },
        {
          value: "2",
          label: "网络活动"
        },
         {
          value: "3",
          label: "美团活动"
        },
        {
          value: "4",
          label: "A级客户"
        },
        {
          value: "5",
          label: "家长转介绍"
        },
      ],
      sourceTypeList:[
        {
          value: "",
          label: "全部"
        },
        {
          value: "1",
          label: "地推活动"
        },
        {
          value: "2",
          label: "网络活动"
        },
         {
          value: "3",
          label: "美团活动"
        },
        {
          value: "4",
          label: "A级客户"
        },
        {
          value: "5",
          label: "家长转介绍"
        },
      ],
      sourceList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "1",
          label: "批量导入"
        },
        {
          value: "2",
          label: "销售添加"
        },
        {
          value: "3",
          label: "自主登录"
        },
        // {
        //   value: "4",
        //   label: "教务添加"
        // },
        {
          value: "5",
          label: "邀请码"
        }
      ],
      constellationList: [],
      tagsTotal: 0,
      total: 0,
      saveDialogVisible: false,
      saveRuleForm: {
        name: "",
        phoneNo: "",
        sex: "1",
        sectionId: "",
        birthday: "",
        provinceId: "",
        cityId: "",
        countyId: "",
        schoolName: "",
        sourceType: "",
        areaId: "",
        constellationId: "",
        childNature: "",
        childMotherName: "",
        childMotherPhone: "",
        childFatherName: "",
        childFatherPhone: "",
        parentWechat: "",
        firstClassTime: "",
        firstClassTimeRemark: "",
        formalClassTime: "",
        formalClassTimeRemark: "",
        qitaxueke:0,
        yuwen: 0,
        shuxue: 0,
        yingyu: 0,
        lishi: 0,
        zhengzhi: 0,
        dili: 0,
        wuli: 0,
        huaxue: 0,
        shengwu: 0,
        xmtStudentSubjectRelationEntityList: [],
        dataAuthority: "dept" // dept 销售主管添加
      },
       expireTimeOption: {
        disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() > Date.now();
        }
      },
      firstClassTimeList: [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ],
      formalClassTimeList: [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ],
      isDisable:false,
      saveRules: {
        sectionId: [
          { required: true, message: "年级不能为空", trigger: "blur" }
        ],
        name: [
          { required: true, message: "学生姓名不能为空", trigger: "blur" }
        ],
        phoneNo: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let mobile = /^1[3|4|5|6|7|8|9]\d{9}$/;
              let phone = /^0\d{2,3}-?\d{7,8}$/;
              if (value === "") {
                callback(new Error("手机号不能为空"));
              } else if (!mobile.test(value) && !phone.test(value)) {
                callback(new Error("请输入正确手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        childMotherPhone : [
          {
             validator: checkPhone, message: '请输入正确的手机号码'
          }
        ],
        childFatherPhone : [
          {
             validator: checkPhone, message: '请输入正确的手机号码'
          }
        ]
      },
      previewImage:[],
      previewRuleForm:{},
      previewResultRules:{},
      previewDialogVisible:false,
      provinceList: [],
      isSaveDisable:false,
      cityList: [],
      countyList: [],
      selectStudentList: [],
      selectStudentListObj: [],
      apportionDialog: {
        title: "分配",
        type: "1"
      },
      sectionList: [],
      sectionLists:[],
      apportionVisible: false,
      saleList: [],
      saleParams: {
        roleSign: "sales",
        status: 1
      },
      batchImportVisible: false,
      selectFile: [],
      parentId: "0",
      areaId: "",
      saveDialog: {
        title: "新增学生",
        type: "save"
      },
      subjectList: [],
      teachSpeedList: [],
      teachYearList: [],
      teachStyleList: [],
      teachSexList: [
        {
          value: 1,
          label: "男老师"
        },
        {
          value: 2,
          label: "女老师"
        },
        {
          value: 0,
          label: "均可"
        }
      ],
      curOptStudent: {},
      isOpen: false
    };
  },
   watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
  methods: {
    // daalSaleBack(row) {
    //   saleBack({ ids: [row.id] }).then(res => {
    //     if (res.code == 0) {
    //       this.msgSuccess("释放成功！");
    //       this.getStudentList();
    //     }
    //   });
    // },
    birthdayChange(){
      let birthdayArray=this.saveRuleForm.birthday.split('-');
      let month =Number(birthdayArray[1]);//月
      let date =Number(birthdayArray[2]);//日
      console.log(birthdayArray)
      if(month==1 &&date>=20||month==2 &&date<=18){
          this.saveRuleForm.constellationId=256;
      }
      if (month == 2 && date >=19 || month == 3 && date <=20) {
          this.saveRuleForm.constellationId=257;
      }
      if (month == 3 && date >=21 || month == 4 && date <=19){
          this.saveRuleForm.constellationId=246;
      }
      if (month == 4 && date >=20 || month == 5 && date <=20){
        this.saveRuleForm.constellationId=247;
      }
      if (month == 5 && date >=21 || month == 6 && date <=21){
        this.saveRuleForm.constellationId=248;
      }
      if (month == 6 && date >=22 || month == 7 && date <=22){
        this.saveRuleForm.constellationId=249;
      }
      if (month == 7 && date >=23 || month == 8 && date <=22){
        this.saveRuleForm.constellationId=250;
      }
      if (month == 8 && date >=23 || month == 9 && date <=22){
        this.saveRuleForm.constellationId=251;
      }
      if (month == 9 && date >=23 || month == 10 && date <=22){
        this.saveRuleForm.constellationId=252;
      }
      if (month == 10 && date >=23 || month == 11 && date <=21){
        this.saveRuleForm.constellationId=253;
      }
      if (month == 11 && date >=22 || month == 12 && date <=21){
         this.saveRuleForm.constellationId=254;
      }
      if (month == 12 && date >=22 || month == 1 && date <=19){
        this.saveRuleForm.constellationId=255;
      }
    },
    deleteSubject(item) {
      item.status = false;
      item.subjectScore = "";
      item.subjectAllScore = "";
      item.sexNeedId = "";
      item.styleNeedId = "";
      item.speedNeedId = "";
      item.ageNeedId = "";
    },
    getTeachSpeedList() {
      getSelectList({
        parentCode: teachSpeed
      }).then(res => {
        if (res.code == 0) {
          this.teachSpeedList = res.rows;
          this.teachSpeedList.unshift({
            macroId: 0,
            name: "均可",
          });
        }
      });
    },
    getTeacYearList() {
      getSelectList({
        parentCode: teachYear
      }).then(res => {
        if (res.code == 0) {
          this.teachYearList = res.rows;
          this.teachYearList.unshift({
            macroId: 0,
            name: "均可",
          });
        }
      });
    },
    getConstellationList() {
      getSelectList({
        parentCode: constellation
      }).then(res => {
        if (res.code == 0) {
          this.constellationList = res.rows;
        }
      });
    },
    getTeachStyleList() {
      getSelectList({
        parentCode: teachStyle
      }).then(res => {
        if (res.code == 0) {
          this.teachStyleList = res.rows;
          this.teachStyleList.unshift({
            macroId: 0,
            name: "均可",
          });
          
        }
      });
    },
    getProvinceList() {
      return systemAreaList({
        parentId: this.parentId
      }).then(res => {
        if (res.code == 0) {
          this.provinceList = res.rows;
        }
      });
    },
    getCityList() {
      return systemAreaList({
        parentId: this.saveRuleForm.provinceId
      }).then(res => {
        if (res.code == 0) {
          this.cityList = res.rows;
        }
      });
    },

    //试卷预览
    paperPreview(item){
      this.previewDialogVisible=true;
      this.paperId=item.id;
      if(item.id){
        getObjectById(
          item.id
        )
          .then(res => {
            if (res.code == 0) {
                this.previewRuleForm=res.rows;
                this.previewImage=res.rows.testPapers?res.rows.testPapers.split(','):[];
                this.previewRuleForm.examinationTime=res.rows.examinationTime.split(' ')[0];
            }
          })
          .catch(() => {});
      }else{
        this.previewRuleForm.examinationType=item.examinationType;
        this.previewRuleForm.subjectScore=item.subjectScore;
        this.previewRuleForm.subjectAllScore=item.subjectAllScore;
        this.previewRuleForm.remarks=item.remarks;
        this.previewImage=item.testPapers.split(',');
        let date = new Date(item.examinationTime);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        this.previewRuleForm.examinationTime = y + '-' + m + '-' + d;
      }
    },
    //试卷编辑
    editStudent(item){
      this.editId=item.id;
      this.testPapers=[]
      this.studentResultSync=true;
      this.studentResultDialog={
        title:"编辑试卷信息",
        type:"edit"
      }
      if(item.id){
        getObjectById(
         item.id
        )
      .then(res => {
        if (res.code == 0) {
            this.studentRuleForm=res.rows;
            let getTestPapers=res.rows.testPapers.split(',');
            if(res.rows.testPapers!='undefined'){
              getTestPapers.forEach(item => {  //处理图片需要转格式
              this.testPapers.push({
                "url": item,
              })
            })
            }else{
              this.testPapers=[];
            }
        }
      })
      .catch(() => {});
      }else{
        this.studentRuleForm.examinationType=item.examinationType;
        this.studentRuleForm.subjectScore=item.subjectScore;
        this.studentRuleForm.subjectAllScore=item.subjectAllScore;
        this.studentRuleForm.remarks=item.remarks;
        let getTestPapers=item.testPapers.split(',');
        if(item.testPapers!='undefined'){
          getTestPapers.forEach(item => {  //处理图片需要转格式
            this.testPapers.push({
              "url": item,
            })
          })
        }else{
          this.testPapers=[];
        }
        let date = new Date(item.examinationTime);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        this.studentRuleForm.examinationTime = y + '-' + m + '-' + d;
      }
    },
    downloadTemplate(){
          const link = document.createElement("a");
          link.href = 'https://liveoms.oss-cn-beijing.aliyuncs.com/audios/5ef3212bfc4b420bb4bc68fff018df78.xls';
          // 对下载的文件命名
          link.download =  "模板.xls";
          link.click();
    },
    //试卷删除
    paperRemove(item,index,indexs){
      if(item.id==''||item.id==undefined){
        this.subjectList[index].xmtStudentAcademicRecordList.splice(indexs,1);
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        academicrecordRemove(
         item.id
        )
          .then(res => {
            if (res.code == 0) {
              this.msgSuccess("删除成功！");
              this.daalEdit();
              this.subjectList[index].xmtStudentAcademicRecordList.splice(indexs,1);
            }
          })
          .catch(() => {});
      });
    },
    getCountyList() {
      return systemAreaList({
        parentId: this.saveRuleForm.cityId
      }).then(res => {
        if (res.code == 0) {
          this.countyList = res.rows;
        }
      });
    },
    async provinceChange() {
      await this.getCityList();
      this.saveRuleForm.cityId = this.cityList[0].areaId;
      this.cityChange();
    },
    async cityChange() {
      await this.getCountyList();
      this.saveRuleForm.countyId = this.countyList[0].areaId;
    },
    confirmApportion() {
      if (!this.saleData.saleId) {
        this.msgWarn("请选择需要分配的销售！");
        return;
      }
      this.apportionVisible = false;
      let arr = [];
      if (this.apportionDialog.type == 3) {
        for (let i = 0, len = this.selectStudentListObj.length; i < len; i++) {
          arr.push({
            no: this.selectStudentListObj[i].no,
            name: this.selectStudentListObj[i].name,
            id: this.selectStudentListObj[i].id,
            saleId: this.saleData.saleId,
            saleName: this.saleData.saleName,
            allocationRecordType: 2,
            isCheckFollowup: this.saleData.record ? 1 : 0
          });
        }
      } else {
        this.curOptStudent.saleId = this.saleData.saleId;
        this.curOptStudent.saleName = this.saleData.saleName;
        this.curOptStudent.allocationRecordType = 2;
        this.curOptStudent.isCheckFollowup = this.saleData.record ? 1 : 0;
        arr.push(this.curOptStudent);
      }
      reapportionList(arr).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.getStudentList();
        }
      });
    },
    getSectionList() {
      getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          // this.sectionList.unshift({
          //   macroId: '',
          //   name: "全部"
          // });
        }
      });
    },
    getSectionListSearch(){
       getSelectList({
        parentCode: sectionCode
      }).then(res => {
        if (res.code == 0) {
          this.sectionLists = res.rows;
          this.sectionLists.unshift({
            macroId: '',
            name: "全部"
          });
        }
      });
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.searchBeginDate = null;
        this.listParams.searchEndDate = null;
        return;
      }
      this.listParams.searchBeginDate = this.timePickerValue[0];
      this.listParams.searchEndDate = this.timePickerValue[1];
    },
    handleSelectionChange(val) {
      this.selectStudentList = [];
      this.selectStudentListObj = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectStudentList.push(val[i].id);
          this.selectStudentListObj.push(val[i]);
        }
      }
    },
    searchFn(){
      this.getStudentList();
    },
    getStudentList() {
      getNewCustomerPool(this.listParams).then(res => {
        this.studentList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getStudentList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getStudentList();
    },
     handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleAvatarSuccess(file, fileList){
        this.testPapers.push({url:file[0].url});
      },
      handleRemove(file, fileList) {
      for (var i=0;i<this.testPapers.length;i++) {
        if (this.testPapers[i].url === file.url) {
            this.testPapers.splice(i,1);
        }
      }
      },
       getPreviewImgList(index){
          let tempImgList = [...this.previewImage];//所有图片地址
                if (index == 0) return tempImgList;
                // 调整图片顺序，把当前图片放在第一位
                let start = tempImgList.splice(index);
                let remain = tempImgList.splice(0, index);
                return start.concat(remain);//将当前图片调整成点击缩略图的那张图片
    },
      confirmStudent(formName){
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.studentRuleForm.testPapers='';
          let papersList = this.testPapers.map((obj)=>{return obj.url})
            this.studentRuleForm.testPapers=papersList.join(',');
            this.studentRuleForm.studentId=this.id;
            // this.studentRuleForm.subjectId=this.getMacroId;
            if(this.studentResultDialog.type=='add'){
              for(var i=0;i<this.subjectList.length;i++){
                  if( this.subjectList[i].macroId==this.getSubjectId){
                    this.subjectList[i].xmtStudentAcademicRecordList.push(this.studentRuleForm)
                  }
              }
            }else if(this.studentResultDialog.type=='edit'){
              console.log(this.subjectList,'subjectList...')
              for(var i=0;i<this.subjectList.length;i++){
                if(this.subjectList[i].id){
                  for(var j=0;j<this.subjectList[i].xmtStudentAcademicRecordList.length;j++){
                    if(this.subjectList[i].xmtStudentAcademicRecordList[j].id==this.editId){
          
          
                      this.subjectList[i].xmtStudentAcademicRecordList[j]=this.studentRuleForm;
                    }
                  }
                }
                  
              }
            }
            this.studentResultSync=false;
          // if(this.studentResultDialog.type=='edit'){
          //       for(var i=0;i<this.testPapers.length;i++){
          //         this.studentRuleForm.testPapers+=this.testPapers[i].url+','.substr(0, this.studentRuleForm.testPapers.length - 1);
          //       }
          // }else{
          //   this.testPapers.map((obj)=>{return obj.url})
          //   this.studentRuleForm.testPapers=this.testPapers.join(',');
          // }
          //   this.studentRuleForm.studentId=this.id;
          //   this.studentRuleForm.subjectId=this.getMacroId;
          //   if(this.studentResultDialog.type=='add'){
          //     academicrecordSave(this.studentRuleForm)
          //       .then(res => {
          //         if (res.code == 0) {
          //           this.msgSuccess("操作成功！");
          //           this.studentResultSync=false;
          //           this.daalEdit();
          //         }
          //       })
          //       .catch(() => {});
          //   }else if(this.studentResultDialog.type=='edit'){
          //     // this.studentRuleForm.id=this.getMacroId;
          //       academicrecordUpdate(
          //       this.studentRuleForm
          //       )
          //         .then(res => {
          //           if (res.code == 0) {
          //             this.studentResultSync=false;
          //             this.msgSuccess("操作成功！");
          //             this.daalEdit();
          //           }
          //         })
          //         .catch(() => {});
          //    }
              }else {
                this.msgWarn("必填字段不能为空！");
                return false;
              }
      })
    },
    examinationTimeFn(row){
        let date = new Date(row);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        return y + '-' + m + '-' + d;
    },
    examinationTypeFn(row){
      if(row==1){
        return '模拟考试'
      }else if(row==2){
        return '期中'
      }else if(row==3){
        return '期末'
      }else if(row==4){
        return '月考'
      }else if(row==5){
        return '随堂练'
      }
    },
    confirmSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.isSaveDisable=true;
          let arr = [],
            firstClassTimeListArr = [],
            formalClassTimeListArr = [];
            this.saveRuleForm.qitaxueke = 0;
          this.saveRuleForm.yuwen = 0;
          this.saveRuleForm.shuxue = 0;
          this.saveRuleForm.yingyu = 0;
          this.saveRuleForm.lishi = 0;
          this.saveRuleForm.zhengzhi = 0;
          this.saveRuleForm.dili = 0;
          this.saveRuleForm.wuli = 0;
          this.saveRuleForm.huaxue = 0;
          this.saveRuleForm.shengwu = 0;
          for (let i = 0, len = this.subjectList.length; i < len; i++) {
            if (this.subjectList[i].select) {
              switch (this.subjectList[i].name) {
                case "语文":
                  this.saveRuleForm.yuwen = 1;
                  break;
                case "数学":
                  this.saveRuleForm.shuxue = 1;
                  break;
                case "英语":
                  this.saveRuleForm.yingyu = 1;
                  break;
                case "历史":
                  this.saveRuleForm.lishi = 1;
                  break;
                case "政治":
                  this.saveRuleForm.zhengzhi = 1;
                  break;
                case "地理":
                  this.saveRuleForm.dili = 1;
                  break;
                case "物理":
                  this.saveRuleForm.wuli = 1;
                  break;
                case "化学":
                  this.saveRuleForm.huaxue = 1;
                  break;
                case "生物":
                  this.saveRuleForm.shengwu = 1;
                  break;
              }
            }
            if (this.subjectList[i].status) {
              if(this.subjectList[i].id!=undefined||this.subjectList[i].id!=''){
                  arr.push({
                      studentId: this.saveRuleForm.id,
                      subjectId: this.subjectList[i].macroId,
                      subjectScore: this.subjectList[i].subjectScore,
                      subjectAllScore: this.subjectList[i].subjectAllScore,
                      sexNeedId: this.subjectList[i].sexNeedId,
                      styleNeedId: this.subjectList[i].styleNeedId,
                      speedNeedId: this.subjectList[i].speedNeedId,
                      ageNeedId: this.subjectList[i].ageNeedId,
                      content: this.subjectList[i].content,
                      id:this.subjectList[i].id,
                      xmtStudentAcademicRecordList:this.subjectList[i].xmtStudentAcademicRecordList
                    });
              }else{
                arr.push({
                    studentId: this.saveRuleForm.id,
                    subjectId: this.subjectList[i].macroId,
                    subjectScore: this.subjectList[i].subjectScore,
                    subjectAllScore: this.subjectList[i].subjectAllScore,
                    sexNeedId: this.subjectList[i].sexNeedId,
                    styleNeedId: this.subjectList[i].styleNeedId,
                    speedNeedId: this.subjectList[i].speedNeedId,
                    ageNeedId: this.subjectList[i].ageNeedId,
                    content: this.subjectList[i].content,
                    xmtStudentAcademicRecordList:this.subjectList[i].xmtStudentAcademicRecordList
                  });
              }
              // arr.push({
              //   studentId: this.saveRuleForm.id,
              //   subjectId: this.subjectList[i].macroId,
              //   subjectScore: this.subjectList[i].subjectScore,
              //   subjectAllScore: this.subjectList[i].subjectAllScore,
              //   sexNeedId: this.subjectList[i].sexNeedId,
              //   styleNeedId: this.subjectList[i].styleNeedId,
              //   speedNeedId: this.subjectList[i].speedNeedId,
              //   ageNeedId: this.subjectList[i].ageNeedId,
              //   content: this.subjectList[i].content
              // });
            }
          }
          for (let i = 0, len = this.firstClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.firstClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (this.firstClassTimeList[i][j].status) {
                firstClassTimeListArr.push(this.firstClassTimeList[i][j].key);
              }
            }
          }
          for (let i = 0, len = this.formalClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.formalClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (this.formalClassTimeList[i][j].status) {
                formalClassTimeListArr.push(this.formalClassTimeList[i][j].key);
              }
            }
          }
          this.saveRuleForm.firstClassTime = firstClassTimeListArr.join(",");
          this.saveRuleForm.formalClassTime = formalClassTimeListArr.join(",");
          this.saveRuleForm.xmtStudentSubjectRelationEntityList = arr;
          if (this.saveDialog.type == "save") {
              this.saveRuleForm.yuwen = 1;
              this.saveRuleForm.shuxue = 1;
              this.saveRuleForm.yingyu = 1;
            studentSave(this.saveRuleForm).then(res => {
              if (res.code == 0&&res.msg=='操作成功！') {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.isSaveDisable=false;
                this.getStudentList();
              }else{
                this.msgWarn(res.rows);
                this.isSaveDisable=false;
              }
            });
          } else {
            studentUpdate(this.saveRuleForm).then(res => {
              if (res.code == 0&&res.msg=='操作成功！') {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.isSaveDisable=false;
                this.getStudentList();
              }else{
                this.msgWarn(res.rows);
                this.isSaveDisable=false;
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    //添加学生成绩
    addStudentResult(item,index){
      this.getMacroId=item.id;
      this.getSubjectId=item.macroId;
      this.studentResultSync=true;
      this.studentRuleForm={
        examinationType:"",//考试类型,
        subjectScore:"",//成绩
        subjectAllScore:"",//总分
        examinationTime:"",//考试时间
        remarks:"",//备注说明
      }
      this.testPapers=[];
      this.studentResultDialog={
        title:"添加试卷信息",
        type:"add"
      }
    },

    addClick() {
      this.saveDialog = {
        title: "新增学生",
        type: "save"
      };
      this.getProvinceList();
      this.initClassTime();
      for (let i = 0, len = this.subjectList.length; i < len; i++) {
        this.subjectList[i].status = false;
        this.subjectList[i].select = false;
        this.subjectList[i].subjectScore = "";
        this.subjectList[i].subjectAllScore = "";
        this.subjectList[i].sexNeedId = "";
        this.subjectList[i].styleNeedId = "";
        this.subjectList[i].speedNeedId = "";
        this.subjectList[i].ageNeedId = "";
        this.subjectList[i].content = "";
      }
      this.saveRuleForm = {
        name: "",
        phoneNo: "",
        sex: "1",
        sectionId: "",
        birthday: "",
        provinceId: "",
        cityId: "",
        countyId: "",
        schoolName: "",
        sourceType: "",
        areaId: this.saveRuleForm.areaId,
        constellationId: "",
        childNature: "",
        childMotherName: "",
        childMotherPhone: "",
        childFatherName: "",
        childFatherPhone: "",
        parentWechat: "",
        firstClassTime: "",
        firstClassTimeRemark: "",
        formalClassTime: "",
        formalClassTimeRemark: "",
        qitaxueke:0,
        yuwen: 0,
        shuxue: 0,
        yingyu: 0,
        lishi: 0,
        zhengzhi: 0,
        dili: 0,
        wuli: 0,
        huaxue: 0,
        shengwu: 0,
        xmtStudentSubjectRelationEntityList: [],
        dataAuthority: "dept"
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload();
    },
    delClick() {
      if (this.selectStudentList.length == 0) {
        this.msgWarn("请至少选择一个资讯！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          studentDelete(this.selectStudentList).then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getStudentList();
            }
          });
        })
        .catch(() => {});
    },
    studentItemClick(row) {
      this.$refs.studentListTable.toggleRowSelection(row);
    },
    dealSex(row) {
      if (row.sex == 1) {
        return "男";
      } else if (row.sex == 2) {
        return "女";
      } else {
        return "";
      }
    },
    dealSourceType(row) {
      let type;
      for (let i = 0, len = this.sourceList.length; i < len; i++) {
        if (row.importType == this.sourceList[i].value) {
          type = this.sourceList[i].label;
          break;
        }
      }
      return type;
    },
     dealSourceListType(row) {
      let type;
      for (let i = 0, len = this.sourceTypeList.length; i < len; i++) {
        if (row.sourceType == this.sourceTypeList[i].value) {
          type = this.sourceTypeList[i].label;
          break;
        }
      }
      return type;
    },
    dealApportion(row) {
      if (row.saleId) {
        this.apportionDialog = {
          title: "重新分配",
          type: "2"
        };
      } else {
        this.apportionDialog = {
          title: "分配",
          type: "1"
        };
      }
      this.curOptStudent = {
        no: row.no,
        id: row.id,
        name: row.name
      };
      this.getSaleList();
      this.apportionVisible = true;
    },
    batchApportion() {
      this.apportionDialog = {
        title: "批量分配",
        type: "3"
      };
      if (this.selectStudentListObj.length < 1) {
        this.msgWarn("请至少选择一个学生！");
        return;
      } 
      this.getSaleList();
      this.apportionVisible = true;
    },
    dealAble(row) {
      studentUpdate({
        id: row.id,
        status: 1
      }).then(res => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.getStudentList();
        }
      });
    },
    async dealDisable(row) {
      this.$prompt("请输入禁用理由", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "禁用理由不能为空！",
        closeOnClickModal: false
      })
        .then(async ({ value }) => {
          let flag;
          let res = await deleteStudentToken(row.id);
          if (res.code == 0) {
            studentUpdate({
              id: row.id,
              status: 0,
              statusReason: value
            }).then(res => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.getStudentList();
              }
            });
          }
        })
        .catch(() => {});
    },
    daalDetail(row){
        this.$router.push({
        name: "sectionManageStudentInfo",
        params: {
          id: row.id,
          contract: -1,
          show:false,
          type:1
        }
      });
    },
    daalDelete(row) {
      let ids = [];
      if (row) {
        ids = [row.id];
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          studentDelete(ids).then(res => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getStudentList();
            }
          });
        })
        .catch(() => {});
    },
    batchImport() {
      this.batchImportVisible = true;
    },
    selectFileClick() {
      this.$refs.batchImport.click();
      this.$refs.batchImport.value = "";
    },
    uploadSelectFileClick() {
      // this.isDisable=true;
      for (let i = 0, len = this.selectFile.length; i < len; i++) {
        if (!this.selectFile[i].status) {
          this.dealFileUpload(this.selectFile[i]);
        }
      }
    },
    dealFileUpload(item) {
      this.isDisable=true;
      let formData = new FormData();
      formData.append("areaId", this.areaId);
      formData.append("file", item.file);
      studentImport(formData).then(res => {
        if (res.code == 0) {
          if (res.errorList.length > 0) {
            item.status = 2;
            item.importMsg = res.errorList[0].importMsg;
            console.log(res.errorList,'errorList')
            this.errorFile=res.errorList;
          } else if (res.successList.length > 0) {
            item.status = 1;
            item.importMsg = "";
          }
          this.isDisable=false;
          // this.batchImportVisible=false;
          this.getStudentList();
        }else{
          this.isDisable=false;
          // this.batchImportVisible=false;
          this.getStudentList();
        }
      });
    },
    selectFileDel(row) {
      for (let i = 0, len = this.selectFile.length; i < len; i++) {
        if (row && row.id == this.selectFile[i].id) {
          this.selectFile.splice(i, 1);
          break;
        }
      }
    },
    selectFileChange() {
      if (this.$refs.batchImport.files.length > 0) {
        for (
          let i = 0, len = this.$refs.batchImport.files.length;
          i < len;
          i++
        ) {
          this.selectFile.push({
            status: 0,
            file: this.$refs.batchImport.files[i],
            importMsg: "",
            id: ++id
          });
        }
      }
    },
    getSaleList() {
      listNoPage(this.saleParams).then(res => {
        this.saleList = res;
      });
    },
    saleChange() {
      for (let i = 0, len = this.saleList.length; i < len; i++) {
        if (this.saleList[i].userId == this.saleData.saleId) {
          this.saleData.saleName = this.saleList[i].username;
        }
      }
    },
    getSaleId() {
      getSaleId().then(res => {
        if (res.code == 0) {
          this.areaId = this.saveRuleForm.areaId = res.rows;
        }
      });
    },
    async daalEdit(row) {
      this.id=row.id;
      this.saveDialog = {
        title: "编辑学生",
        type: "edit"
      };
      this.saveDialogVisible = true;
      await this.getProvinceList();
      for (var i = 0; i < this.subjectList.length; i++) {
        this.subjectList[i].xmtStudentAcademicRecordList = [];
      }
      await studentInfo(row.id).then(res => {
        if (res.code == 0) {
          this.saveRuleForm = {
            studentsOtherNeeds:res.rows.studentsOtherNeeds,
            name: res.rows.name,
            phoneNo: res.rows.phoneNo,
            sex: String(res.rows.sex),
            sectionId: res.rows.sectionId,
            birthday: res.rows.birthday,
            provinceId: res.rows.provinceId,
            cityId: res.rows.cityId,
            countyId: res.rows.countyId,
            schoolName: res.rows.schoolName,
            sourceType:res.rows.sourceType!=0?String(res.rows.sourceType):"",
            areaId: this.saveRuleForm.areaId,
            constellationId: res.rows.constellationId,
            childNature: res.rows.childNature,
            childMotherName: res.rows.childMotherName,
            childMotherPhone: res.rows.childMotherPhone,
            childFatherName: res.rows.childFatherName,
            childFatherPhone: res.rows.childFatherPhone,
            parentWechat: res.rows.parentWechat,
            firstClassTime: res.rows.firstClassTime,
            firstClassTimeRemark: res.rows.firstClassTimeRemark,
            formalClassTime: res.rows.formalClassTime,
            formalClassTimeRemark: res.rows.formalClassTimeRemark,
            qitaxueke:res.rows.qitaxueke,
            yuwen: res.rows.yuwen,
            shuxue: res.rows.shuxue,
            yingyu: res.rows.yingyu,
            lishi: res.rows.lishi,
            zhengzhi: res.rows.zhengzhi,
            dili: res.rows.dili,
            wuli: res.rows.wuli,
            huaxue: res.rows.huaxue,
            shengwu: res.rows.shengwu,
            xmtStudentSubjectRelationEntityList: [],
            dataAuthority: "dept",
            id: res.rows.id,
            referralphone:res.rows.referralphone,
            referralstudent:res.rows.referralstudent,
            schoolReach:res.rows.schoolReach,
            contract:res.rows.contract
          };
          if (this.saveRuleForm.provinceId) {
            this.getCityList();
          }
          if (this.saveRuleForm.cityId) {
            this.getCountyList();
          }
          this.initClassTime();
          for (let i = 0, len = this.firstClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.firstClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.firstClassTime &&
                res.rows.firstClassTime.includes(
                  this.firstClassTimeList[i][j].key
                )
              ) {
                this.firstClassTimeList[i][j].status = true;
              }
            }
          }
          for (let i = 0, len = this.formalClassTimeList.length; i < len; i++) {
            for (
              let j = 0, lenJ = this.formalClassTimeList[i].length;
              j < lenJ;
              j++
            ) {
              if (
                res.rows.formalClassTime &&
                res.rows.formalClassTime.includes(
                  this.formalClassTimeList[i][j].key
                )
              ) {
                this.formalClassTimeList[i][j].status = true;
              }
            }
          }
          for (let i = 0, len = this.subjectList.length; i < len; i++) {
            this.subjectList[i].status = false;
            this.subjectList[i].select = false;
            this.subjectList[i].subjectScore = "";
            this.subjectList[i].subjectAllScore = "";
            this.subjectList[i].sexNeedId = "";
            this.subjectList[i].styleNeedId = "";
            this.subjectList[i].speedNeedId = "";
            this.subjectList[i].ageNeedId = "";
            this.subjectList[i].content = "";
            if (
              (this.subjectList[i].name == "其他学科" && res.rows.qitaxueke == 1) ||
              (this.subjectList[i].name == "语文" && res.rows.yuwen == 1) ||
              (this.subjectList[i].name == "数学" && res.rows.shuxue == 1) ||
              (this.subjectList[i].name == "英语" && res.rows.yingyu == 1) ||
              (this.subjectList[i].name == "历史" && res.rows.lishi == 1) ||
              (this.subjectList[i].name == "政治" && res.rows.zhengzhi == 1) ||
              (this.subjectList[i].name == "地理" && res.rows.dili == 1) ||
              (this.subjectList[i].name == "物理" && res.rows.wuli == 1) ||
              (this.subjectList[i].name == "化学" && res.rows.huaxue == 1) ||
              (this.subjectList[i].name == "生物" && res.rows.shengwu == 1)
            ) {
              this.subjectList[i].select = true;
            }
          }
          for (let i = 0, len = this.subjectList.length; i < len; i++) {
            for (
              let j = 0,
                lenJ = res.rows.xmtStudentSubjectRelationEntityList.length;
              j < lenJ;
              j++
            ) {
              if (
                this.subjectList[i].macroId ==
                res.rows.xmtStudentSubjectRelationEntityList[j].subjectId
              ) {
                this.subjectList[i].xmtStudentAcademicRecordList=res.rows.xmtStudentSubjectRelationEntityList[j].xmtStudentAcademicRecordList;
                this.subjectList[i].status = true;
                this.subjectList[i].subjectScore =
                  res.rows.xmtStudentSubjectRelationEntityList[j].subjectScore;
                this.subjectList[i].subjectAllScore =
                  res.rows.xmtStudentSubjectRelationEntityList[
                    j
                  ].subjectAllScore;
                this.subjectList[i].sexNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].sexNeedId;
                this.subjectList[i].styleNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].styleNeedId;
                this.subjectList[i].speedNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].speedNeedId;
                this.subjectList[i].ageNeedId =
                  res.rows.xmtStudentSubjectRelationEntityList[j].ageNeedId;
                this.subjectList[i].content =
                  res.rows.xmtStudentSubjectRelationEntityList[j].content;
                  this.subjectList[i].id =
                  res.rows.xmtStudentSubjectRelationEntityList[j].id;
              }
            }
          }
        }
      });
    },
    initClassTime() {
      this.formalClassTimeList = [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ];
      this.firstClassTimeList = [
        [
          {
            name: "上午",
            key: "a",
            status: false
          },
          {
            name: "上午",
            key: "b",
            status: false
          },
          {
            name: "上午",
            key: "c",
            status: false
          },
          {
            name: "上午",
            key: "d",
            status: false
          },
          {
            name: "上午",
            key: "e",
            status: false
          },
          {
            name: "上午",
            key: "f",
            status: false
          },
          {
            name: "上午",
            key: "g",
            status: false
          }
        ],
        [
          {
            name: "下午",
            key: "h",
            status: false
          },
          {
            name: "下午",
            key: "i",
            status: false
          },
          {
            name: "下午",
            key: "j",
            status: false
          },
          {
            name: "下午",
            key: "k",
            status: false
          },
          {
            name: "下午",
            key: "l",
            status: false
          },
          {
            name: "下午",
            key: "m",
            status: false
          },
          {
            name: "下午",
            key: "n",
            status: false
          }
        ],
        [
          {
            name: "晚上",
            key: "o",
            status: false
          },
          {
            name: "晚上",
            key: "p",
            status: false
          },
          {
            name: "晚上",
            key: "q",
            status: false
          },
          {
            name: "晚上",
            key: "r",
            status: false
          },
          {
            name: "晚上",
            key: "s",
            status: false
          },
          {
            name: "晚上",
            key: "t",
            status: false
          },
          {
            name: "晚上",
            key: "u",
            status: false
          }
        ]
      ];
    },
    getSubjectList() {
      return infoByParentCodeAndValue({
        parentCode: subjectCode
      }).then(res => {
        if (res.code == 0) {
          for (let i = 0, len = res.rows.length; i < len; i++) {
            res.rows[i].status = false;
            res.rows[i].select = false;
            res.rows[i].subjectScore = "";
            res.rows[i].subjectAllScore = "";
            res.rows[i].sexNeedId = "";
            res.rows[i].styleNeedId = "";
            res.rows[i].speedNeedId = "";
            res.rows[i].ageNeedId = "";
            res.rows[i].content = "";
            res.rows[i].xmtStudentAcademicRecordList =[];
          }
          this.subjectList = res.rows;
          console.log(this.subjectList, "subjectListsubjectList.......");
        }
      });
    }
  },
  created() {},
  mounted() {
    this.getStudentList();
    this.getSectionList();
    this.getSectionListSearch();
    this.getSaleId();
    this.getConstellationList();
    this.getTeachSpeedList();
    this.getTeacYearList();
    this.getTeachStyleList();
    this.getSubjectList();
  }
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .right {
      flex: 1;  
      display: flex;
      justify-content: flex-end;
    }
  }
  .select-condition {
    border-bottom: 1px solid #f1f1f1;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .score-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #000000;
    text-align: center;
    width: 80px;
  }
  .subject-item {
    height: 40px;
    line-height: 40px;
  }
  .self-radio-group {
    margin-left: 20px;
    .self-radio-group-item {
      margin-bottom: 8px;
    }
  }
  .class_title {
    height: 30px;
    line-height: 30px;
    text-indent: 12px;
  }
  .table_area {
    margin-left: 20px;
    padding: 10px 4px;
    .class_tip {
      height: 40px;
      line-height: 40px;
      text-indent: 4px;
      span {
        margin-right: 20px;
      }
    }
  }
  .self_table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-collapse: collapse;
    border-spacing: 0px;
    tr {
      border: 1px solid #ebeef5;
      td {
        border: 1px solid #ebeef5;
        text-align: center;
        vertical-align: middle;
        height: 40px;
      }
    }
  }
  .required_subject {
    height: auto;
    margin-bottom: 10px;
  }
  .select_subject {
    .select_subject_item {
      .item_subject_title {
        font-weight: 900;
        padding-left: 32px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      .item_score {
        display: flex;
        /deep/ .el-input {
          width: 40%;
        }
        > span {
          width: 20px;
          text-align: center;
        }
      }
    }
  }
  .open_tip {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
 
  .item_title span:nth-child(1){
        font-weight: 900;
        padding-left: 32px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      .item_title span:nth-child(2){
        font-weight: 500;
        font-size: 16px;
        margin-left:20px;
        color:#000;
      }
}
.paperUl{
    padding:0 20px;
    flex:1;
  }
  .paperUl li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:13px;
   
    
  }
</style>
