<template>
  <div class="main-content sys-role-manage sys-org-manage">
    <div class="btn-area">
      <div class="left"></div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table size="small" :data="orgList" style="width: 100%;margin-bottom: 20px;" row-key="orgId" border
        height="100%" tooltip-effect="dark" ref="orgListTable" @row-click="orgItemClick">
        <el-table-column width="66" align="center" label="单选" type>
          <template slot-scope="scope">
            <el-radio v-model="curOrgId" :label="scope.row.orgId">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="orgId" label="编号" width="100" align="center" type></el-table-column>
        <el-table-column prop="name" label="名称" align="left" header-align="center" width="250"></el-table-column>
        <el-table-column prop="parentName" label="上层机构" align="center"></el-table-column>
        <el-table-column label="可用" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-if="scope.row.status == 1">可用</el-button>
            <el-button type="danger" size="mini" v-else-if="scope.row.status == 0">禁用</el-button>
          </template>
        </el-table-column>
        <el-table-column label="机构标签" prop="organLabelName" align="center"></el-table-column>
        <el-table-column label="到期时间" prop="organEndTime" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="organCreateTime" align="center"></el-table-column>
        <el-table-column prop="orderNum" label="排序" align="center"></el-table-column>
      </el-table>
    </div>
    <el-dialog :title="saveDialog.title" :close-on-click-modal="false" :visible.sync="saveDialogVisible" width="500px"
      center>
      <el-dialog width="460px" title="选择机构" :visible.sync="showOrgSelect" append-to-body center>
        <div class="org-tree">
          <el-tree :data="treeData" :props="defaultProps" :default-expand-all="treeOptions.defaultExpandAll"
            @node-click="handleNodeClick" :expand-on-click-node="treeOptions.expandOnClickNode"
            :check-on-click-node="treeOptions.checkOnClickNode" :highlight-current="treeOptions.highlightCurrent"
            :current-node-key="treeOptions.currentNodeKey" append-to-body="true"></el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showOrgSelect = false" size="small">取 消</el-button>
          <el-button type="primary" @click="confirmOrgSelect" size="small">确 定</el-button>
        </span>
      </el-dialog>
      <div>
        <el-form :model="saveRuleForm" status-icon :rules="saveRules" ref="saveRuleForm" label-width="100px"
          size="small">
          <el-form-item label="名称" prop="name">
            <el-input v-model="saveRuleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="机构类型" prop="code">
            <el-select v-model="saveRuleForm.code" placeholder="请选择" size="small">
              <el-option v-for="item in codeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上级机构" prop="parentName" @click.native="orgSelectClick">
            <el-input v-model="saveRuleForm.parentName"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="orderNum">
            <el-input v-model="saveRuleForm.orderNum"></el-input>
          </el-form-item>
          <el-form-item label="机构标签" prop="organLabel">
            <el-radio-group v-model="saveRuleForm.organLabel">
              <el-radio :label="1">基础版</el-radio>
              <el-radio :label="2">专业版</el-radio>
              <el-radio :label="3">定制版</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="过期时间" prop="expirationTime">
            <el-date-picker v-model="saveRuleForm.expirationTime" type="date" value-format="yyyy-MM-dd" placeholder="选择过期时间" :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="可用" prop="status">
            <el-radio-group v-model="saveRuleForm.status">
              <el-radio label="0">禁用</el-radio>
              <el-radio label="1">可用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSave('saveRuleForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemOrgTreeList,
  systemOrgSave,
  systemOrgSelect,
  systemOrgUpdate,
  systemOrgRemove,
  systemOrgInfo,
} from "@/api/system/systemManage";
import { formatTree } from "@/utils/common";
import * as dayjs from 'dayjs'
export default {
  inject: ['reload'],
  watch: {
    saveDialogVisible: function (val, oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
  data() {
    return {
      orgList: [],
      saveDialogVisible: false,
      saveRuleForm: {
        code: "",
        layer: 1,
        name: "",
        orderNum: 0,
        parentId: "",
        parentIds: "",
        parentName: "",
        status: "1",
        organLabel: 1,  // 1 基础版  2 专业版 3 定制版
        expirationTime: '',
      },
      saveRules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        parentName: [
          { required: true, message: "上级机构不能为空", trigger: "blur" },
        ],
        organLabel: [{ required: true, message: "机构标签不能为空", trigger: "blur" }],
        expirationTime: [{ required: true, message: "过期时间不能为空", trigger: "blur" }],
      },
      pickerOptions: {
        disabledDate(date) {
           return date.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000;
        },
      },
      codeList: [
        { label: '学管部门', value: '001' },
        { label: '销售部门', value: '002' },
        { label: '财务部门', value: '003' },
      ],
      selectOrgList: [],
      saveDialog: {
        title: "新增机构",
        type: "add",
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeData: [],
      treeOptions: {
        defaultExpandAll: true,
        expandOnClickNode: false,
        checkOnClickNode: true,
        highlightCurrent: true,
        currentNodeKey: "",
        selectNode: {},
      },
      curOrgId: "",
      showOrgSelect: false,
    };
  },
  methods: {
    handleNodeClick(selectNode) {
      this.treeOptions.selectNode = selectNode;
    },
    getSystemOrgTreeList() {
      systemOrgTreeList().then((res) => {
        this.orgList = formatTree(res, {
          id: "orgId",
          parentId: "parentId",
        });
      });
    },
    confirmOrgSelect() {
      this.saveRuleForm.parentId = this.treeOptions.selectNode.orgId;
      this.saveRuleForm.parentName = this.treeOptions.selectNode.name;
      this.saveRuleForm.parentIds = this.treeOptions.selectNode.parentIds;
      this.showOrgSelect = false;
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            code: this.saveRuleForm.code,
            layer: this.saveRuleForm.layer,
            name: this.saveRuleForm.name,
            orderNum: this.saveRuleForm.orderNum,
            parentId: this.saveRuleForm.parentId,
            parentName: this.saveRuleForm.parentName,
            status: this.saveRuleForm.status,
            parentIds: this.saveRuleForm.parentIds,
            organLabel: this.saveRuleForm.organLabel,
            expirationTime: this.saveRuleForm.expirationTime,
          };
          if (this.saveDialog.type == "add") {
            systemOrgSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemOrgTreeList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.orgId = this.curOrgId;
            data.oldParentIds = this.saveRuleForm.oldParentIds;
            systemOrgUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemOrgTreeList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if (!this.curOrgId) {
        this.msgWarn("请选择单个机构进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑机构";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        code: "",
        layer: 1,
        name: "",
        orderNum: 0,
        parentId: "",
        parentIds: "",
        parentName: "",
        status: "1",
        expirationTime: '',
        organLabel: null,
      };
      this.saveDialogVisible = true;
      systemOrgInfo(this.curOrgId).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            code: res.rows.code,
            layer: 1,
            name: res.rows.name,
            orderNum: res.rows.orderNum,
            parentName: res.rows.parentName ? res.rows.parentName : "一级机构",
            parentId: res.rows.parentId,
            parentIds: res.rows.parentIds,
            status: String(res.rows.status),
            oldParentIds: res.rows.oldParentIds,
            expirationTime: res.rows.expirationTime,
            organLabel: res.rows.organLabel
          };
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增机构";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        code: "",
        layer: 1,
        name: "",
        orderNum: 0,
        parentId: "",
        parentIds: "",
        parentName: "",
        status: "1",
        organLabel: 1,
        expirationTime: '',
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload()
    },
    delClick() {
      if (!this.curOrgId) {
        this.msgWarn("请选择单个菜单进行操作！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          systemOrgRemove([this.curOrgId]).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSystemOrgTreeList();
            }
          });
        })
        .catch(() => { });
    },
    orgItemClick(row) {
      this.$refs.orgListTable.toggleRowSelection(row);
    },
    orgSelectClick() {
      this.treeOptions.selectNode = {};
      systemOrgSelect().then((res) => {
        this.treeData = formatTree(res, {
          id: "orgId",
          parentId: "parentId",
          originId: -1,
        });
        console.log(this.treeData);
      });
      this.showOrgSelect = true;
    },
  },
  created() { },
  mounted() {
    this.getSystemOrgTreeList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;

    .left {
      width: 300px;
    }

    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  .table-area {
    flex: 1;
    overflow: hidden;
  }
}
</style>