<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area">
      <div class="left">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input v-model="listParams.name" placeholder="请输入搜索关键字" size="small"></el-input>
          </el-col>
          <el-col :span="8">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="getSystemAppArticleList"
            >搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="articleList"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="articleListTable"
        @selection-change="handleSelectionChange"
        @row-click="articleItemClick"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column label="图片" align="center" width="240px">
          <template slot-scope="scope">
            <img :src="scope.row.photo" alt class="item-img" />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" align="center"></el-table-column>
        <el-table-column prop="articleTime" label="时间" align="center"></el-table-column>
        <el-table-column :formatter="dealArticleType" label="类型" align="center"></el-table-column>
        <el-table-column prop="browseNum" label="浏览数" align="center"></el-table-column>
        <el-table-column prop="forwardNum" label="转发数" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-show="scope.row.sendState==0"
              @click.stop="releaseClick(scope.row)"
              >发布</el-button
            >
            <el-button
              type="text"
              size="small"
              v-show="scope.row.sendState==1"
              >已发布</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      width="860px"
      center
      :close-on-click-modal="false"
      custom-class="article-dialog"
    >
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="标题" prop="title">
                <el-input v-model="saveRuleForm.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="时间" prop="articleTime">
                <el-date-picker v-model="saveRuleForm.articleTime" type="date" placeholder="选择日期" @change="dateValueChange" value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="类型" prop="type">
                <el-select v-model="saveRuleForm.type" placeholder="请选择">
                  <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发送范围" prop="sendType">
                <el-select v-model="saveRuleForm.sendType" multiple  placeholder="请选择">
                  <el-option
                    v-for="item in sendTypeList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="图片地址" prop="photo">
                <el-upload
                  accept="image/jpeg, image/png/, image/gif"
                  class="avatar-uploader"
                  :action="fileUpload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="saveRuleForm.photo" :src="saveRuleForm.photo" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="浏览数" prop="browseNum">
                <el-input v-model="saveRuleForm.browseNum"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="转发数" prop="forwardNum">
                <el-input v-model="saveRuleForm.forwardNum"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="副标题" prop="subhead">
            <el-input v-model="saveRuleForm.subhead"></el-input>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <editor
              :set_editor_content="set_editor_content"
              @edit_content_change="edit_content_change"
            ></editor>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSave('saveRuleForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemAppArticleList,
  systemAppArticleRemove,
  systemAppArticleSave,
  systemAppArticleInfo,
  systemAppArticleUpdate,
  sendArticle
} from "@/api/system/appManage"
import { fileUpload,getSelectList,phaseCode } from "@/api/public";
export default {
  inject: ["reload"],
  watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
  data() {
    return {
      articleList: [],
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        name: "",
      },
      set_editor_content: "",
      typeList: [
        {
          value: "1",
          label: "家长在线",
        },
        {
          value: "2",
          label: "教师在线",
        },
      ],
      fileUpload,
      total: 0,
      saveDialogVisible: false,
      saveRuleForm: {
        articleTime: new Date(),
        browseNum: "",
        content: "",
        forwardNum: "",
        photo: "",
        subhead: "",
        title: "",
        type: "",
      },
      sendTypeList:[],
      saveRules: {
        articleTime: [
          { required: true, message: "时间不能为空", trigger: "blur" },
        ],
        browseNum: [
          { required: true, message: "浏览数不能为空", trigger: "blur" },
        ],
        content: [
          { required: true, message: "内容不能为空", trigger: "blur" },
        ],
        forwardNum: [
          { required: true, message: "转发数不能为空", trigger: "blur" },
        ],
        photo: [
          { required: true, message: "图片地址不能为空", trigger: "blur" },
        ],
        subhead: [
          { required: true, message: "副标题不能为空", trigger: "blur" },
        ],
        title: [
          { required: true, message: "标题不能为空", trigger: "blur" },
        ],
        type: [
          { required: true, message: "类型不能为空", trigger: "blur" },
        ],
      },
      selectArticleList: [],
      saveDialog: {
        title: "新增好文",
        type: "add",
      },
    };
  },
  methods: {
    sendTypeListFn(){
      getSelectList({
        parentCode: phaseCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sendTypeList = res.rows;
        }
      });
    },
    releaseClick(row){
       sendArticle(row.id).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.getSystemAppArticleList();
        }
      });
    },  
    dateValueChange(){
      console.log(this.dateValue)
    },
    handleAvatarSuccess(res, file) {
      this.saveRuleForm.photo = res[0].url;
    },
    beforeAvatarUpload(file) {
      let reg = /\.(png|jpg|gif|jpeg)$/;
      if(!reg.test(file.name)){
        this.msgError('支持上传的图片格式为png，jpg，gif，jpeg；请检查格式是否正确！')
      }
      return reg.test(file.name);
    },
    edit_content_change(html) {
      this.saveRuleForm.content = html
    },
    handleSelectionChange(val) {
      this.selectArticleList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectArticleList.push(val[i].id);
        }
      }
    },
    getSystemAppArticleList() {
      systemAppArticleList(this.listParams).then((res) => {
        console.log(res);
        this.articleList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getSystemAppArticleList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getSystemAppArticleList();
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            articleTime: this.saveRuleForm.articleTime,
            browseNum: this.saveRuleForm.browseNum,
            content: this.saveRuleForm.content,
            forwardNum: this.saveRuleForm.forwardNum,
            photo: this.saveRuleForm.photo,
            subhead: this.saveRuleForm.subhead,
            title: this.saveRuleForm.title,
            type: this.saveRuleForm.type,
            sendType:this.saveRuleForm.sendType
          };
          if (this.saveDialog.type == "add") {
            systemAppArticleSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemAppArticleList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.id = this.selectArticleList[0];
            systemAppArticleUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemAppArticleList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if (this.selectArticleList.length != 1) {
        this.msgWarn("请选择单个好文进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑好文";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        articleTime: new Date(),
        browseNum: "",
        content: "",
        forwardNum: "",
        photo: "",
        subhead: "",
        title: "",
        type: "",
        sendType:[]
      };
      this.saveDialogVisible = true;
      systemAppArticleInfo(this.selectArticleList[0]).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            articleTime: res.rows.articleTime,
            browseNum: res.rows.browseNum,
            content: res.rows.content,
            forwardNum: res.rows.forwardNum,
            photo: res.rows.photo,
            subhead: res.rows.subhead,
            title: res.rows.title,
            type: String(res.rows.type),
            sendType:res.rows.sendTypeArray
          };
          this.set_editor_content = res.rows.content
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增好文";
      this.saveDialog.type = "add";
      this.set_editor_content="";
      this.saveRuleForm = {
        articleTime: new Date(),
        browseNum: "",
        content: "",
        forwardNum: "",
        photo: "",
        subhead: "",
        title: "",
        type: "",
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload();
    },
    delClick() {
      if (this.selectArticleList.length == 0) {
        this.msgWarn("请至少选择一个好文！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          systemAppArticleRemove(this.selectArticleList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSystemAppArticleList();
            }
          });
        })
        .catch(() => {});
    },
    articleItemClick(row) {
      this.$refs.articleListTable.toggleRowSelection(row);
    },
    dealArticleType(row) {
      let result;
      switch (row.type) {
        case 1:
          result = "家长在线";
          break;
        case 2:
          result = "教师在线";
          break;
       
      }
      return result;
    },
  },
  created() {},
  mounted() {
    this.sendTypeListFn();
    this.getSystemAppArticleList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 300px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
    .item-img {
      width: 200px;
      height: 100px;
    }
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .article-dialog {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 300px;
    }
  }
}
</style>