<template>
  <div class="main-content sys-role-manage">
    <div class="content-left">
      <div class="content-title">区域目录</div>
      <div class="org-tree tree-area">
        <el-tree
          :props="props"
          :load="loadNode"
          node-key="areaId"
          lazy
          :highlight-current="treeOptions.highlightCurrent"
          :expand-on-click-node="treeOptions.expandOnClickNode"
          :default-expand-all="treeOptions.defaultExpandAll"
          :default-expanded-keys="treeOptions.defaultExpandedKeys"
          @node-click="handleNodeClick"
          :key="treeKey"
          style="height: 400px"
        ></el-tree>
      </div>
    </div>
    <div class="content-right">
      <div class="content-title">区域信息</div>
      <div class="btn-area">
        <div class="left">
          <el-row :gutter="20">
            <el-col :span="16">
              <el-input v-model="listParams.name" placeholder="请输入搜索关键字" size="small"></el-input>
            </el-col>
            <el-col :span="8">
              <el-button
                type="primary"
                size="small"
                icon="el-icon-search"
                @click="getSystemAreaList"
              >搜索</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="right">
          <el-button-group>
            <el-button type="default" size="small" @click="refreshClick">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shuaxin" />
              </svg>
              <span>刷新</span>
            </el-button>
            <el-button type="default" size="small" @click="addClick">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-xinzeng" />
              </svg>
              <span>新增</span>
            </el-button>
            <el-button type="default" size="small" @click="editClick">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-edit" />
              </svg>
              <span>编辑</span>
            </el-button>
            <el-button type="default" size="small" @click="delClick">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-trash" />
              </svg>
              <span>删除</span>
            </el-button>
          </el-button-group>
        </div>
      </div>
      <div class="table-area">
        <el-table
          size="small"
          :data="areaList"
          style="width: 100%;margin-bottom: 20px;"
          row-key="areaId"
          border
          height="100%"
          tooltip-effect="dark"
          ref="areaListTable"
          @selection-change="handleSelectionChange"
          @row-click="areaItemClick"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column prop="areaId" label="编号" width="100" align="center"></el-table-column>
          <el-table-column prop="areaCode" label="区域代码" align="center"></el-table-column>
          <el-table-column prop="name" label="区域名称" align="center"></el-table-column>
          <el-table-column label="层级" align="center">
            <template slot-scope="scope">
              <el-button type="success" size="mini" v-if="scope.row.layer == 1">省级</el-button>
              <el-button type="danger" size="mini" v-else-if="scope.row.layer == 2">地市</el-button>
              <el-button type="info" size="mini" v-else-if="scope.row.layer == 3">区县</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="orderNum" label="排序" align="center"></el-table-column>
          <el-table-column label="可用" align="center">
            <template slot-scope="scope">
              <el-switch
                :value="scope.row.status == 1"
                active-color="#13ce66"
                inactive-color="#ff4949"
                disabled
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog :title="saveDialog.title" :close-on-click-modal="false" :visible.sync="saveDialogVisible" width="600px" center>
      <el-dialog width="460px" title="选择区域" :visible.sync="showAreaSelect" append-to-body center>
        <div class="org-tree">
          <el-tree
            node-key="id"
            :props="props"
            :load="loadNode"
            lazy
            :highlight-current="treeOptions.highlightCurrent"
            :expand-on-click-node="treeOptions.expandOnClickNode"
            :default-expand-all="treeOptions.defaultExpandAll"
            :default-expanded-keys="treeOptions.defaultExpandedKeys"
            @node-click="handleDialogNodeClick"
            :key="treeKey"
            ref="selectAreaTree"
            style="height: 400px"
          ></el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showAreaSelect = false" size="small">取 消</el-button>
          <el-button type="primary" @click="confirmAreaSelect" size="small">确 定</el-button>
        </span>
      </el-dialog>
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="区域代码" prop="areaCode">
            <el-input v-model="saveRuleForm.areaCode"></el-input>
          </el-form-item>
          <el-form-item label="区域名称" prop="name">
            <el-input v-model="saveRuleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="上级区域" prop="parentName" @click.native="selectAreaClick">
            <el-input v-model="saveRuleForm.parentName"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="saveRuleForm.orderNum"></el-input>
          </el-form-item>
          <el-form-item label="层级" prop="layer">
            <el-radio-group v-model="saveRuleForm.layer">
              <el-radio label="1">省级</el-radio>
              <el-radio label="2">地市</el-radio>
              <el-radio label="3">区县</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="可用" prop="status">
            <el-radio-group v-model="saveRuleForm.status">
              <el-radio label="0">禁用</el-radio>
              <el-radio label="1">正常</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="saveRuleForm.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSave('saveRuleForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemAreaSelect,
  systemAreaList,
  systemAreaInfo,
  systemAreaSave,
  systemAreaUpdate,
  systemAreaRemove,
} from "@/api/system/systemManage";
import { formatTree } from "@/utils/common";
export default {
  inject: ['reload'],
  data() {
    return {
      areaList: [],
      listParams: {
        parentCode: "0",
        sortOrder: "asc",
        name: "",
      },
      saveDialogVisible: false,
      saveRuleForm: {
        areaCode: "",
        layer: "1",
        name: "",
        orderNum: 0,
        parentCode: "0",
        parentName: "顶层",
        remark: "",
        status: "1",
      },
      saveRules: {
        areaCode: [
          { required: true, message: "区域代码不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "区域名称不能为空", trigger: "blur" },
        ],
        parentName: [
          { required: true, message: "上级区域不能为空", trigger: "blur" },
        ],
      },
      selectAreaList: [],
      saveDialog: {
        title: "新增区域",
        type: "add",
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      props: {
        label: "name",
      },
      treeOptions: {
        defaultExpandAll: false,
        expandOnClickNode: true,
        highlightCurrent: true,
        defaultExpandedKeys: [0],
        selectNode: {},
        dialogSelectNode: {},
      },
      treeKey: new Date().getTime(),
      showAreaSelect: false,
    };
  },
  watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
  methods: {
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([
          {
            name: "顶层目录",
            areaId: 0,
            areaCode: 0,
          },
        ]);
      } else if (node.level > 0) {
        this.listParams.parentCode = node.data.areaCode;
        return systemAreaSelect({
          areaCode: this.listParams.parentCode,
        }).then((res) => {
          return resolve(res);
        });
      }
    },
    handleNodeClick(selectNode) {
      this.listParams.parentCode = selectNode.areaCode;
      this.getSystemAreaList();
      this.treeOptions.selectNode = selectNode;
    },
    handleDialogNodeClick(selectNode){
      this.treeOptions.dialogSelectNode = selectNode
    },
    handleSelectionChange(val) {
      this.selectAreaList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectAreaList.push(val[i].areaId);
        }
      }
    },
    getSystemAreaList() {
      systemAreaList(this.listParams).then((res) => {
        this.areaList = res.rows;
      });
    },
    confirmAreaSelect(){
      if(this.treeOptions.dialogSelectNode.areaCode !== undefined){
        this.saveRuleForm.parentName = this.treeOptions.dialogSelectNode.name
        this.saveRuleForm.parentCode = this.treeOptions.dialogSelectNode.areaCode
      }
      this.showAreaSelect = false
    },
    selectAreaClick(){
      this.showAreaSelect = true; 
      this.treeOptions.dialogSelectNode = {}; 
      if(this.$refs.selectAreaTree){
        this.$refs.selectAreaTree.setCurrentKey(null)
      }
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            areaCode: this.saveRuleForm.areaCode,
            layer: this.saveRuleForm.layer,
            name: this.saveRuleForm.name,
            orderNum: this.saveRuleForm.orderNum,
            parentCode: this.saveRuleForm.parentCode,
            parentName: this.saveRuleForm.parentName,
            remark: this.saveRuleForm.remark,
            status: this.saveRuleForm.status,
          };
          if (this.saveDialog.type == "add") {
            systemAreaSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemAreaList();
                this.treeKey = new Date().getTime()
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.areaId = this.selectAreaList[0];
            systemAreaUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemAreaList();
                this.treeKey = new Date().getTime()
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if (this.selectAreaList.length != 1) {
        this.msgWarn("请选择单个区域进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑区域";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        areaCode: "",
        layer: "1",
        name: "",
        orderNum: 0,
        parentCode: "0",
        parentName: "顶层",
        remark: "",
        status: "1",
      };
      this.saveDialogVisible = true;
      systemAreaInfo(this.selectAreaList[0]).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            areaCode: res.rows.areaCode,
            layer: String(res.rows.layer),
            name: res.rows.name,
            orderNum: res.rows.orderNum,
            parentCode: res.rows.orderNum,
            parentName: res.rows.parentName,
            remark: res.rows.remark,
            status: String(res.rows.status),
          };
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增区域";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        areaCode: "",
        layer: "1",
        name: "",
        orderNum: 0,
        parentCode: this.treeOptions.selectNode.areaCode ? this.treeOptions.selectNode.areaCode : "0",
        parentName: this.treeOptions.selectNode.name ? this.treeOptions.selectNode.name : "顶层目录",
        remark: "",
        status: "1",
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload()
    },
    delClick() {
      if (this.selectAreaList.length == 0) {
        this.msgWarn("请至少选择一个区域！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          systemAreaRemove(this.selectAreaList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSystemAreaList();
              this.treeKey = new Date().getTime()
            }
          });
        })
        .catch(() => {});
    },
    areaItemClick(row) {
      this.$refs.areaListTable.toggleRowSelection(row);
    },
  },
  created() {},
  mounted() {
    this.getSystemAreaList();
    //this.getAreaTree();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: -webkit-box;
  display: flex;
  height: calc(100% - 10px);
  margin-top: 10px;
  .content-title {
    height: 40px;
    background-color: #f5f5f5;
    line-height: 40px;
    width: 100%;
    text-indent: 24px;
  }
  .content-left {
    border: 1px solid #ebeef5;
    width: 260px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .tree-area {
      flex: 1;
      overflow: auto;
    }
  }
  .content-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .btn-area {
      height: 50px;
      display: flex;
      align-items: center;
      .left {
        width: 300px;
      }
      .right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
}
</style>