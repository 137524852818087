<template>
        <div class="main-content sys-user-manage">
            <div class="btn-area">
                <div class="left">
                     <div class="select-condition-item">
                    <span class="item-lable">关键字：</span>
                    <el-input
                        v-model="listParams.keyword"
                        placeholder="请输入姓名/联系方式/批次/编号"
                        size="small"
                        style="min-width:120px"
                    ></el-input>
                    </div>
                    <div class="select-condition-item">
                    <span class="item-lable">年级：</span>
                    <el-select
                        v-model="listParams.sectionId"
                        placeholder="请选择"
                        size="small"
                        @change="()=>(listParams.pageNumber=1,getAssignedCustomersFn())"
                    >
                        <el-option
                        v-for="item in sectionList"
                        :key="item.macroId"
                        :label="item.name"
                        :value="item.macroId"
                        ></el-option>
                    </el-select>
                    </div>
                    <div class="select-condition-item">
                    <span class="item-lable">标签：</span>
                    <el-select
                        v-model="listParams.ifEffective"
                        placeholder="请选择"
                        size="small"
                    >   
                        <el-option key="" label="全部"  value=""></el-option>
                        <el-option key="Y" label="有效"  value="Y"></el-option>
                        <el-option key="N" label="无效"  value="N"></el-option>
                    </el-select>
                    </div>
                    <div class="select-condition-item">
                    <span class="item-lable">意向程度：</span>
                    <el-select
                        v-model="listParams.intentionId"
                        placeholder="请选择"
                        size="small"
                        multiple
                        collapse-tags
                    >   
                         <el-option
                        v-for="item in intentionList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :disabled="item.disabled"
                        ></el-option>
                    </el-select>
                    </div>
                    
                    
                   
                    <div class="select-condition-item">
                    <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        @click="()=>(listParams.pageNumber=1,getAssignedCustomersFn())"
                        >搜索</el-button
                    >
                    </div>
                </div>
                </div>
                <div class="btn-area">
                  <div class="left">
                    <div class="select-condition-item">
                    <span class="item-lable">分配时间：</span>
                    <el-date-picker
                        size="small"
                        v-model="timePickerValue"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        @change="datePickerChange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']"
                    ></el-date-picker>
                    </div>
                    <div class="select-condition-item">
                    <span class="item-lable">请选择销售：</span>
                    <el-select
                        v-model="listParams.saleId"
                        placeholder="请选择"
                        size="small"
                        @change="saleChangeSearch"
                    >   
                         <el-option
                          v-for="item in saleListTitle"
                          :key="item.userId"
                          :label="`${item.username}（${item.name}）`"
                          :value="item.userId"
                        >
                        </el-option>
                    </el-select>
                    </div>
                    <div class="select-condition-item">
                      <span class="item-lable">设置时间：</span>
                      <el-date-picker
                        size="small"
                        v-model="intentionTimeValue"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        @change="intentionTimeChange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']"
                      ></el-date-picker>
                    </div>
                  </div>
                </div>
                <div class="table-area">
      <el-table
        size="small"
        :data="tableList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="studentListTable"
        @selection-change="handleSelectionChange"
        @row-click="allocatedItemClick"
      >
        <!-- <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="batch"
          label="批号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="number"
          label="编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="学生姓名"
          align="center"
        >
          <template slot-scope="scope" >
          <div  style="text-decoration:underline;cursor:pointer;" @click="daalDetail(scope.row)">{{ scope.row.name }}</div>
        </template>
        </el-table-column>
        <!-- <el-table-column
          prop="phoneNo"
          label="联系方式"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sex"
          :formatter="dealSex"
          label="性别"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="saleName"
          label="所属销售"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{scope.row.saleName}}（{{scope.row.saleRealName}}）</span>
          </template>
        </el-table-column>
         <el-table-column
          label="标签"
          :formatter="dealIfEffective"
          align="center"
        ></el-table-column>
        <el-table-column
          prop=""
          label="意向程度"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{intentionIdFn(scope.row)}}</p>
            <p>{{scope.row.intentionTime}}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="createTime"
          :formatter="dealSourceListType"
          label="来源渠道"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="createTime"
          label="添加时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="allocateTime"
          label="最新分配时间"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.stop="dealApportion(scope.row)"
              >重新分配</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableTotal"
        background
      ></el-pagination>
    </div>
     <el-dialog
      title="重新分配"
      :visible.sync="apportionVisible"
      :close-on-click-modal="false"
      width="500px"
      center
    >
      <div class="dialog-content">
        <div class="item_select">
          <span >请选择销售：</span>
          <el-select
            v-model="saleData.saleId"
            placeholder="请选择"
            size="small"
            @change="saleChange"
            style="margin-top:15px;"
          >
            <el-option
              v-for="item in saleList"
              :key="item.userId"
              :label="`${item.username}（${item.name}）`"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="record"  style="margin-top:10px;">
          <el-checkbox v-model="saleData.record">可查看跟踪记录</el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="apportionVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmApportion()" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
        </div>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation
} from "@/api/public";
import {getAssignedCustomers,listNoPage,reapportionList} from "@/api/sales/saleManage";
import dayjs from "dayjs";
    export default{
        data(){
            return{
              intentionTimeValue:"",
              saleListTitle:[],
                listParams:{
                    saleId:"",
                    sectionId:"",
                    sourceType:"",
                    keyword:"",
                    intentionId:"",
                    searchBeginDate: null,
                    searchEndDate: null,
                    pageNumber: 1,
                    pageSize: 10,
                    dataAuthority: "dept",
                    ifEffective:''
                },
                curOptStudent:{},
                saleData: {
                    saleId: "",
                    saleName: "",
                    record: true
                },
                saleParams: {
                    roleSign: "sales",
                    status: 1
                },
                tableList:[],
                tableTotal:0,
                saleList:[],
                timePickerValue:"",
                sectionList:[],
                apportionVisible:false,
                intentionList:[
                  {
                    value: "",
                    label: "全部",
                    disabled: true
                  },
                  {
                      value: 1,
                      label: "意向较强"
                  },
                  {
                      value: 2,
                      label: "意向简单"
                  },
                  {
                      value: 3,
                      label: "了解阶段"
                  },
                  {
                      value: 4,
                      label: "无意向"
                  },
                ],
                sourceTypeList:[
                {
                    value: "",
                    label: "全部"
                },
                {
                    value: "1",
                    label: "地推活动"
                },
                {
                    value: "2",
                    label: "网络活动"
                },
                {
                    value: "3",
                    label: "美团活动"
                },
                {
                    value: "4",
                    label: "A级客户"
                },
                {
                    value: "5",
                    label: "家长转介绍"
                },
            ],
            pickerOptions: {
            shortcuts: [
            {
                text: "今天",
                onClick(picker) {
                const end = new Date(
                    dayjs()
                    .add(1, "day")
                    .format("YYYY-MM-DD") + " 00:00:00"
                );
                const start = new Date(
                    dayjs().format("YYYY-MM-DD") + " 00:00:00"
                );
                picker.$emit("pick", [start, end]);
                }
            },
            {
                text: "昨天",
                onClick(picker) {
                const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
                const start = new Date(
                    dayjs()
                    .subtract(1, "day")
                    .format("YYYY-MM-DD") + " 00:00:00"
                );
                picker.$emit("pick", [start, end]);
                }
            },
            {
                text: "最近一周",
                onClick(picker) {
                const end = new Date(
                    dayjs()
                    .add(1, "day")
                    .format("YYYY-MM-DD") + " 00:00:00"
                );
                const start = new Date(
                    dayjs()
                    .subtract(7, "day")
                    .format("YYYY-MM-DD") + " 00:00:00"
                );
                picker.$emit("pick", [start, end]);
                }
            },
            {
                text: "最近一个月",
                onClick(picker) {
                const end = new Date(
                    dayjs()
                    .add(1, "day")
                    .format("YYYY-MM-DD") + " 00:00:00"
                );
                const start = new Date(
                    dayjs()
                    .subtract(30, "day")
                    .format("YYYY-MM-DD") + " 00:00:00"
                );
                picker.$emit("pick", [start, end]);
                }
            },
            {
                text: "最近三个月",
                onClick(picker) {
                const end = new Date(
                    dayjs()
                    .add(1, "day")
                    .format("YYYY-MM-DD") + " 00:00:00"
                );
                const start = new Date(
                    dayjs()
                    .subtract(90, "day")
                    .format("YYYY-MM-DD") + " 00:00:00"
                );
                picker.$emit("pick", [start, end]);
                }
            }
            ]
        },
            }
        },
        methods:{
          intentionIdFn(row){
            if(row.intentionId==1){
              return '意向较强'
            }else if(row.intentionId==2){
              return '意向简单'
            }else if(row.intentionId==3){
              return '了解阶段'
            }else if(row.intentionId==4){
              return '无意向'
            }else {
              return '暂无数据'
            }
          },
          creatTimeformatter(row){
            if(row.xmtFollowupRecordEntityList.length>0){
              return row.xmtFollowupRecordEntityList[0].creatTime
            }else{
              return '暂未跟进'
            }
          },
          daalDetail(row){
              this.$router.push({
              name: "sectionManageStudentInfo",
              params: {
                id: row.id,
                contract: -1,
                show:false,
                type:2
              }
            });
          },
            //重新分配
            dealApportion(row){
                this.apportionVisible=true;
                this.saleData.saleId='';
                this.curOptStudent = {
                    no: row.no,
                    id: row.id,
                    name: row.name
                };
                this.getSaleList();
            },
            getAssignedCustomersFn(){
                getAssignedCustomers(this.listParams).then(res => {
                    this.tableList=res.rows;
                    this.tableTotal=res.total;
                });
            },
            handleSelectionChange(){},
            allocatedItemClick(){},
            handleSizeChange(pageSize) {
                this.listParams.pageSize = pageSize;
                this.getAssignedCustomersFn();
            },
            handleCurrentChange(currentPage) {
                this.listParams.pageNumber = currentPage;
                this.getAssignedCustomersFn();
            },
            saleChange(){
                for (let i = 0, len = this.saleList.length; i < len; i++) {
                    if (this.saleList[i].userId == this.saleData.saleId) {
                    this.saleData.saleName = this.saleList[i].username;
                    }
                }
            },
             saleChangeSearch(){
                for (let i = 0, len = this.saleListTitle.length; i < len; i++) {
                    if (this.saleListTitle[i].userId == this.listParams.saleId) {
                    this.listParams.saleName = this.saleListTitle[i].username;
                    }
                    if(this.saleListTitle[i].userId==''){
                      this.listParams.saleName=''
                    }
                }
            },
            intentionTimeChange() {
              if (!this.intentionTimeValue) {
                this.listParams.intentionBeginTime = null;
                this.listParams. intentionEndTime = null;
                return;
              }
              this.listParams.intentionBeginTime = this.intentionTimeValue[0];
              this.listParams. intentionEndTime = this.intentionTimeValue[1];
            },
            datePickerChange(){
              if (!this.timePickerValue) {
                this.listParams.searchBeginDate = null;
                this.listParams.searchEndDate = null;
                return;
              }
              this.listParams.searchBeginDate = this.timePickerValue[0];
              this.listParams.searchEndDate = this.timePickerValue[1];
            },
            confirmApportion(){
                if (!this.saleData.saleId) {
                    this.msgWarn("请选择需要分配的销售！");
                    return;
                }
                this.apportionVisible = false;
                let arr = [];
                this.curOptStudent.saleId = this.saleData.saleId;
                this.curOptStudent.saleName = this.saleData.saleName;
                this.curOptStudent.allocationRecordType = 2;
                this.curOptStudent.isCheckFollowup = this.saleData.record ? 1 : 0;
                arr.push(this.curOptStudent);
                reapportionList(arr).then(res => {
                    if (res.code == 0) {
                    this.msgSuccess("操作成功！");
                    this.getAssignedCustomersFn();
                    }
                });
            },
            dealSex(row) {
                if (row.sex == 1) {
                    return "男";
                } else if (row.sex == 2) {
                    return "女";
                } else {
                    return "";
                }
            },
            dealIfEffective(row){
              if (row.contract != 6&&row.contract!=null&&row.contract!='') {
                    return "有效";
                } else if (row.contract == 6) {
                    return "无效";
                } else {
                    return "";
                }
            },
            dealSourceListType(row) {
              console.log(this.sourceTypeList)
            let type;
            for (let i = 0, len = this.sourceTypeList.length; i < len; i++) {
                if(row.sourceType==""){
                  type = '暂无数据'
                  break;
                }
                if (row.sourceType == this.sourceTypeList[i].value) {
                  type = this.sourceTypeList[i].label;
                  break;
                }else{
                  type='暂无数据'
                }
            }
            return type;
            },
            getSectionList() {
                getSelectList({
                    parentCode: sectionCode
                }).then(res => {
                    if (res.code == 0) {
                    this.sectionList = res.rows;
                    this.sectionList.unshift({
                        macroId: '',
                        name: "全部"
                    });
                    }
                });
            },
            getSaleList() {
                listNoPage(this.saleParams).then(res => {
                    this.saleListTitle=res;
                     this.saleListTitle.unshift({
                        userId: '',
                        name:'全部',
                        username: "全部"
                    });
                      
                });
                 listNoPage(this.saleParams).then(res => {
                   this.saleList = res;
                });
            },
        },
        mounted(){
            this.getSectionList();
            this.getAssignedCustomersFn();
            this.getSaleList();
        }
    }
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content{
    .detail_type{
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail{
      padding: 6px 0;
    }
    .certificate{
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
// .main-content {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   .btn-area {
//     height: 50px;
//     display: flex;
//     align-items: center;
//     .right {
//       flex: 1;  
//       display: flex;
//       justify-content: flex-end;
//     }
//   }
//   .select-condition {
//     border-bottom: 1px solid #f1f1f1;
//     .left {
//       flex: 1;
//       display: flex;
//       .select-condition-item {
//         display: flex;
//         align-items: center;
//         margin-right: 20px;
//         .item-lable {
//           word-break: keep-all;
//         }
//       }
//     }
//   }
//   .table-area {
//     flex: 1;
//     overflow: hidden;
//   }
//   .page-area {
//     height: 50px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   .dialog-content {
//     min-height: 260px;
//     max-height: 60vh;
//     overflow-y: auto;
//     padding-left: 10px;
//     padding-right: 10px;
//     .item_select {
//       display: flex;
//       align-items: center;
//       span {
//         width: 120px;
//       }
//     }
//     .record {
//       margin-top: 230px;
//     }
//     .info-title {
//       height: 40px;
//       line-height: 40px;
//       font-weight: 800;
//       font-size: 16px;
//       text-indent: 12px;
//       text-align: left;
//     }
//   }
//   .score-input {
//     border: none;
//     outline: none;
//     border-bottom: 1px solid #000000;
//     text-align: center;
//     width: 80px;
//   }
//   .subject-item {
//     height: 40px;
//     line-height: 40px;
//   }
//   .self-radio-group {
//     margin-left: 20px;
//     .self-radio-group-item {
//       margin-bottom: 8px;
//     }
//   }
//   .class_title {
//     height: 30px;
//     line-height: 30px;
//     text-indent: 12px;
//   }
//   .table_area {
//     margin-left: 20px;
//     padding: 10px 4px;
//     .class_tip {
//       height: 40px;
//       line-height: 40px;
//       text-indent: 4px;
//       span {
//         margin-right: 20px;
//       }
//     }
//   }
//   .self_table {
//     width: 100%;
//     border: 1px solid #ebeef5;
//     border-collapse: collapse;
//     border-spacing: 0px;
//     tr {
//       border: 1px solid #ebeef5;
//       td {
//         border: 1px solid #ebeef5;
//         text-align: center;
//         vertical-align: middle;
//         height: 40px;
//       }
//     }
//   }
//   .required_subject {
//     height: 30px;
//     line-height: 30px;
//     margin-bottom: 10px;
//   }
//   .select_subject {
//     .select_subject_item {
//       .item_subject_title {
//         font-weight: 900;
//         padding-left: 32px;
//         height: 40px;
//         line-height: 40px;
//         font-size: 20px;
//       }
//       .item_score {
//         display: flex;
//         /deep/ .el-input {
//           width: 40%;
//         }
//         > span {
//           width: 20px;
//           text-align: center;
//         }
//       }
//     }
//   }
//   .open_tip {
//     height: 40px;
//     line-height: 40px;
//     text-align: center;
//   }
 
// }
</style>
