<template>
  <div class="main-content sys-role-manage sys-menu-manage">
    <div class="btn-area">
      <div class="left"></div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="macroList"
        style="width: 100%;margin-bottom: 20px;"
        row-key="macroId"
        border
        height="100%"
        tooltip-effect="dark"
        ref="macroListTable"
        @row-click="macroItemClick"
      >
        <el-table-column width="66" align="center" label="单选" type>
          <template slot-scope="scope">
            <el-radio v-model="curMacroId" :label="scope.row.macroId">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="macroId" label="编号" width="100" align="center" type></el-table-column>
        <el-table-column prop="name" label="参数名" align="left" header-align="center" width="250"></el-table-column>
        <el-table-column prop="value" label="参数值" align="center"></el-table-column>
        <el-table-column prop="typeName" label="所属类别" align="center"></el-table-column>
        <el-table-column label="类型" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-if="scope.row.type == 0">目录</el-button>
            <el-button type="info" size="mini" v-else-if="scope.row.type == 1">参数</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="orderNum" label="排序" align="center"></el-table-column>
        <el-table-column label="可用" align="center">
          <template slot-scope="scope">
            <el-switch :value="scope.row.status == 1" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center"></el-table-column>
      </el-table>
    </div>
    <el-dialog :title="saveDialog.title" :close-on-click-modal="false" :visible.sync="saveDialogVisible" width="500px" center>
      <el-dialog width="460px" title="选择菜单" :visible.sync="showMacroSelect" append-to-body center>
        <div class="org-tree">
          <el-tree
            :data="treeData"
            :props="defaultProps"
            :default-expand-all="treeOptions.defaultExpandAll"
            @node-click="handleNodeClick"
            :expand-on-click-node="treeOptions.expandOnClickNode"
            :check-on-click-node="treeOptions.checkOnClickNode"
            :highlight-current="treeOptions.highlightCurrent"
            :current-node-key="treeOptions.currentNodeKey"
            append-to-body="true"
          ></el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showMacroSelect = false" size="small">取 消</el-button>
          <el-button type="primary" @click="confirmMacroSelect" size="small">确 定</el-button>
        </span>
      </el-dialog>
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="类型" prop="type">
            <el-radio-group v-model="saveRuleForm.type">
              <el-radio label="0">目录</el-radio>
              <el-radio label="1">参数</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input v-model="saveRuleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="英文代码" prop="value">
            <el-input v-model="saveRuleForm.value"></el-input>
          </el-form-item>
          <el-form-item label="参数类型" prop="typeName" @click.native="macroSelectClick">
            <el-input v-model="saveRuleForm.typeName"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="orderNum">
            <el-input v-model="saveRuleForm.orderNum"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark" v-show="saveRuleForm.type == 1">
            <el-input v-model="saveRuleForm.remark"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status" v-show="saveRuleForm.type == 1">
            <el-radio-group v-model="saveRuleForm.status">
              <el-radio label="0">隐藏</el-radio>
              <el-radio label="1">显示</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSave('saveRuleForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemMacroList,
  systemMacroSelect,
  systemMacroSave,
  systemMacroUpdate,
  systemMacroInfo,
  systemMacroRemove,
} from "@/api/system/systemManage";
import { formatTree } from "@/utils/common";
export default {
  inject: ['reload'],
   watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
  data() {
    return {
      macroList: [],
      saveDialogVisible: false,
      saveRuleForm: {
        name: "",
        status: "1",
        value: "",
        remark: "",
        type: "1",
        typeId: "0",
        typeName: "",
        orderNum: "0",
      },
      saveRules: {
        type: [{ required: true, message: "类型不能为空", trigger: "blur" }],
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        parentName: [
          { required: true, message: "参数类型不能为空", trigger: "blur" },
        ],
      },
      selectMacroList: [],
      saveDialog: {
        title: "新增字典",
        type: "add",
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeData: [],
      treeOptions: {
        defaultExpandAll: true,
        expandOnClickNode: false,
        checkOnClickNode: true,
        highlightCurrent: true,
        currentNodeKey: "",
        selectNode: {},
      },
      curMacroId: "",
      showMacroSelect: false,
    };
  },
  methods: {
    handleNodeClick(selectNode) {
      this.treeOptions.selectNode = selectNode;
    },
    getSystemMacroList() {
      systemMacroList().then((res) => {
        this.macroList = formatTree(res, {
          id: "name",
          parentId: "typeName",
          originId: null,
        });
        console.log(this.macroList);
      });
    },
    confirmMacroSelect() {
      this.saveRuleForm.typeId = this.treeOptions.selectNode.macroId;
      this.saveRuleForm.typeName = this.treeOptions.selectNode.name;
      this.showMacroSelect = false;
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            name: this.saveRuleForm.name,
            status: this.saveRuleForm.type == 0 ? 1 : this.saveRuleForm.status,
            value: this.saveRuleForm.value,
            type: this.saveRuleForm.type,
            typeId: this.saveRuleForm.typeId,
            typeName: this.saveRuleForm.typeName,
            orderNum: this.saveRuleForm.orderNum,
            remark: this.saveRuleForm.type == 1 ? this.saveRuleForm.remark : '',
          };
          if (this.saveDialog.type == "add") {
            systemMacroSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemMacroList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.macroId = this.curMacroId;
            systemMacroUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemMacroList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if (!this.curMacroId) {
        this.msgWarn("请选择单个菜单进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑字典";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        name: "",
        status: "1",
        value: "",
        remark: "",
        type: "1",
        typeId: "0",
        typeName: "",
        orderNum: "0",
      };
      this.saveDialogVisible = true;
      systemMacroInfo(this.curMacroId).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            name: res.rows.name,
            status: String(res.rows.status),
            value: res.rows.value,
            remark: res.rows.remark,
            type: String(res.rows.type),
            typeId: res.rows.typeId,
            typeName: res.rows.typeName ? res.rows.typeName : "一级目录",
            orderNum: res.rows.orderNum,
          };
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增字典";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        name: "",
        status: "1",
        value: "",
        remark: "",
        type: "1",
        typeId: "0",
        typeName: "",
        orderNum: "0",
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload()
    },
    delClick() {
      if (!this.curMacroId) {
        this.msgWarn("请选择单个字典进行操作！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          systemMacroRemove([this.curMacroId]).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSystemMacroList();
            }
          });
        })
        .catch(() => {});
    },
    macroItemClick(row) {
      this.$refs.macroListTable.toggleRowSelection(row);
    },
    macroSelectClick() {
      this.treeOptions.selectNode = {};
      systemMacroSelect().then((res) => {
        this.treeData = formatTree(res, {
          id: "macroId",
          parentId: "typeId",
          originId: -1,
        });
        console.log(this.treeData);
      });
      this.showMacroSelect = true;
    },
  },
  created() {},
  mounted() {
    this.getSystemMacroList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 300px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
}
</style>