<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area">
      <div class="left">
        <el-row :gutter="20">
          <el-col :span="9">
            <el-input v-model="listParams.keywords" placeholder="请输入查询关键字" size="small"></el-input>
          </el-col>
          <el-col :span="9">
            <el-input v-model="listParams.linkAddress" placeholder="请输入查询链接地址" size="small"></el-input>
          </el-col>
          <el-col :span="6">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="getSystemAppLinkList"
            >搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="linkList"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="linkListTable"
        @selection-change="handleSelectionChange"
        @row-click="linkItemClick"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="keywords" label="关键字" align="center"></el-table-column>
        <el-table-column prop="linkAddress" label="链接地址" align="center"></el-table-column>
        <el-table-column prop="author" label="作者" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="discribe" label="描述" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      width="460px"
      center
      :close-on-click-modal="false"
      custom-class="article-dialog"
    >
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="关键字" prop="keywords">
            <el-input v-model="saveRuleForm.keywords"></el-input>
          </el-form-item>
          <el-form-item label="链接地址" prop="linkAddress">
            <el-input v-model="saveRuleForm.linkAddress"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="discribe">
            <el-input v-model="saveRuleForm.discribe"></el-input>
          </el-form-item>
          <el-form-item label="作者" prop="author">
            <el-input v-model="saveRuleForm.author"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSave('saveRuleForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemAppLinkList,
  systemAppLinkRemove,
  systemAppLinkInfo,
  systemAppLinkSave,
  systemAppLinkUpdate,
} from "@/api/system/appManage"
export default {
  inject: ["reload"],
  watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
  data() {
    return {
      linkList: [],
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        keywords: "",
        linkAddress: '',
      },
      total: 0,
      saveDialogVisible: false,
      saveRuleForm: {
        author: '',
        discribe: "",
        keywords: "",
        linkAddress: "",
      },
      saveRules: {
        author: [
          { required: true, message: "作者不能为空", trigger: "blur" },
        ],
        discribe: [
          { required: true, message: "描述不能为空", trigger: "blur" },
        ],
        keywords: [
          { required: true, message: "关键字不能为空", trigger: "blur" },
        ],
        linkAddress: [
          { required: true, message: "链接地址不能为空", trigger: "blur" },
        ],
      },
      selectLinkList: [],
      saveDialog: {
        title: "新增内链",
        type: "add",
      },
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.selectLinkList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectLinkList.push(val[i].id);
        }
      }
    },
    getSystemAppLinkList() {
      systemAppLinkList(this.listParams).then((res) => {
        console.log(res);
        this.linkList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getSystemAppLinkList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getSystemAppLinkList();
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            author: this.saveRuleForm.author,
            discribe: this.saveRuleForm.discribe,
            keywords: this.saveRuleForm.keywords,
            linkAddress: this.saveRuleForm.linkAddress,
          };
          if (this.saveDialog.type == "add") {
            systemAppLinkSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemAppLinkList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.id = this.selectLinkList[0];
            systemAppLinkUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemAppLinkList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if (this.selectLinkList.length != 1) {
        this.msgWarn("请选择单个内链进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑内链";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        author: '',
        discribe: "",
        keywords: "",
        linkAddress: "",
      }
      this.saveDialogVisible = true;
      systemAppLinkInfo(this.selectLinkList[0]).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            author: res.rows.author,
            discribe: res.rows.discribe,
            keywords: res.rows.keywords,
            linkAddress: res.rows.linkAddress,
          };
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增内链";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        author: '',
        discribe: "",
        keywords: "",
        linkAddress: "",
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload();
    },
    delClick() {
      if (this.selectLinkList.length == 0) {
        this.msgWarn("请至少选择一个内链！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          systemAppLinkRemove(this.selectLinkList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSystemAppLinkList();
            }
          });
        })
        .catch(() => {});
    },
    linkItemClick(row) {
      this.$refs.linkListTable.toggleRowSelection(row);
    },
  }, 
  created() {},
  mounted() {
    this.getSystemAppLinkList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 480px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
    .item-img {
      width: 200px;
      height: 100px;
    }
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>