<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">机构名称：</span>
          <el-select
            v-model="listParams.organId"
            placeholder="请选择机构名称"
            size="small"
            @change="handleOrganChange"
          >
            <el-option
              v-for="item in organList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="organChargeList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        ref="organListTable"
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column label="机构名称" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click.stop="handleSeeOrgDetaliList(scope.row)"
              >{{ scope.row.orgName }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="accountBalance"
          label="余额(元)"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="expenditureBalance"
          label="支出金额(元)"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="expirationTime"
          label="到期时间"
          align="center"
        ></el-table-column>
        <el-table-column label="机构标签" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.organLabel == 1">基础版</span>
            <span v-else-if="scope.row.organLabel == 2">标准版</span>
            <span v-else-if="scope.row.organLabel == 3">定制版</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.stop="handleAddFee(scope.row)"
              >流量费充值</el-button
            >
            <el-button
              class="mL20"
              type="text"
              size="small"
              @click.stop="handleOrgChargeInit(scope.row)"
              >余额清零</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="list_tip">
      <span>总计</span>
      <span class="mL20">余额：{{ allAccountBalance }}元</span>
      <span class="mL20">支出金额：{{ expenditureBalance }}元</span>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title="充值"
      :visible.sync="saveDialogVisible"
      width="460px"
      center
    >
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="充值金额" prop="rechargePrice">
            <el-input v-model.number="saveRuleForm.rechargePrice"></el-input>
          </el-form-item>
          <el-form-item label="充值类型" prop="rechargeType">
            <el-radio-group v-model="saveRuleForm.rechargeType">
              <el-radio :label="1">充值</el-radio>
              <el-radio :label="2">平台赠送</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="charge_tip">注意：请仔细核对充值金额，确保正确</div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          class="mL20"
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  organRechargeInit,
  organRechargeList,
  organRechargeSave,
  getOrganList,
} from "@/api/system/systemManage";
export default {
  data() {
    return {
      allAccountBalance: "", // 余额
      expenditureBalance: "", // 支出金额
      organList: [
        {
          orgName: "全部",
          orgId: 0,
        },
      ],
      organChargeList: [],
      total: 0,
      listParams: {
        pageSize: 10,
        pageNumber: 1,
        organId: 0,
      },
      saveDialogVisible: false,
      saveRuleForm: {
        rechargePrice: "",
        orgId: "",
        rechargeType: 1,
      },
      saveRules: {
        rechargePrice: [
          {
            required: true,
            message: "充值类型不能为空或0！",
            trigger: "change",
            validator(rule, value, callback) {
              if (value === 0) {
                return callback(new Error("充值金额不能为0!"));
              } else if (!value) {
                return callback(new Error("充值金额不能为空!"));
              }
              return callback();
            },
          },
        ],
        rechargeType: [
          { required: true, message: "充值类型不能为空", trigger: "change" },
        ],
      },
    };
  },
  components: {},
  computed: {},
  mounted() {
    this.getOrganList();
    this.getOrganRechargeList();
  },
  methods: {
    /* 当前页码改变 */
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getOrganRechargeList();
    },
    /* 单页数量改变 */
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getOrganRechargeList();
    },
    /* 获取机构列表 */
    getOrganList() {
      getOrganList().then((res) => {
        console.log(res);
        res.rows.unshift({
          orgName: "全部",
          orgId: 0,
        });
        this.organList = res.rows;
      });
    },
    /* 获取平台充值列表 */
    getOrganRechargeList() {
      let organId = this.listParams.organId;
      if (organId == 0) {
        organId = null;
      }
      organRechargeList({ ...this.listParams, organId }).then((res) => {
        console.log(res);
        const {
          total,
          rows,
          countMap: { allAccountBalance, expenditureBalance },
        } = res.rows;
        this.allAccountBalance = allAccountBalance;
        this.expenditureBalance = expenditureBalance;
        this.total = total;
        this.organChargeList = rows;
      });
    },
    /* 清除余额 */
    handleOrgChargeInit(item) {
      this.$confirm("确定要清除余额吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          organRechargeInit({ orgId: item.orgId }).then((res) => {
            console.log(res);
            if (res.flag == "error") {
              this.$message.warning(res.msg);
            } else {
              this.$message.success("操作成功！");
              this.getOrganRechargeList();
            }
          });
        })
        .catch(() => {});
    },
    /* 流量费充值 */
    handleAddFee(item) {
      this.saveDialogVisible = true;
      this.saveRuleForm.orgId = item.orgId
      if(this.$refs['saveRuleForm']){
        this.$refs['saveRuleForm'].resetFields();
      }
    },
    /* 处理机构选择 */
    handleOrganChange() {
      this.listParams.pageNumber = 1;
      this.getOrganRechargeList();
    },
    /* 确定充值 */
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          organRechargeSave(this.saveRuleForm).then((res) => {
            console.log(res);
            if (res.flag == "error") {
              this.$message.warning(res.msg);
            } else {
              this.$message.success("操作成功！");
              this.saveDialogVisible = false
              this.getOrganRechargeList();
            }
          });
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    /* 处理查看机构明细 */
    handleSeeOrgDetaliList(item) {
      this.$router.push({
        path: '/system/systemManage/orgChargeDetail',
        query: {
          orgId: item.orgId
        }
      })
    },
  },
};
</script>
<style lang='scss' scoped>
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .save-dialog-content {
    height: 500px;
    overflow-y: auto;
    padding-right: 20px;
  }

  .charge_tip {
    text-indent: 26px;
  }

  .list_tip {
    padding-right: 10px;
    height: 40px;
    line-height: 40px;
    text-align: right;
  }
}
</style>