<template>
  <div class="main-content sys-role-manage sys-menu-manage">
    <div class="btn-area">
      <div class="left"></div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="menuList"
        style="width: 100%;margin-bottom: 20px;"
        row-key="menuId"
        border
        height="100%"
        tooltip-effect="dark"
        ref="menuListTable"
        @row-click="menuItemClick"
      >
        <el-table-column width="66" align="center" label="单选" type>
          <template slot-scope="scope">
            <el-radio v-model="curMenuId" :label="scope.row.menuId">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="menuId" label="编号" width="100" align="center" type></el-table-column>
        <el-table-column prop="name" label="名称" align="left" header-align="center" width="250"></el-table-column>
        <el-table-column prop="parentName" label="上级菜单" align="center"></el-table-column>
        <el-table-column label="图标" align="center">
          <template slot-scope="scope">
            <svg class="icon" aria-hidden="true" style="font-size: 26px">
              <use :xlink:href="`#${scope.row.icon}`" />
            </svg>
          </template>
        </el-table-column>
        <el-table-column label="类型" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-if="scope.row.type == 0">目录</el-button>
            <el-button type="danger" size="mini" v-else-if="scope.row.type == 1">菜单</el-button>
            <el-button type="info" size="mini" v-else-if="scope.row.type == 2">按钮</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="orderNum" label="排序" align="center"></el-table-column>
        <el-table-column prop="url" label="菜单url" align="center"></el-table-column>
        <el-table-column prop="perms" label="授权标识" align="center"></el-table-column>
      </el-table>
    </div>
    <el-dialog :title="saveDialog.title" :close-on-click-modal="false" :visible.sync="saveDialogVisible" width="500px" center>
      <el-dialog width="460px" title="选择菜单" :visible.sync="showMenuSelect" append-to-body center>
        <div class="org-tree">
          <el-tree
            :data="treeData"
            :props="defaultProps"
            :default-expand-all="treeOptions.defaultExpandAll"
            @node-click="handleNodeClick"
            :expand-on-click-node="treeOptions.expandOnClickNode"
            :check-on-click-node="treeOptions.checkOnClickNode"
            :highlight-current="treeOptions.highlightCurrent"
            :current-node-key="treeOptions.currentNodeKey"
            append-to-body="true"
          ></el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showMenuSelect = false" size="small">取 消</el-button>
          <el-button type="primary" @click="confirmMenuSelect" size="small">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog width="560px" title="选择图标" :visible.sync="showIconSelect" append-to-body center :close-on-click-modal="false">
        <icon-select @iconChange="iconChange"></icon-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showIconSelect = false" size="small">取 消</el-button>
          <el-button type="primary" @click="showIconSelect = false" size="small">确 定</el-button>
        </span>
      </el-dialog>
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="类型" prop="type">
            <el-radio-group v-model="saveRuleForm.type">
              <el-radio label="0">目录</el-radio>
              <el-radio label="1">菜单</el-radio>
              <el-radio label="2">按钮</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input v-model="saveRuleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="上级菜单" prop="parentName" @click.native="menuSelectClick">
            <el-input v-model="saveRuleForm.parentName"></el-input>
          </el-form-item>
          <el-form-item label="菜单URL" prop="url" v-show="saveRuleForm.type == 1">
            <el-input v-model="saveRuleForm.url"></el-input>
          </el-form-item>
          <el-form-item label="授权标识" prop="perms" v-show="saveRuleForm.type != 0">
            <el-input v-model="saveRuleForm.perms"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="orderNum" v-show="saveRuleForm.type != 2">
            <el-input v-model="saveRuleForm.orderNum"></el-input>
          </el-form-item>
          <el-form-item
            label="图标"
            prop="icon"
            v-show="saveRuleForm.type != 2"
            @click.native="showIconSelect = true"
          >
            <el-input v-model="saveRuleForm.icon"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSave('saveRuleForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemMenuList,
  systemMenuSelect,
  systemMenuSave,
  systemMenuUpdate,
  systemMenuRemove,
  systemMenuInfo,
} from "@/api/system/systemManage";
import { formatTree } from "@/utils/common";
export default {
  inject: ['reload'],
  data() {
    return {
      menuList: [],
      saveDialogVisible: false,
      saveRuleForm: {
        icon: "",
        name: "",
        orderNum: 0,
        parentId: "",
        parentName: "",
        perms: "",
        type: "1",
        url: "",
      },
      saveRules: {
        type: [{ required: true, message: "类型不能为空", trigger: "blur" }],
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        parentId: [
          { required: true, message: "上级菜单不能为空", trigger: "blur" },
        ],
      },
      selectMenuList: [],
      saveDialog: {
        title: "新增菜单",
        type: "add",
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeData: [],
      treeOptions: {
        defaultExpandAll: true,
        expandOnClickNode: false,
        checkOnClickNode: true,
        highlightCurrent: true,
        currentNodeKey: "",
        selectNode: {},
      },
      curMenuId: "",
      showIconSelect: false,
      showMenuSelect: false,
    };
  },
  methods: {
    handleNodeClick(selectNode) {
      this.treeOptions.selectNode = selectNode;
    },
    getSystemMenuList() {
      systemMenuList().then((res) => {
        this.menuList = formatTree(res, {
          id: "menuId",
          parentId: "parentId",
        });
        console.log(res,'menuList....')
      });
    },
    confirmMenuSelect() {
      this.saveRuleForm.parentId = this.treeOptions.selectNode.menuId;
      this.saveRuleForm.parentName = this.treeOptions.selectNode.name;
      this.showMenuSelect = false;
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            icon: this.saveRuleForm.icon,
            name: this.saveRuleForm.name,
            orderNum: this.saveRuleForm.orderNum,
            parentId: this.saveRuleForm.parentId,
            parentName: this.saveRuleForm.parentName,
            perms: this.saveRuleForm.perms,
            type: this.saveRuleForm.type,
            url: this.saveRuleForm.url,
          };
          if (this.saveDialog.type == "add") {
            systemMenuSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemMenuList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.menuId = this.curMenuId;
            systemMenuUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemMenuList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if (!this.curMenuId) {
        this.msgWarn("请选择单个菜单进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑菜单";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        icon: "",
        name: "",
        orderNum: 0,
        parentId: "",
        parentName: "",
        perms: "",
        type: "1",
        url: "",
      };
      this.saveDialogVisible = true;
      systemMenuInfo(this.curMenuId).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            icon: res.rows.icon,
            name: res.rows.name,
            orderNum: res.rows.orderNum,
            parentId: res.rows.parentId,
            parentName: res.rows.parentName ? res.rows.parentName : "一级菜单",
            perms: res.rows.perms,
            type: String(res.rows.type),
            url: res.rows.url,
          };
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增菜单";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        icon: "",
        name: "",
        orderNum: 0,
        parentId: "",
        parentName: "",
        perms: "",
        type: "1",
        url: "",
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload();
    },
    delClick() {
      if (!this.curMenuId) {
        this.msgWarn("请选择单个菜单进行操作！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          systemMenuRemove([this.curMenuId]).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSystemMenuList();
            }
          });
        })
        .catch(() => {});
    },
    menuItemClick(row) {
      this.$refs.menuListTable.toggleRowSelection(row);
    },
    iconChange(icon) {
      this.saveRuleForm.icon = icon;
      this.showIconSelect = false;
    },
    menuSelectClick() {
      this.treeOptions.selectNode = {};
      systemMenuSelect().then((res) => {
        this.treeData = formatTree(res.rows, {
          id: "menuId",
          parentId: "parentId",
          originId: -1,
        });
        console.log(this.treeData,'treeData...');
      });
      this.showMenuSelect = true;
    },
  },
  created() {},
  mounted() {
    this.getSystemMenuList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 300px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
}
</style>