<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area">
      <div class="left">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input
              v-model="listParams.roleName"
              placeholder="请输入搜索关键字"
              size="small"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="getSystemRoleList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
          <el-button type="default" size="small" @click="authClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-quanxian2" />
            </svg>
            <span>操作权限</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="roleList"
        style="width: 100%; margin-bottom: 20px"
        row-key="roleId"
        border
        height="100%"
        tooltip-effect="dark"
        ref="roleListTable"
        @selection-change="handleSelectionChange"
        @row-click="roleItemClick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="roleId"
          label="编号"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="roleName"
          label="角色名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="roleSign"
          label="角色标识"
          align="center"
        ></el-table-column>
        <el-table-column label="角色类型" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-if="scope.row.isAdmin == 0"
              >普通员工</el-button
            >
            <el-button
              type="danger"
              size="mini"
              v-else-if="scope.row.isAdmin == 1"
              >管理员</el-button
            >
            <el-button
              type="info"
              size="mini"
              v-else-if="scope.row.isAdmin == 2"
              >领导</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="roleSign"
          label="角色标识"
          align="center"
        ></el-table-column>
        <el-table-column label="机构标签" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.organLabel == 1">基础版</span>
            <span v-else-if="scope.row.organLabel == 2">专业版</span>
            <span v-else-if="scope.row.organLabel == 3">定制版</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="gmtCreate"
          label="创建时间"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :close-on-click-modal="false"
      :visible.sync="saveDialogVisible"
      width="500px"
      center
    >
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="角色名称" prop="roleName">
            <el-input v-model="saveRuleForm.roleName"></el-input>
          </el-form-item>
          <el-form-item label="角色标识" prop="roleSign">
            <el-input v-model="saveRuleForm.roleSign"></el-input>
          </el-form-item>
          <el-form-item label="角色类型" prop="isAdmin">
            <el-radio-group v-model="saveRuleForm.isAdmin">
              <el-radio label="0">普通员工</el-radio>
              <el-radio label="1">管理员</el-radio>
              <el-radio label="2">部门领导</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="机构标签" prop="organLabel">
            <el-radio-group v-model="saveRuleForm.organLabel">
              <el-radio :label="1">基础版</el-radio>
              <el-radio :label="2">专业版</el-radio>
              <el-radio :label="3">定制版</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="saveRuleForm.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('saveRuleForm')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      width="460px"
      title="权限操作"
      :visible.sync="authOptDialogVisible"
      center
      :close-on-click-modal="false"
    >
      <div class="org-tree">
        <el-tree
          ref="optTree"
          show-checkbox
          node-key="menuId"
          :data="treeData"
          :props="defaultProps"
          :default-expand-all="treeOptions.defaultExpandAll"
          :expand-on-click-node="treeOptions.expandOnClickNode"
          :check-on-click-node="treeOptions.checkOnClickNode"
          :highlight-current="treeOptions.highlightCurrent"
          :default-checked-keys="treeOptions.defaultCheckKeys"
        ></el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="authOptDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmAuthOpt" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemRoleList,
  systemRoleAdd,
  systemRoleUpdate,
  systemRoleAuthOpt,
  systemRoleRemove,
  systemRoleInfo,
  systemMenuList,
} from "@/api/system/systemManage";
import { formatTree } from "@/utils/common";
export default {
  inject: ["reload"],
  data() {
    return {
      roleList: [],
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        roleName: "",
      },
      total: 0,
      saveDialogVisible: false,
      saveRuleForm: {
        isAdmin: "",
        orgId: 0,
        orgName: null,
        remark: "",
        roleName: "",
        roleSign: "",
        menuIdList: [],
        organLabel: 1, // 1 基础版  2 专业版 3 定制版
      },
      saveRules: {
        isAdmin: [
          { required: true, message: "角色类型不能为空", trigger: "blur" },
        ],
        roleName: [
          { required: true, message: "角色名称不能为空", trigger: "blur" },
        ],
        roleSign: [
          { required: true, message: "角色类型不能为空", trigger: "blur" },
        ],
        organLabel: [
          { required: true, message: "机构标签不能为空", trigger: "blur" },
        ],
      },
      selectRoleList: [],
      saveDialog: {
        title: "新增角色",
        type: "add",
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeData: [],
      treeOptions: {
        defaultExpandAll: true,
        expandOnClickNode: false,
        checkOnClickNode: true,
        highlightCurrent: true,
        defaultCheckKeys: [],
      },
      authOptDialogVisible: false,
    };
  },
  watch: {
    saveDialogVisible: function (val, oldVla) {
      if (this.$refs["saveRuleForm"] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.selectRoleList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectRoleList.push(val[i].roleId);
        }
      }
    },
    getSystemRoleList() {
      systemRoleList(this.listParams).then((res) => {
        console.log(res);
        this.roleList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getSystemRoleList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getSystemRoleList();
    },
    confirmAuthOpt() {
      let arr = this.$refs.optTree.getCheckedNodes(false, true),
        authOpt = [];
      for (let i = 0, len = arr.length; i < len; i++) {
        authOpt.push(arr[i].menuId);
      }
      this.saveRuleForm.menuIdList = authOpt;
      this.saveRuleForm.roleId = this.selectRoleList[0];
      systemRoleAuthOpt(this.saveRuleForm).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.authOptDialogVisible = false;
        }
      });
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            isAdmin: this.saveRuleForm.isAdmin,
            orgId: this.saveRuleForm.orgId,
            orgName: this.saveRuleForm.orgName,
            remark: this.saveRuleForm.remark,
            roleName: this.saveRuleForm.roleName,
            roleSign: this.saveRuleForm.roleSign,
            organLabel: this.saveRuleForm.organLabel
          };
          if (this.saveDialog.type == "add") {
            systemRoleAdd(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemRoleList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.roleId = this.selectRoleList[0];
            systemRoleUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemRoleList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if (this.selectRoleList.length != 1) {
        this.msgWarn("请选择单个角色进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑角色";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        isAdmin: "",
        orgId: 0,
        orgName: null,
        remark: "",
        roleName: "",
        roleSign: "",
      };
      this.saveDialogVisible = true;
      systemRoleInfo(this.selectRoleList[0]).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            isAdmin: String(res.rows.isAdmin),
            remark: res.rows.remark,
            roleName: res.rows.roleName,
            roleSign: res.rows.roleSign,
            orgId: 0,
            orgName: null,
            menuIdList: res.rows.menuIdList,
            organLabel: res.rows.organLabel,
          };
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增角色";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        isAdmin: "",
        orgId: 0,
        orgName: null,
        remark: "",
        roleName: "",
        roleSign: "",
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload();
    },
    delClick() {
      if (this.selectRoleList.length == 0) {
        this.msgWarn("请至少选择一个用户！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          systemRoleRemove(this.selectRoleList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSystemRoleList();
            }
          });
        })
        .catch(() => {});
    },
    async authClick() {
      if (this.selectRoleList.length != 1) {
        this.msgWarn("请选择一个用户！");
        return;
      }
      this.authOptDialogVisible = true;
      await systemRoleInfo(this.selectRoleList[0]).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            isAdmin: String(res.rows.isAdmin),
            remark: res.rows.remark,
            roleName: res.rows.roleName,
            roleSign: res.rows.roleSign,
            orgId: 0,
            orgName: null,
            menuIdList: res.rows.menuIdList,
          };
        }
      });
      await systemMenuList().then((res) => {
        let arr = [],
          menuIdList = this.saveRuleForm.menuIdList;
        this.treeData = formatTree(res, {
          id: "menuId",
          parentId: "parentId",
        });
        for (let i = 0, len = menuIdList.length; i < len; i++) {
          function dealMenuIdList(val, list) {
            for (let j = 0, lenJ = list.length; j < lenJ; j++) {
              if (list[j].children.length > 0) {
                dealMenuIdList(val, list[j].children);
              } else if (val == list[j].menuId) {
                arr.push(val);
                break;
              }
            }
          }
          dealMenuIdList(menuIdList[i], this.treeData);
        }
        this.treeOptions.defaultCheckKeys = arr;
      });
    },
    roleItemClick(row) {
      this.$refs.roleListTable.toggleRowSelection(row);
    },
  },
  created() {},
  mounted() {
    this.getSystemRoleList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 300px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>