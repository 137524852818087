<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area">
      <div class="left">
        <el-row :gutter="20">
          <el-col :span="9">
            <el-input v-model="listParams.polymerizationKeywords" placeholder="请输入搜索关键字" size="small"></el-input>
          </el-col>
          <el-col :span="9">
            <el-input v-model="listParams.tagsAddress" placeholder="请输入查询链接地址" size="small"></el-input>
          </el-col>
          <el-col :span="6">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="getSystemAppTagsList"
            >搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="tagList"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="tagListTable"
        @selection-change="handleSelectionChange"
        @row-click="tagItemClick"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="polymerizationKeywords" label="关键字" align="center"></el-table-column>
        <el-table-column prop="tagsAddress" label="链接地址" align="center"></el-table-column>
        <el-table-column prop="author" label="作者" align="center"></el-table-column>
        <el-table-column prop="createTime" label="添加时间" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      width="460px"
      center
      :close-on-click-modal="false"
      custom-class="article-dialog"
    >
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="140px"
          size="small"
        >
          <el-form-item label="聚合关键字" prop="polymerizationKeywords">
            <el-input v-model="saveRuleForm.polymerizationKeywords"></el-input>
          </el-form-item>
          <el-form-item label="标签地址" prop="tagsAddress">
            <el-input v-model="saveRuleForm.tagsAddress"></el-input>
          </el-form-item>
          <el-form-item label="聚合标签页描述" prop="tagsDiscribe">
            <el-input v-model="saveRuleForm.tagsDiscribe"></el-input>
          </el-form-item>
          <el-form-item label="作者" prop="author">
            <el-input v-model="saveRuleForm.author"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSave('saveRuleForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemAppTagsList,
  systemAppTagsRemove,
  systemAppTagsSave,
  systemAppTagsInfo,
  systemAppTagsUpdate,
} from "@/api/system/appManage"
export default {
  inject: ["reload"],
  watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
  data() {
    return {
      tagList: [],
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        tagsAddress: "",
        polymerizationKeywords: '',
      },
      total: 0,
      saveDialogVisible: false,
      saveRuleForm: {
        author: '',
        polymerizationKeywords: "",
        tagsAddress: "",
        tagsDiscribe: "",
      },
      saveRules: {
        author: [
          { required: true, message: "作者不能为空", trigger: "blur" },
        ],
        polymerizationKeywords: [
          { required: true, message: "聚合关键字不能为空", trigger: "blur" },
        ],
        tagsAddress: [
          { required: true, message: "标签地址不能为空", trigger: "blur" },
        ],
        tagsDiscribe: [
          { required: true, message: "聚合标签页描述不能为空", trigger: "blur" },
        ],
      },
      selectTagList: [],
      saveDialog: {
        title: "新增好文",
        type: "add",
      },
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.selectTagList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectTagList.push(val[i].id);
        }
      }
    },
    getSystemAppTagsList() {
      systemAppTagsList(this.listParams).then((res) => {
        console.log(res);
        this.tagList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getSystemAppTagsList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getSystemAppTagsList();
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            author: this.saveRuleForm.author,
            polymerizationKeywords: this.saveRuleForm.polymerizationKeywords,
            tagsAddress: this.saveRuleForm.tagsAddress,
            tagsDiscribe: this.saveRuleForm.tagsDiscribe,
          };
          if (this.saveDialog.type == "add") {
            systemAppTagsSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemAppTagsList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.id = this.selectTagList[0];
            systemAppTagsUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSystemAppTagsList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if (this.selectTagList.length != 1) {
        this.msgWarn("请选择单个标签进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑标签";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        author: '',
        polymerizationKeywords: "",
        tagsAddress: "",
        tagsDiscribe: "",
      }
      this.saveDialogVisible = true;
      systemAppTagsInfo(this.selectTagList[0]).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            author: res.rows.author,
            polymerizationKeywords: res.rows.polymerizationKeywords,
            tagsAddress: res.rows.tagsAddress,
            tagsDiscribe: res.rows.tagsDiscribe,
          };
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增标签";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        author: '',
        polymerizationKeywords: "",
        tagsAddress: "",
        tagsDiscribe: "",
      };
      this.saveDialogVisible = true;
    },
    refreshClick() {
      this.reload();
    },
    delClick() {
      if (this.selectTagList.length == 0) {
        this.msgWarn("请至少选择一个标签！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          systemAppTagsRemove(this.selectTagList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSystemAppTagsList();
            }
          });
        })
        .catch(() => {});
    },
    tagItemClick(row) {
      this.$refs.tagListTable.toggleRowSelection(row);
    },
  }, 
  created() {},
  mounted() {
    this.getSystemAppTagsList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 480px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
    .item-img {
      width: 200px;
      height: 100px;
    }
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>