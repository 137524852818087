<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">类别：</span>
          <el-select v-model="listParams.type" placeholder="请选择" size="small">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="getSysytemAppBannerList"
          >搜索</el-button>
        </div>
      </div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="bannerList"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        height="100%"
        tooltip-effect="dark"
        ref="bannerListTable"
        @selection-change="handleSelectionChange"
        @row-click="bannerItemClick"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column label="图片地址" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.photo" alt class="item-img" />
          </template>
        </el-table-column>
        <el-table-column :formatter="dealType" label="类型" align="center"></el-table-column>
        <el-table-column prop="sort" label="顺序" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      :title="saveDialog.title"
      :visible.sync="saveDialogVisible"
      width="460px"
      center
      :close-on-click-modal="false"
      custom-class="article-dialog"
    >
      <div>
        <el-form
          :model="saveRuleForm"
          status-icon
          :rules="saveRules"
          ref="saveRuleForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="类型" prop="type">
            <el-select v-model="saveRuleForm.type" placeholder="请选择">
              <el-option
                v-for="item in dialogTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图片" prop="photo">
            <el-upload
              accept="image/jpeg, image/png/, image/gif"
              class="avatar-uploader"
              :action="fileUpload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="saveRuleForm.photo" :src="saveRuleForm.photo" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="顺序" prop="sort">
            <el-input v-model="saveRuleForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="关联产品"  v-show="saveRuleForm.type == 2">
            <el-select v-model="saveRuleForm.relationId" placeholder="请选择">
              <el-option
                v-for="item in productList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSave('saveRuleForm')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  sysytemAppBannerList,
  systemAppBannerRemove,
  systemAppBannerSave,
  systemAppBannerUpdate,
  systemAppBannerInfo,
  systemAppProduct,
} from "@/api/system/appManage";
import { fileUpload } from "@/api/public";
export default {
  inject: ["reload"],
  watch: {
    saveDialogVisible: function (val,oldVla) {
      if (this.$refs['saveRuleForm'] !== undefined) {
        this.$refs["saveRuleForm"].resetFields();
      }
    }
  },
  data() {
    let validateRelationId = (rule, value, callback) => {
      if (this.saveRuleForm.type == 2) {
        if (!value) {
          return callback(new Error("关联产品不能为空"));
        }else{
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      fileUpload,
      bannerList: [],
      typeList: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "讲师APP首页",
        },
        {
          value: "2",
          label: "家长APP首页",
        },
        {
          value: "3",
          label: "学生PC购买页",
        },
        {
          value: "4",
          label: "WEB端首页",
        },
        {
          value: "5",
          label: "WEB端公开课",
        },
      ],
      dialogTypeList: [
        {
          value: "1",
          label: "讲师APP首页",
        },
        {
          value: "2",
          label: "家长APP首页",
        },
        {
          value: "3",
          label: "学生PC购买页",
        },
        {
          value: "4",
          label: "WEB端首页",
        },
        {
          value: "5",
          label: "WEB端公开课",
        },
      ],
      productParams: {
        productStatus: 3,
        productTypeList: [1, 2],
      },
      productList: [],
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        type: "0",
      },
      total: 0,
      saveDialogVisible: false,
      //saveRuleForm.relationType 1 关联产品  2自定义链接
      saveRuleForm: {
        photo: "",
        relationId: "",
        relationType: "1",
        sort: "",
        type: "1",
      },
      saveRules: {
        photo: [{ required: true, message: "图片不能为空", trigger: "blur" }],
        relationId: [{ validator: validateRelationId, trigger: "blur" }],
        sort: [{ required: true, message: "排序不能为空", trigger: "blur" }],
        type: [{ required: true, message: "类型不能为空", trigger: "blur" }],
      },
      selectBannerList: [],
      saveDialog: {
        title: "新增banner",
        type: "add",
      },
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.selectBannerList = [];
      if (val.length > 0) {
        for (let i = 0, len = val.length; i < len; i++) {
          this.selectBannerList.push(val[i].id);
        }
      }
    },
    handleAvatarSuccess(res, file) {
      this.saveRuleForm.photo = res[0].url;
    },
    beforeAvatarUpload(file) {
      let reg = /\.(png|jpg|gif|jpeg)$/;
      if(!reg.test(file.name)){
        this.msgError('支持上传的图片格式为png，jpg，gif，jpeg；请检查格式是否正确！')
      }
      return reg.test(file.name);
    },
    dealType(row) {
      let result;
      switch (row.type) {
        case 1:
          result = "讲师APP首页";
          break;
        case 2:
          result = "家长APP首页";
          break;
        case 3:
          result = "学生PC购买页";
          break;
        case 4:
          result = "WEB端首页";
          break;
        case 5:
          result = "WEB端公开课";
          break;
      }
      return result;
    },
    getSysytemAppBannerList() {
      let data = JSON.parse(JSON.stringify(this.listParams));
      data.type = data.type == "0" ? null : data.type;
      sysytemAppBannerList(data).then((res) => {
        console.log(res);
        this.bannerList = res.rows;
        this.total = res.total;
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getSysytemAppBannerList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getSysytemAppBannerList();
    },
    confirmSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            photo: this.saveRuleForm.photo,
            sort: this.saveRuleForm.sort,
            type: this.saveRuleForm.type,
          };
          if (this.saveRuleForm.type == 2) {
            data.relationId = this.saveRuleForm.relationId;
          }
          if (this.saveDialog.type == "add") {
            systemAppBannerSave(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSysytemAppBannerList();
              }
            });
          } else if (this.saveDialog.type == "edit") {
            data.id = this.selectBannerList[0];
            systemAppBannerUpdate(data).then((res) => {
              if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.saveDialogVisible = false;
                this.getSysytemAppBannerList();
              }
            });
          }
        } else {
          this.msgWarn("必填字段不能为空！");
          return false;
        }
      });
    },
    editClick() {
      if (this.selectBannerList.length != 1) {
        this.msgWarn("请选择单个banner进行编辑操作！");
        return;
      }
      this.saveDialog.title = "编辑banner";
      this.saveDialog.type = "edit";
      this.saveRuleForm = {
        photo: "",
        relationId: "",
        relationType: "1",
        sort: "",
        type: "1",
      };
      this.saveDialogVisible = true;
      this.getProductList()
      systemAppBannerInfo(this.selectBannerList[0]).then((res) => {
        if (res.code == 0) {
          this.saveRuleForm = {
            photo: res.rows.photo,
            type: String(res.rows.type),
            sort: res.rows.sort,
            relationId: res.rows.relationId,
          };
        }
      });
    },
    addClick() {
      this.saveDialog.title = "新增banner";
      this.saveDialog.type = "add";
      this.saveRuleForm = {
        photo: "",
        relationId: "",
        relationType: "1",
        sort: "",
        type: "1",
      };
      this.getProductList()
      this.saveDialogVisible = true;
    },
    getProductList(){
      systemAppProduct(this.productParams).then((res) => {
        if (res.code == 0) {
          this.productList = res.rows;
        }
      });
    },
    refreshClick() {
      this.reload();
    },
    delClick() {
      if (this.selectBannerList.length == 0) {
        this.msgWarn("请至少选择一个banner！");
        return;
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          systemAppBannerRemove(this.selectBannerList).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.getSysytemAppBannerList();
            }
          });
        })
        .catch(() => {});
    },
    bannerItemClick(row) {
      this.$refs.bannerListTable.toggleRowSelection(row);
    },
  },
  created() {},
  mounted() {
    this.getSysytemAppBannerList();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 480px;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
    .item-img {
      width: 200px;
      height: 100px;
    }
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>